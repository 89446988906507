<template>
  <div class="fill-width">
    <dialog-form-section-one-col v-if="substep === 1">
      <dialog-form-file-upload-input
        :selectedImportFile="entriesFile"
        :allowedImportMimeTypes="allowedMimeTypes"
        :allowedFileExpensions="allowedFileExtensions"
        :overrideUploadFileText="
          $t('components.integrations.manualFileUpload.stepThree.upload')
        "
        :overrideDragOrClickText="
          $t('components.integrations.manualFileUpload.dragAndDrop')
        "
        @fileChanged="$emit('update:entries-file', $event)"
        :legacyInputEvent="false"
      ></dialog-form-file-upload-input>
      <v-alert v-if="entriesParseError" dense type="error">
        {{ entriesParseError }}
      </v-alert>
      <v-row>
        <v-col offset="1" cols="10" align="center">
          <span class="d-inline-block grey--text">
            {{
              $t("components.integrations.manualFileUpload.supportedFiletypes")
            }}
          </span>
          <span class="d-inline-block grey--text text-left">
            {{
              $t(
                "components.integrations.manualFileUpload.stepThree.fileMustContain"
              )
            }}
          </span>
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
    <dialog-form-section-one-col :fluid="true" v-else-if="substep === 2">
      <v-alert v-if="entriesValidateError" dense type="error">
        {{ entriesValidateError }}
      </v-alert>
      <entry-file-mapping-table
        :entries="entries"
        :entry-field-mappings="entryFieldMappings"
        :available-fields="availableFields"
        :unit-types="unitTypes"
        @update:entries="$emit('update:entries', $event)"
        @update:entry-field-mappings="
          $emit('update:entry-field-mappings', $event)
        "
        @update:entry-field="
          (index, field, value) =>
            $emit('update:entry-field', index, field, value)
        "
        @update:editing-entry-mappings="
          $emit('update:editing-entry-mappings', $event)
        "
      />
    </dialog-form-section-one-col>
    <dialog-form-section-one-col v-else-if="substep === 3">
      <v-alert v-if="importError" dense type="error">
        {{ importError }}
      </v-alert>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <span class="d-block mb-2">{{
            $t(
              "components.integrations.manualFileUpload.stepThree.integrationName.instruction"
            )
          }}</span>
          <dialog-form-text-input
            :value="integrationName"
            :placeholder="
              $t(
                'components.integrations.manualFileUpload.stepThree.integrationName.placeholder'
              )
            "
            @input="$emit('update:integration-name', $event)"
          ></dialog-form-text-input>
        </v-col>
      </v-row>
      <v-row dense class="mt-8">
        <v-col cols="12" class="text-center">
          <span class="d-block">
            {{
              $t(
                "components.integrations.manualFileUpload.stepThree.uploadMoreFiles"
              )
            }}
          </span>
          <span class="d-block">
            {{
              $t(
                "components.integrations.manualFileUpload.stepThree.clickUploadFile"
              )
            }}
          </span>
        </v-col>
        <v-col offset="3" cols="6" >
          <img
            class="py-8"
            :src="
              require('../../../assets/manual-file-upload/upload-file-guide.png')
            "
          />
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
  </div>
</template>
<script>
import { serialize } from "object-to-formdata";
import DialogFormSectionOneCol from "../../Dialog/DialogFormSectionOneCol.vue";
import DialogFormFileUploadInput from "../../Dialog/inputs/DialogFormFileUploadInput.vue";
import DialogFormTextInput from "../../Dialog/inputs/DialogFormTextInput.vue";
import EntryFileMappingTable from "../EntryFileMappingTable.vue";

export default {
  components: {
    DialogFormSectionOneCol,
    DialogFormFileUploadInput,
    DialogFormTextInput,
    EntryFileMappingTable,
  },
  props: {
    substep: Number,
    loading: Boolean,
    entriesFile: File,
    entries: Array,
    entryFieldMappings: Object,
    integrationName: String,
    entryValidation: Object,
    importErrorCode: String,
    unitTypes: Array,
  },
  data() {
    return {
      entriesParseError: null,

      availableFields: [
        "AccountNumber",
        "EntryText",
        "EntryDate",
        "EntryAmount",
        "VoucherNumber",
        "Quantity",
        "UnitType",
      ],

      allowedFileExtensions: ".xlsx,.xls,.csv",
      allowedMimeTypes: [
        "application/csv",
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    };
  },
  computed: {
    entriesValidateError() {
      const errorCode = this.entryValidation?.generalErrorMessage;

      if (!errorCode) return null;

      return this.$t(
        `components.integrations.manualFileUpload.stepThree.errors.${errorCode}`
      );
    },
    importError() {
      const errorCode = this.importErrorCode;

      if (!errorCode) return null;

      return this.$t(
        `components.integrations.manualFileUpload.stepThree.errors.${errorCode}`
      );
    },
  },
  methods: {
    parseEntries(file) {
      this.$emit("update:loading", true);

      const requestBody = {
        entries: file,
      };

      const formData = serialize(requestBody);

      fetch(this.route("api.manual-file-upload.entries.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errorCode) {
            const errorPrefix =
              "components.integrations.manualFileUpload.stepThree.errors.";

            this.entriesParseError = this.$t(
              `${errorPrefix}${data.errorCode}`,
              {
                lineNumber: data.lineNumber,
              }
            );
            this.$emit("update:entries", []);
            this.$emit("update:entryFieldMappings", []);
            return;
          }

          this.$emit("update:entries", data.parsedEntries ?? []);
          this.$emit("update:entryFieldMappings", data.fieldMappings ?? []);
          this.availableFields = data.availableFields;
          this.entriesParseError = null;
        })
        .finally(() => {
          this.$emit("update:loading", false);
        });
    },
  },
  watch: {
    entriesFile(value) {
      if (!value) return;

      this.parseEntries(value);
    },
  },
};
</script>
