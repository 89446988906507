<template>
  <v-container fluid class="py-0" style="margin-top: -66px">
    <v-row class="px-2">
      <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
        <amount-change-card
          :title="$t('pages.audits.quantity.total.integration')"
          iconBackColor="#F25D3B1f"
          :value="formatInteger(totalIntegrationQuantity)"
          :hideBenchmark="true"
          :unit="$t('pages.audits.entries')"
        >
          <template v-slot:icon>
            <BarChartIcon style="width: 20px; height: 20px"></BarChartIcon>
          </template>
        </amount-change-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
        <amount-change-card
          :title="$t('pages.audits.quantity.total.unprocessed')"
          iconBackColor="#F25D3B1f"
          :value="formatInteger(totalUnprocessedQuantity)"
          :hideBenchmark="true"
          :unit="$t('pages.audits.entries')"
        >
          <template v-slot:icon>
            <BarChartIcon style="width: 20px; height: 20px"></BarChartIcon>
          </template>
        </amount-change-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
        <amount-change-card
          :title="$t('pages.audits.quantity.total.processed')"
          iconBackColor="#F25D3B1f"
          :value="formatInteger(totalProcessedQuantity)"
          :hideBenchmark="true"
          :unit="$t('pages.audits.entries')"
        >
          <template v-slot:icon>
            <BarChartIcon style="width: 20px; height: 20px"></BarChartIcon>
          </template>
        </amount-change-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
        <amount-change-card
          :title="$t('pages.audits.quantity.total.invalid')"
          iconBackColor="#F25D3B1f"
          :value="formatInteger(totalInvalidQuantity)"
          :hideBenchmark="true"
          :unit="$t('pages.audits.entries')"
        >
          <template v-slot:icon>
            <BarChartIcon style="width: 20px; height: 20px"></BarChartIcon>
          </template>
        </amount-change-card>
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col class="px-2">
        <dialog-form-select-input
          v-if="integrations?.length > 0"
          :items="formattedIntegrations"
          itemText="name"
          itemValue="id"
          v-model="selectedIntegration"
          style="max-width: 270px"
          hide-details
          class="mb-4"
        ></dialog-form-select-input>
        <v-data-table
          class="elevation-0 rounded-0 account-table"
          :headers="headers"
          :items="mappedAccounts"
          disable-sort
          hide-default-footer
          :items-per-page="-1"
          :loading="loadingData"
        >
          <template v-slot:item="{ item }">
            <tr
              :style="
                !item.heading ? 'background: #FAFAFA' : 'background: #FFF'
              "
            >
              <td>{{ item.accountName }}</td>
              <td class="align-center justify-space-between">
                {{
                  !item.heading ? formatInteger(item.integrationQuantity) : null
                }}
              </td>
              <td class="align-center">
                {{
                  !item.heading ? formatInteger(item.unprocessedQuantity) : null
                }}
              </td>
              <td>
                <component
                  :is="item.processedQuantity ? 'a' : 'span'"
                  :disabled="!item.processedQuantity"
                  :class="
                    item.processedQuantity ? 'text-decoration-underline' : ''
                  "
                  :href="navigateToEntryViewUrl(item.accountNumber, false)"
                  class="black--text pr-2 py-2"
                >
                  {{
                    !item.heading ? formatInteger(item.processedQuantity) : null
                  }}
                </component>
              </td>
              <td>
                <component
                  :is="item.invalidQuantity ? 'a' : 'span'"
                  :disabled="!item.invalidQuantity"
                  :class="
                    item.invalidQuantity ? 'text-decoration-underline' : ''
                  "
                  :href="navigateToEntryViewUrl(item.accountNumber, true)"
                  class="black--text pr-2 py-2"
                >
                  {{
                    !item.heading ? formatInteger(item.invalidQuantity) : null
                  }}
                </component>
              </td>
              <td class="py-2">
                <div v-if="!item.heading" class="d-flex align-center">
                  <div class="mr-2">
                    <CheckCircleIcon
                      v-if="item.deviationPercentage === 0"
                      width="20px"
                      height="20px"
                    ></CheckCircleIcon>
                    <WarningIcon
                      class="warning-icon"
                      v-else
                      width="20px"
                      height="20px"
                    ></WarningIcon>
                  </div>
                  <div
                    class="flex-grow-1"
                    :class="
                      item.deviationPercentage !== 0 ? 'warning-text' : null
                    "
                  >
                    {{ formatInteger(item.deviationPercentage) }}%
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <div class="d-flex flex-grow-1 justify-end mx-4 py-4">
              <v-btn
                large
                class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 text-none"
                elevation="0"
                tile
                color="#F25D3B"
                dark
                :href="excelExportLink"
                :disabled="!excelExportLink"
                >Export to Excel</v-btn
              >
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { appLayout } from "@/util/layout";
import AmountChangeCard from "../../Components/Cards/AmountChangeCard.vue";
import BarChartIcon from "../../assets/svg/bar-chart.svg";
import CheckCircleIcon from "../../assets/svg/check-circle.svg";
import WarningIcon from "../../assets/svg/warning-error.svg";
import Vue from "vue";
import Formatters from "../../mixins/Formatters.vue";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";
import qs from "qs";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import { normalizeISOString } from "../../util/dateTime";
import { DateTime } from "luxon";

export default {
  mixins: [Formatters, GlobalDateRange],
  components: {
    AmountChangeCard,
    CheckCircleIcon,
    WarningIcon,
    BarChartIcon,
    DialogFormSelectInput,
  },
  layout: appLayout({ title: "pages.audits.title", pushContent: true }),
  props: {
    audit: Array,
    integrationId: String,
    auditTotal: Object,
    integrations: Array,
  },
  mounted() {
    this.getAccountsAndCategories();

    this.syncDateRange((dateRange) => {
      this.globalDateRange = dateRange;
      this.handleDateRangeChanged(dateRange);
    });
  },
  data() {
    return {
      flattenedAccounts: [],
      mappedAccounts: [],
      loadingData: false,
      currentAudit: this.audit,
      currentAuditTotal: this.auditTotal,
      selectedIntegration: this.integrationId,
      globalDateRange: null,
    };
  },
  watch: {
    selectedIntegration() {
      this.flattenedAccounts = [];
      this.mappedAccounts = [];

      this.getAccountsAndCategories();
      this.handleDateRangeChanged(this.globalDateRange, true);
    },
  },
  computed: {
    totalIntegrationQuantity() {
      return this.currentAuditTotal?.integrationQuantity;
    },
    totalProcessedQuantity() {
      return this.currentAuditTotal?.processedQuantity;
    },
    totalUnprocessedQuantity() {
      return this.currentAuditTotal?.unprocessedQuantity;
    },
    totalInvalidQuantity() {
      return this.currentAuditTotal?.invalidQuantity;
    },
    integrationSystem() {
      if (!this.selectedIntegration) return null;

      return (
        this.integrations?.find((x) => x.id === this.selectedIntegration)
          ?.system ?? null
      );
    },
    headers() {
      let headers = [
        {
          text: this.$t("pages.audits.mappedAccounts"),
          align: "start",
          sortable: false,
          value: "accountName",
          width: "fill",
        },
        {
          text: this.$t("pages.audits.quantity.integration"),
          value: "integrationQuantity",
        },
        {
          text: this.$t("pages.audits.quantity.unprocessed"),
          value: "unprocessedQuantity",
        },
        {
          text: this.$t("pages.audits.quantity.processed"),
          value: "processedQuantity",
        },
        {
          text: this.$t("pages.audits.quantity.invalid"),
          value: "invalidQuantity",
        },
        {
          text: this.$t("pages.audits.deviation"),
          value: "deviationPercentage",
        },
      ];

      return headers;
    },
    formattedIntegrations() {
      if (!this.integrations) return [];

      return this.integrations.map((x) => {
        const integrationSystemKey = `common.integrations.systems.${x.system}`;

        let integrationSystem;

        if (this.$te(integrationSystemKey)) {
          integrationSystem = this.$t(integrationSystemKey);
        } else {
          integrationSystem = this.$t("common.integrations.systems.unknown");
        }

        return {
          id: x.id,
          name: `#${x.referenceKey} - ${integrationSystem}`,
        };
      });
    },
    excelExportLink() {
      if (!this.globalDateRange || !this.selectedIntegration) return null;

      return this.route("api.audits.export.excel", {
        fromDate: this.globalDateRange.from,
        toDate: this.globalDateRange.to,
        integrationId: this.selectedIntegration,
      });
    },
  },
  methods: {
    async getAccountsAndCategories() {
      this.loadingData = true;
      fetch(
        this.route("onboarding.accountNumberMapper", {
          integrationId: this.selectedIntegration,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.flattenedAccounts = this.formatFlattenedAccounts(
            data.economicAccounts
          );
          this.mappedAccounts = this.formatMappedAccounts(
            this.flattenedAccounts
          );
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    formatFlattenedAccounts(data) {
      const _accounts = [];
      const currentParent = {};

      data.forEach((account) => {
        if (
          account.accountType === "heading" ||
          account.accountType === "Heading" ||
          account.accountType === "Header" ||
          account.accountType.includes("Begin")
        ) {
          // Add last parentobject when encountering a new heading (Ignore first time since no parent object data has been added yet)
          if (currentParent.name && currentParent.accountNumbers.length > 0) {
            _accounts.push({ ...currentParent });
          }
          currentParent.name = account.name;
          currentParent.accountNumber = account.accountNumber;
          currentParent.accountNumbers = [];
        } else if (currentParent.accountNumbers) {
          const accountNumber = {
            name: account.name,
            number: account.accountNumber,
            id: account.id,
            balance: account.balance,
          };
          currentParent.accountNumbers.push(accountNumber);
        }
      });

      // flatten
      const flattenedAccounts = [];

      _accounts.forEach((accountGroup) => {
        flattenedAccounts.push({
          accountName: accountGroup.accountNumber + " - " + accountGroup.name,
          accountNumber: accountGroup.accountNumber,
          heading: true,
        });

        accountGroup.accountNumbers.forEach((account) => {
          flattenedAccounts.push({
            accountName: account.number + " - " + account.name,
            accountNumber: account.number,
          });
        });
      });

      return flattenedAccounts;
    },
    formatMappedAccounts(accounts) {
      accounts = [...accounts];

      // merge
      this.currentAudit.forEach((auditAccount) => {
        let index = accounts.findIndex(
          (account) => account.accountNumber === auditAccount.accountNumber
        );

        if (index !== -1) {
          Vue.set(accounts, index, {
            ...auditAccount,
            ...accounts[index],
          });
        }
      });

      // filter
      accounts = accounts.filter(
        (account) =>
          account.heading ||
          this.currentAudit.some(
            (x) => x.accountNumber === account.accountNumber
          )
      );

      // remove empty headings
      const filteredAccounts = accounts.filter(
        (account, index) =>
          !account?.heading || // include if not heading
          (!(account?.heading && accounts[index + 1]?.heading) && // exclude if this and next item are a headings
            !(account?.heading && index + 1 === accounts.length)) // exclude heading and last item in list
      );

      return filteredAccounts;
    },
    handleDateRangeChanged(dateRange, forceUpdate = false) {
      const dateRangeChanged = this.applyDateRangeToParams(dateRange);

      if (!dateRangeChanged && !forceUpdate) return;

      this.loadingData = true;

      this.fetchAuditData(dateRange)
        .then(
          () =>
            (this.mappedAccounts = this.formatMappedAccounts(
              this.flattenedAccounts
            ))
        )
        .finally(() => (this.loadingData = false));
    },
    applyDateRangeToParams(dateRange) {
      const searchParams = qs.parse(window.location.search.substring(1));

      const fromChanged = searchParams.fromDate !== dateRange.from;
      const toChanged = searchParams.toDate !== dateRange.to;

      if (!fromChanged && !toChanged) return false;

      searchParams.fromDate = dateRange.from;
      searchParams.toDate = dateRange.to;

      const url = `${window.location.pathname}?${qs.stringify(searchParams)}`;

      window.history.replaceState(null, "", url);

      return true;
    },
    fetchAuditData(dateRange) {
      const totalPromise = fetch(
        this.route("api.audits.quantities.total", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          integrationId: this.selectedIntegration,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.currentAuditTotal = data));

      const accountsPromise = fetch(
        this.route("api.audits.quantities.accounts", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          integrationId: this.selectedIntegration,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.currentAudit = data));

      return Promise.all([totalPromise, accountsPromise]);
    },
    formatDate(dateString) {
      if (!dateString) return null;

      const normalizedDate = normalizeISOString(dateString);

      return DateTime.fromISO(normalizedDate).toISODate();
    },
    navigateToEntryViewUrl(accountNumber, onlyInvalid) {
      return this.route("api.audits.accounting-entries.navigate", {
        accountNumber,
        onlyInvalid: onlyInvalid ? "true" : "false",
        fromDate: this.formatDate(this.globalDateRange?.from),
        toDate: this.formatDate(this.globalDateRange?.to),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-table:deep(th span) {
  color: #b4b4b4;
  font-weight: 500;
  font-size: 12px;
}

.warning-text {
  color: #d32c2c;
}

.warning-icon {
  path {
    fill: #d32c2c;
  }
}
</style>
