<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <dialog-form-section-two-cols class="dialog-form-cols" no-bottom-border>
        <template #left>
          <dialog-form-text-area-input
            :value="noteText"
            :title="$t('pages.assets.form.notes.note')"
            :rows="5"
            :maxlength="1024"
            @input="$emit('update:noteText', $event)"
          />
          <dialog-form-input-wrapper
            :title="$t('pages.assets.form.fixedLabels.attachment')"
          >
            <dialog-form-file-upload-input
              small
              :allowedImportMimeTypes="['application/pdf']"
              :allowedFileExpensions="'.pdf'"
              :legacy-input-event="false"
              @filesChanged="$emit('update:noteFiles', $event)"
              :selectedImportFiles="noteFiles"
              multiple
              :overrideUploadFileText="$t('pages.assets.form.uploadFileText')"
            ></dialog-form-file-upload-input>
            <p class="mt-1" style="font-size: 10px; color: #b4b4b4">
              {{ $t("pages.assets.form.uploadFileInfo") }}
            </p>
          </dialog-form-input-wrapper>
          <div
            v-for="(noteFile, index) in noteFiles"
            :key="index"
            class="d-flex flex-row align-center pl-4 mb-2"
            style="background: #fef2ef; border: 1px solid #fef2ef"
          >
            <PdfIcon></PdfIcon>
            <p class="flex-grow-1 mb-0 ml-2">{{ noteFile?.name }}</p>
            <v-btn class="mr-1" icon dark @click="removeFile(index)">
              <v-icon size="20" color="#686868">mdi-close</v-icon>
            </v-btn>
          </div>
        </template>
        <template #right>
          <div
            v-for="(assetNote, index) in formattedAssetNotes"
            :key="assetNote.id"
          >
            <AssetNote :asset-note="assetNote" @remove="removeNote" />
            <v-divider
              v-if="index < formattedAssetNotes.length - 1"
              class="my-4"
            ></v-divider>
          </div>
        </template>
      </dialog-form-section-two-cols>
    </v-card>
  </v-tab-item>
</template>
<script>
import AssetNote from "../../../Components/Asset/AssetNote.vue";
import DialogFormSectionTwoCols from "../../../Components/Dialog/DialogFormSectionTwoCols.vue";
import DialogFormFileUploadInput from "../../../Components/Dialog/inputs/DialogFormFileUploadInput.vue";
import DialogFormInputWrapper from "../../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormTextAreaInput from "../../../Components/Dialog/inputs/DialogFormTextAreaInput.vue";
import PdfIcon from "../../../assets/svg/pdf.svg";

export default {
  components: {
    DialogFormSectionTwoCols,
    AssetNote,
    DialogFormTextAreaInput,
    DialogFormInputWrapper,
    DialogFormFileUploadInput,
    PdfIcon,
  },
  props: {
    tabValue: [String, Number],
    assetNotes: Array,
    deleteAssetNoteIds: Array,
    noteText: String,
    noteFiles: Array,
  },
  computed: {
    formattedAssetNotes() {
      const assetNotes = this.assetNotes.filter(
        (x) => !this.deleteAssetNoteIds.includes(x.id)
      );

      assetNotes.sort((a, b) => {
        return b.createdAt.localeCompare(a.createdAt);
      });

      return assetNotes;
    },
  },
  methods: {
    removeNote(id) {
      if (!id) return;

      const updatedDeleteIds = [...this.deleteAssetNoteIds, id];

      this.$emit("update:deleteAssetNoteIds", updatedDeleteIds);
    },
    removeFile(index) {
      const updatedFiles = this.noteFiles.filter(
        (_, existingIndex) => existingIndex !== index
      );

      this.$emit("update:noteFiles", updatedFiles);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-form-cols {
  :deep(.column-left) {
    height: calc(81vh - 197px);
    overflow-y: scroll;
    padding-top: 10px !important;
  }

  :deep(.column-right) {
    height: calc(81vh - 197px);
    overflow-y: scroll;
    padding-top: 10px !important;
  }
}
</style>
