<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="12" sm="6" xl="3">
          <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
            <v-text-field
              :label="'Search in Intensity figures'"
              color="#686868"
              class="search-field rounded-0 pt-0"
              height="44"
              light
              hide-details="auto"
              background-color="#F7F7F7"
              filled
              solo
              dense
              prepend-inner-icon="mdi-magnify"
              flat
              clearable
              @change="(val) => updateSearch(val)"
            ></v-text-field>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-btn
            large
            class="align-self-stretch align-self-sm-center mt-5 mr-4 mt-sm-0"
            elevation="0"
            tile
            color="#F25D3B"
            dark
            @click="createIntensityFigure"
          >
            <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
            {{ $t("common.actions.create") }}
          </v-btn>
          <IntensityFigureForm
            v-model="showForm"
            :intensityFigure="editingIntensityFigure"
            :scopes="scopes"
            :ghg-categories="ghgCategories"
            :emission-sectors="emissionSectors"
            :emission-categories="emissionCategories"
            :integrations="integrations.data"
            :financial-accounts="financialAccounts"
            :dimensions="dimensions"
          ></IntensityFigureForm>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="intensityFigures"
            :options.sync="tableOptions"
            disable-pagination
            hide-default-footer
            @dblclick:row="(_, { item }) => setEditingIntensityFigure(item)"
            @update:sort-by="updateQuery(tableOptions)"
            @update:sort-desc="updateQuery(tableOptions)"
          >
            <template #item.dateRange="{ item }">
              <div class="d-flex flex-row align-center">
                {{ formatDate(item.startDate, settings.defaultDateFormat) }}
                <ArrowRightIcon
                  class="mx-2"
                  width="12px"
                  height="12px"
                ></ArrowRightIcon>
                {{ formatDate(item.endDate, settings.defaultDateFormat) }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>

<script>
import debounce from "lodash/debounce";
import qs from "qs";
import IntensityFigureForm from "../IntensityFigureForm.vue";
import ArrowRightIcon from "../../../assets/svg/arrow-right-dark-20px.svg";
import { formatDate } from "../../../util/dateTime";
import { fixedAttributes, fixedClasses } from "../../../util/fixedAssetData";

export default {
  components: { IntensityFigureForm, ArrowRightIcon },
  props: {
    tabHref: String,
    tabId: Number,
    intensityFigures: Array,
    attributes: Array,
    ghgCategories: Array,
    emissionSectors: Array,
    emissionCategories: Array,
    integrations: Object,
    financialAccounts: Array,
    settings: Object,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      showForm: false,
      headers: [
        {
          text: this.$t("pages.settings.intensityFigures.table.id"),
          value: "referenceKey",
        },
        {
          text: this.$t("pages.settings.intensityFigures.table.name"),
          value: "name",
        },
        {
          text: this.$t("pages.settings.intensityFigures.table.dateRange"),
          value: "dateRange",
        },
      ],
      editingIntensityFigure: null,
      tableOptions: {
        sortBy: [searchParams.intensityFigureSortBy ?? "referenceKey"],
        sortDesc: [searchParams.intensityFigureSortDesc === "true"],
      },
      filters: {
        intensityFigureSearch: searchParams["intensityFigureSearch"] ?? null,
      },
    };
  },
  methods: {
    formatDate,
    updateSearch(value) {
      this.filters.intensityFigureSearch = value;
    },
    setEditingIntensityFigure(item) {
      this.editingIntensityFigure = item;
      this.showForm = true;
    },
    createIntensityFigure() {
      this.editingIntensityFigure = null;
      this.showForm = true;
    },
    updateQuery(options) {
      const existingQuery = qs.parse(window.location.search.substring(1));

      const query = {
        ...existingQuery,
        intensityFigureSearch: this.filters?.intensityFigureSearch ?? null,
        intensityFigureSortBy:
          typeof options.sortBy !== "undefined" ? options.sortBy[0] : null,
        intensityFigureSortDesc:
          typeof options.sortDesc !== "undefined" ? options.sortDesc[0] : null,
      };

      this.$inertia.get(
        this.route("assets.more", query),
        {},
        {
          preserveState: true,
          preserveScroll: true,
          onFinish: () => {
            this.$emit("setTab", this.tabId);
          },
        }
      );
    },
  },
  computed: {
    scopes() {
      const scopeAttribute = this.attributes?.find(
        (x) => x.id === fixedAttributes.co2EmissionSourceId
      );

      if (!scopeAttribute) return [];

      return scopeAttribute.attributeSelectOptions;
    },
    dimensions() {
      return this.attributes?.filter(
        (x) => x.attributeClassId === fixedClasses.dimensionsId
      );
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.updateQuery(this.tableOptions);
      }, 250),
      deep: true,
    },
  },
};
</script>
