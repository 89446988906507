<template>
  <div class="flex-grow-1 d-flex justify-center" style="padding-top: 126px">
    <div class="d-flex justify-center">
      <div class="px-0" fluid style="width: 1313px">
        <div class="">
          <div class="page-title">
            {{ $t("pages.reports.climateReport.statistics.title") }}
          </div>
          <div class="page-subtitle">
            {{ $t("pages.reports.climateReport.statistics.subtitle") }}
          </div>
        </div>
        <v-row class="mb-3">
          <v-col>
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
              height="100%"
              class="flex-grow-1 pa-4 d-flex justify-space-between flex-column"
            >
              <p class="card-title">
                {{
                  $t(
                    "components.reportTemplates.reportStatistics.cardTitles.title1"
                  )
                }}
              </p>
              <p class="card-value">
                {{ statisticsPageData?.dataOverview?.accountingSystemEntries }}
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
              height="100%"
              class="flex-grow-1 pa-4 d-flex justify-space-between flex-column"
            >
              <p class="card-title">
                {{
                  $t(
                    "components.reportTemplates.reportStatistics.cardTitles.title2"
                  )
                }}
              </p>
              <p class="card-value">
                {{ statisticsPageData?.dataOverview?.invoicesFiles }}
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
              height="100%"
              class="flex-grow-1 pa-4 d-flex justify-space-between flex-column"
            >
              <p class="card-title">
                {{
                  $t(
                    "components.reportTemplates.reportStatistics.cardTitles.title3"
                  )
                }}
              </p>
              <p class="card-value">
                {{ statisticsPageData?.dataOverview?.individualPurchases }}
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
              height="100%"
              class="flex-grow-1 pa-4 d-flex justify-space-between flex-column"
            >
              <p class="card-title">
                {{
                  $t(
                    "components.reportTemplates.reportStatistics.cardTitles.title4"
                  )
                }}
              </p>
              <p class="card-value">
                {{
                  statisticsPageData?.dataOverview?.differentInvoiceSuppliers
                }}
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
              height="100%"
              class="flex-grow-1 pa-4 d-flex justify-space-between flex-column"
            >
              <p class="card-title">
                {{
                  $t(
                    "components.reportTemplates.reportStatistics.cardTitles.title5"
                  )
                }}
              </p>
              <p class="card-value">
                {{ statisticsPageData?.dataOverview?.accountingSystemAccounts }}
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
              height="100%"
              class="flex-grow-1 pa-4 d-flex justify-space-between flex-column"
            >
              <p class="card-title">
                {{
                  $t(
                    "components.reportTemplates.reportStatistics.cardTitles.title6"
                  )
                }}
              </p>
              <p class="card-value">
                {{ statisticsPageData?.dataOverview?.integrations }}
              </p>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
              height="100%"
              class="flex-grow-1 pa-4 d-flex justify-space-between flex-column"
            >
              <p class="card-title">
                {{
                  $t(
                    "components.reportTemplates.reportStatistics.cardTitles.title7"
                  )
                }}
              </p>
              <p class="card-value">
                {{
                  statisticsPageData?.dataOverview?.destinctiveEmissionFactors
                }}
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-5 pb-5 pt-2">
            <v-simple-table class="statistics-table">
              <template v-slot:default>
                <thead>
                  <tr class="table-header">
                    <th width="auto" class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportStatistics.tableHeaders.header1"
                        )
                      }}
                    </th>
                    <th width="15%" class="text-right">
                      {{
                        $t(
                          "components.reportTemplates.reportStatistics.tableHeaders.header2"
                        )
                      }}
                    </th>
                    <th width="15%" class="text-right">
                      {{
                        $t(
                          "components.reportTemplates.reportStatistics.tableHeaders.header3"
                        )
                      }}
                    </th>
                    <th width="15%" class="text-right">
                      {{
                        $t(
                          "components.reportTemplates.reportStatistics.tableHeaders.header4"
                        )
                      }}
                    </th>
                    <th width="15%" class="text-right">
                      {{
                        $t(
                          "components.reportTemplates.reportStatistics.tableHeaders.header5"
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(key, index) in Object.keys(scopesTotal).sort(
                      (a, b) => orderMapping[a] - orderMapping[b]
                    )"
                    :class="
                      scopesTotal[key][0]?.emissionFactorStatus === 'total'
                        ? 'sub-header'
                        : null
                    "
                    :key="'total-' + index"
                  >
                    <td>
                      {{
                        statusLabelMap[
                          scopesTotal[key][0]?.emissionFactorStatus
                        ]
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        scopesTotal[key].reduce(
                          (acc, a) => (acc += a.quanityPurchases),
                          0
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        formatKgToTonEmissions(
                          scopesTotal[key].reduce(
                            (acc, a) => (acc += a.co2Emissions),
                            0
                          )
                        ).value
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        formatCurrency(
                          scopesTotal[key].reduce(
                            (acc, a) => (acc += a.percentCo2Emissions),
                            0
                          )
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        formatCurrency(
                          scopesTotal[key].reduce(
                            (acc, a) => (acc += a.percentPurchaseAmount),
                            0
                          )
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="(key, index) in Object.keys(scope1).sort(
                      (a, b) => orderMapping[a] - orderMapping[b]
                    )"
                    :class="
                      scope1[key][0]?.emissionFactorStatus === 'total'
                        ? 'sub-header'
                        : null
                    "
                    :key="'scope1-' + index"
                  >
                    <td>
                      {{
                        scope1[key][0]?.emissionFactorStatus === "total"
                          ? "Scope 1"
                          : statusLabelMap[scope1[key][0]?.emissionFactorStatus]
                      }}
                    </td>
                    <td class="text-right">
                      {{ scope1[key][0]?.quanityPurchases }}
                    </td>
                    <td class="text-right">
                      {{
                        formatKgToTonEmissions(scope1[key][0]?.co2Emissions)
                          .value
                      }}
                    </td>
                    <td class="text-right">
                      {{ formatCurrency(scope1[key][0]?.percentCo2Emissions) }}
                    </td>
                    <td class="text-right">
                      {{
                        formatCurrency(scope1[key][0]?.percentPurchaseAmount)
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="(key, index) in Object.keys(scope2).sort(
                      (a, b) => orderMapping[a] - orderMapping[b]
                    )"
                    :class="
                      scope2[key][0]?.emissionFactorStatus === 'total'
                        ? 'sub-header'
                        : null
                    "
                    :key="'scope2-' + index"
                  >
                    <td>
                      {{
                        scope2[key][0]?.emissionFactorStatus === "total"
                          ? "Scope 2"
                          : statusLabelMap[scope2[key][0]?.emissionFactorStatus]
                      }}
                    </td>
                    <td class="text-right">
                      {{ scope2[key][0]?.quanityPurchases }}
                    </td>
                    <td class="text-right">
                      {{
                        formatKgToTonEmissions(scope2[key][0]?.co2Emissions)
                          .value
                      }}
                    </td>
                    <td class="text-right">
                      {{ formatCurrency(scope2[key][0]?.percentCo2Emissions) }}
                    </td>
                    <td class="text-right">
                      {{
                        formatCurrency(scope2[key][0]?.percentPurchaseAmount)
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="(key, index) in Object.keys(scope3).sort(
                      (a, b) => orderMapping[a] - orderMapping[b]
                    )"
                    :class="
                      scope3[key][0]?.emissionFactorStatus === 'total'
                        ? 'sub-header'
                        : null
                    "
                    :key="'scope3-' + index"
                  >
                    <td>
                      {{
                        scope3[key][0]?.emissionFactorStatus === "total"
                          ? "Scope 3"
                          : statusLabelMap[scope3[key][0]?.emissionFactorStatus]
                      }}
                    </td>
                    <td class="text-right">
                      {{ scope3[key][0]?.quanityPurchases }}
                    </td>
                    <td class="text-right">
                      {{
                        formatKgToTonEmissions(scope3[key][0]?.co2Emissions)
                          .value
                      }}
                    </td>
                    <td class="text-right">
                      {{ formatCurrency(scope3[key][0]?.percentCo2Emissions) }}
                    </td>
                    <td class="text-right">
                      {{
                        formatCurrency(scope3[key][0]?.percentPurchaseAmount)
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="px-8 pb-4">
            <div class="footnote">
              <strong>{{
                $t(
                  "components.reportTemplates.reportStatistics.footNotes.footNote1.title"
                )
              }}</strong
              >&nbsp;{{
                $t(
                  "components.reportTemplates.reportStatistics.footNotes.footNote1.text"
                )
              }}
            </div>
            <div class="footnote">
              <strong>{{
                $t(
                  "components.reportTemplates.reportStatistics.footNotes.footNote2.title"
                )
              }}</strong
              >&nbsp;{{
                $t(
                  "components.reportTemplates.reportStatistics.footNotes.footNote2.text"
                )
              }}
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Formatters from "../../mixins/Formatters.vue";

export default {
  mixins: [Formatters],
  props: {
    statisticsPageData: Object,
  },
  data() {
    return {
      orderMapping: {
        total: 1,
        approved: 2,
        defined: 3,
        undefined: 4,
        defining: 5,
      },
      statusLabelMap: {
        Approved: this.$t(
          "components.reportTemplates.reportStatistics.statusLabels.verified"
        ),
        Defined: this.$t(
          "components.reportTemplates.reportStatistics.statusLabels.defined"
        ),
        Undefined: this.$t(
          "components.reportTemplates.reportStatistics.statusLabels.undefined"
        ),
        Defining: this.$t(
          "components.reportTemplates.reportStatistics.statusLabels.defining"
        ),
        total: this.$t(
          "components.reportTemplates.reportStatistics.statusLabels.total"
        ),
      },
    };
  },
  computed: {
    scopesTotal() {
      return (
        this.statisticsPageData?.dataGroupedByScopeAndStatus["scopesTotal"] ??
        {}
      );
    },
    scope1() {
      return (
        this.statisticsPageData?.dataGroupedByScopeAndStatus[
          "cb483bd8-d20e-42a1-a252-c44ee12230ef"
        ] ?? {}
      );
    },
    scope2() {
      return (
        this.statisticsPageData?.dataGroupedByScopeAndStatus[
          "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b"
        ] ?? {}
      );
    },
    scope3() {
      return (
        this.statisticsPageData?.dataGroupedByScopeAndStatus[
          "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde"
        ] ?? {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  margin-bottom: 0;
  color: #707070;
}

.card-value {
  font-size: 25px;
}

.page-title {
  font-size: 35px;
}
.page-subtitle {
  font-size: 20px;
  color: #707070;
}

.statistics-table {
  thead {
    .table-header {
      th {
        font-weight: 200;
        color: #707070;
        font-size: 14px;
        padding-bottom: 10px;
        &:first-child {
          font-size: 18px;
        }
      }
    }
  }
  tbody {
    tr {
      background: #fbfbfb;

      td {
        border-bottom: thin solid #fff !important;

        height: 35px !important;
      }
    }
    .sub-header {
      background: #f5f5f5;
    }
  }
}
.footnote {
  color: #707070;
  strong {
    font-weight: 600;
  }
}
</style>
