<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <v-card-text
        v-if="
          hasEnoughDataForAI &&
          !factorIsDefined &&
          !isSupplierSpecificMethod &&
          !formattedEmissionsData &&
          calculatedEmissionFactorId !== emissionFactor?.id
        "
      >
        <v-alert tile prominent type="warning" elevation="0">
          <v-row align="center">
            <v-col class="grow">
              {{ $t("pages.assets.form.emissionNotDefinedCo2") }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                :disabled="runningAI"
                :loading="runningAI"
                elevation="0"
                tile
                @click="runAIOnAsset"
              >
                {{ $t("pages.assets.form.runAI") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <v-card-text
        v-if="false /*factorIsDefined && asset.emissionFactorId === nameData*/"
      >
        <v-alert prominent tile type="warning" elevation="0">
          <v-row align="center">
            <v-col class="grow">
              {{ $t("pages.assets.form.emissionAiDefined") }}
            </v-col>
            <v-col class="shrink">
              <v-btn elevation="0" tile @click="approveEmission">
                {{ $t("pages.assets.form.approve") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <dialog-form-section-two-cols no-bottom-border>
        <template slot="left">
          <div v-if="!isPurchasedAsUsed">
            <asset-attribute-link-input
              :attribute="emissionSourceAttribute"
              :value="emissionSourceData"
              @input="updateData(fixedAttributes.co2EmissionSourceId, $event)"
              @select_changed="handleEmissionSourceChanged"
            />
            <asset-attribute-link-input
              v-if="emissionSourceData"
              :attribute="ghgCategoryAttribute"
              :value="ghgCategoryId"
              :select-options="filteredGhgCategories"
              select-item-text="name"
              select-item-value="id"
              @input="updateData(ghgCategoryAttribute.id, $event)"
              @select_changed="handleGhgCategoryIdChanged"
            />
            <asset-attribute-link-input
              :attribute="methodAttribute"
              :value="methodData"
              @input="updateData(fixedAttributes.co2MethodId, $event)"
              @select_changed="handleMethodChanged"
            />
            <asset-attribute-link-input
              :attribute="sectorAttribute"
              :value="sectorData"
              :selectOptions="emissionSectors"
              @input="updateData(fixedAttributes.co2SectorId, $event)"
              @select_changed="handleSectorChanged"
              :loading="loadingFields['sectors']"
            />
            <asset-attribute-link-input
              :attribute="categoryAttribute"
              :value="categoryData"
              :selectOptions="emissionCategories"
              @input="updateData(fixedAttributes.co2CategoryId, $event)"
              @select_changed="handleCategoryChanged"
              :loading="loadingFields['categories']"
            />
            <template v-if="!isSupplierSpecificMethod">
              <div
                v-if="categoryData === '08db14dc-e325-4653-83fe-48dea5f652cc'"
              >
                <v-radio-group
                  row
                  v-model="electricitySourceData"
                  @change="updateBool($event, electricitySourceAttribute)"
                >
                  <v-radio
                    :label="
                      $t('pages.assets.form.electricitySource.locationBased')
                    "
                    :value="false"
                  ></v-radio>
                  <v-radio
                    :label="
                      $t('pages.assets.form.electricitySource.marketBased')
                    "
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </div>
              <asset-attribute-link-input
                :attribute="regionAttribute"
                :value="regionData"
                :selectOptions="emissionRegions"
                @input="updateData(fixedAttributes.co2RegionId, $event)"
                @select_changed="handleRegionChanged"
                :loading="loadingFields['regions']"
              />
              <asset-attribute-link-input
                :attribute="nameAttribute"
                :value="nameData"
                :selectOptions="emissionFactors"
                @input="updateData(fixedAttributes.co2NameId, $event)"
                @select_changed="handleNameChanged"
                :loading="loadingFields['emissionFactors']"
              >
                <template #selectItem="{ item }">
                  {{ item.value }} {{ getFactorVersionLabel(item) }}
                </template>
              </asset-attribute-link-input>
            </template>
            <template v-else>
              <asset-attribute-link-input
                :attribute="emissionsAttribute"
                :value="emissionsData"
                @input="updateData(emissionsAttribute.id, $event)"
                @decimal_changed="
                  $emit('decimal_changed', $event, emissionsAttribute)
                "
              />
              <asset-attribute-link-input
                :attribute="documentationAttribute"
                :value="documentationData"
                :secondValue="documentationSecondValueData"
                @input="updateData(documentationAttribute.id, $event)"
                @input_secondValue="
                  updateSecondValueData(documentationAttribute.id, $event)
                "
                @file_changed="
                  $emit('file_changed', $event, documentationAttribute)
                "
                @file_removed="
                  $emit('file_removed', $event, documentationAttribute)
                "
              />
            </template>
          </div>
        </template>
        <template slot="right">
          <div>
            <v-row dense>
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.emissions") }}:
              </v-col>
              <v-col cols="7">
                <span
                  v-if="isAverageDataMethod && !activityUnitMatch"
                  class="red--text"
                >
                  {{
                    $t("pages.assets.form.validation.activityTypeMismatch", {
                      activityUnit: activityUnit,
                      unitType: formattedUnitType,
                    })
                  }}
                </span>
                <span
                  v-else-if="
                    isSupplierSpecificMethod ||
                    (formattedEmissionsData &&
                      calculatedEmissionFactorId === emissionFactor?.id)
                  "
                >
                  {{ formattedEmissionsData }}
                </span>
                <span v-else-if="emissionFactor" class="red--text">
                  {{ $t("pages.assets.form.fixedLabels.saveShowEmissions") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="chosenByData" dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.categorizedBy") }}:
              </v-col>
              <v-col cols="7">
                {{ chosenByData }}
              </v-col>
            </v-row>
            <v-row v-if="!isSupplierSpecificMethod" dense class="mt-4">
              <v-col cols="5">
                {{
                  $t("pages.assets.form.fixedLabels.emissionFactor", {
                    currencyType: emissionFactor
                      ? emissionFactor.activityUnit
                      : "?",
                  })
                }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed"> </span>
                <span v-else-if="!emissionFactor" class="red--text">
                  {{ $t("pages.assets.form.validation.missingEmissionFactor") }}
                </span>
                <span v-else>
                  {{ formattedEmissionFactor }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="isSpendBasedMethod" dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.purchasePrice") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed" class="blue--text">
                  {{ $t("pages.assets.form.validation.assetPurchasedAsUsed") }}
                </span>
                <span
                  v-else-if="!purchasePrice || !purchasePriceCurrency"
                  class="red--text"
                >
                  {{ $t("pages.assets.form.validation.missingPurchasePrice") }}
                </span>
                <template v-else>
                  {{ formattedPurchasePrice }}
                </template>
              </v-col>
            </v-row>
            <v-row v-else-if="isAverageDataMethod" dense class="mt-4">
              <v-col cols="5">
                {{
                  $t("pages.assets.form.fixedLabels.quantity", {
                    type: formattedUnitType,
                  })
                }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed" class="blue--text">
                  {{ $t("pages.assets.form.validation.assetPurchasedAsUsed") }}
                </span>
                <span v-else-if="!assetQuantity" class="red--text">
                  {{ $t("pages.assets.form.validation.missingQuantity") }}
                </span>
                <template v-else>
                  {{ formattedQuantity }}
                </template>
              </v-col>
            </v-row>
            <v-row v-if="!isSupplierSpecificMethod" dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.factorSource") }}:
              </v-col>
              <v-col cols="7">
                <a
                  v-if="emissionFactor"
                  :href="emissionFactor?.sourceLink"
                  target="_blank"
                >
                  {{
                    isCustomEmissionFactor
                      ? $t("pages.assets.form.fixedLabels.customEmissionFactor")
                      : emissionFactor.source
                  }}
                </a>
              </v-col>
            </v-row>
            <v-row v-if="!isSupplierSpecificMethod" dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.uuid") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="emissionFactor">
                  {{ emissionFactor.id }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!isSupplierSpecificMethod" dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.year") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="emissionFactor">
                  {{ emissionFactor.yearsCalculatedFrom }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!isSupplierSpecificMethod" dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.yearRelease") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="emissionFactor">
                  {{
                    emissionFactor.yearReleased ??
                    emissionFactor.yearsCalculatedFrom
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row
              dense
              class="mt-4"
              v-if="!isSupplierSpecificMethod && emissionFactor"
            >
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.assessment") }}:
              </v-col>
              <v-col cols="7">
                {{
                  isCustomEmissionFactor
                    ? $t("pages.assets.form.fixedLabels.customEmissionFactor")
                    : selectedFactor
                }}
              </v-col>
            </v-row>
            <v-row v-if="!isSupplierSpecificMethod" dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.lcaActivity") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="emissionFactor">
                  {{
                    emissionFactor.lcaActivity ??
                    $t("pages.assets.form.fixedValues.unknown")
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row dense class="mt-4" v-if="isSupplierSpecificMethod">
              <v-col cols="4" class="d-flex align-center">
                <span>
                  {{
                    $t(
                      "pages.assets.form.fixedLabels.supplierSpecificDocumentation"
                    )
                  }}:
                </span>
              </v-col>
              <v-col cols="8">
                <v-chip
                  v-if="documentationUploadedFile"
                  :href="
                    route('api.files.content', {
                      id: documentationUploadedFile.id,
                      filename: documentationUploadedFile.originalName,
                    })
                  "
                  target="_blank"
                  color="primary"
                  label
                  outlined
                >
                  <v-icon>mdi-attachment</v-icon>
                  {{ documentationUploadedFile.originalName }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row
              dense
              class="mt-4"
              v-if="isCustomEmissionFactor && emissionFactor?.description"
            >
              <v-col cols="12">
                {{ $t("pages.assets.form.fixedLabels.info") }}
              </v-col>
              <v-col cols="12">{{ emissionFactor?.description }}</v-col>
            </v-row>
            <v-row
              dense
              class="mt-4"
              v-if="isCustomEmissionFactor && emissionFactor?.documentationFile"
            >
              <v-col cols="12">
                {{
                  $t("pages.assets.form.fixedLabels.customFactorDocumentation")
                }}
              </v-col>
              <v-col cols="12">
                <div
                  class="documentation-item d-flex flex-row align-center pl-4 mb-2"
                >
                  <PdfIcon></PdfIcon>
                  <v-btn
                    class="documentation-btn flex-grow-1 mb-0 ml-0 px-2 justify-start text-none font-weight-regular"
                    :href="
                      route('api.files.content', {
                        id: emissionFactor.documentationFile.id,
                        filename: emissionFactor.documentationFile.originalName,
                      })
                    "
                    target="_blank"
                    left
                    plain
                    >{{ emissionFactor.documentationFile.originalName }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </div>
          <v-card
            v-if="emissionFactor?.energyFactor"
            color="#f7f7f7"
            elevation="0"
            tile
            class="pa-2 mt-4"
          >
            <v-row class="" dense>
              <v-col cols="5" class="d-flex align-center">
                <span>
                  {{ $t("pages.assets.form.fixedLabels.energy") }} ({{
                    $t("pages.assets.form.fixedLabels.kWh")
                  }}):
                </span>
              </v-col>
              <v-col cols="7">
                <span
                  v-if="isAverageDataMethod && !activityUnitMatchEnergyFactor"
                  class="red--text"
                >
                  {{
                    $t(
                      "pages.assets.form.validation.activityTypeMismatchEnergyFactor",
                      {
                        activityUnit: activityUnit,
                        unitType: formattedUnitType,
                      }
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    isSupplierSpecificMethod ||
                    (formattedEmissionsData &&
                      calculatedEmissionFactorId === emissionFactor.id)
                  "
                >
                  {{ energyFactorKWh }}
                </span>
                <span v-else-if="emissionFactor" class="red--text">
                  {{ $t("pages.assets.form.fixedLabels.saveShowEnergyFactor") }}
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-4" dense>
              <v-col cols="5" class="d-flex align-center">
                <span>
                  {{ $t("pages.assets.form.fixedLabels.energyFactor") }} ({{
                    $t("pages.assets.form.fixedLabels.kWh")
                  }}/{{ emissionFactor.energyFactor.activityUnit }}):
                </span>
              </v-col>
              <v-col cols="7"> {{ formattedEnergyFactor }} </v-col>
            </v-row>
            <v-row class="mt-4" dense>
              <v-col cols="5" class="d-flex align-center">
                <span>
                  {{ $t("pages.assets.form.fixedLabels.energyFactorSource") }}:
                </span>
              </v-col>
              <v-col cols="7">
                <a
                  v-if="emissionFactor.energyFactor.sourceLink"
                  :href="emissionFactor.energyFactor.sourceLink"
                  target="_blank"
                  >{{ emissionFactor.energyFactor.source }}</a
                >
                <span v-else-if="emissionFactor.energyFactor.source">
                  {{ emissionFactor.energyFactor.source }}
                </span>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </dialog-form-section-two-cols>
    </v-card>
  </v-tab-item>
</template>
<script>
import AssetAttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import DialogFormSectionTwoCols from "../../../Components/Dialog/DialogFormSectionTwoCols.vue";
import PdfIcon from "../../../assets/svg/pdf.svg";
import {
  calculateEmissionsAverageData,
  calculateEmissionsSpendBased,
} from "../../../util/calculators";
import {
  co2MethodOptions,
  emissionConvertibleCurrencies,
  emissionCurrencies,
  emissionFactorStatus,
  fixedAttributes,
  fixedFieldAttributes,
} from "../../../util/fixedAssetData";
import {
  emissionsFormatter,
  formatEmissionFactorName,
  fullNumberFormatter,
} from "../../../util/formatters";

export default {
  components: { AssetAttributeLinkInput, DialogFormSectionTwoCols, PdfIcon },
  props: {
    asset: Object,
    assetAttributeLinkData: Object,
    attributes: Array,
    ghgCategories: Array,
    emissionFactor: Object,
    ghgCategoryId: String,
    calculatedEmissionFactorId: String,
    defaultCurrencyCode: String,
    tabValue: [String, Number],
    resetEmissionFactor: Boolean,
    autoApplyAssetAttributeLinks: Object,
    settings: Object,
  },
  data() {
    return {
      fixedAttributes,
      emissionSectors: [],
      emissionCategories: [],
      emissionRegions: [],
      emissionFactors: [],
      validCurrencies: emissionCurrencies,
      runningAI: false,
      loadingFields: {
        sectors: false,
        categories: false,
        regions: false,
        emissionFactors: false,
      },
    };
  },
  methods: {
    async runAIOnAsset() {
      this.runningAI = true;

      // Save asset first so fields on general aren't cleared.
      this.$emit("onRunAi", () => {
        this.runningAI = false;
      });
    },
    updateData(attributeId, value) {
      this.$emit("asset_attribute_data_changed", attributeId, value);
    },
    updateSecondValueData(attributeId, value) {
      this.$emit(
        "asset_attribute_data_second_value_changed",
        attributeId,
        value
      );
    },
    updateBool(value, attribute) {
      this.$emit("bool_changed", value, attribute);
    },
    handleMethodChanged(value) {
      this.$emit("select_changed", value, this.methodAttribute);

      if (this.sectorData) {
        this.$emit("string_changed", null, this.sectorAttribute);
        this.updateData(fixedAttributes.co2SectorId, null);
      }

      if (this.categoryData) {
        this.$emit("string_changed", null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.electricitySourceData) {
        this.$emit("bool_changed", null, this.electricitySourceAttribute);
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionCategories.length) this.emissionCategories = [];
      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleSectorChanged(value) {
      this.$emit("string_changed", value, this.sectorAttribute);

      if (this.categoryData) {
        this.$emit("string_changed", null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.electricitySourceData) {
        this.$emit("bool_changed", null, this.electricitySourceAttribute);
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleCategoryChanged(value) {
      this.$emit("string_changed", value, this.categoryAttribute);

      if (this.electricitySourceData) {
        this.$emit("bool_changed", null, this.electricitySourceAttribute);
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleElectricitySourceChanged(value) {
      this.$emit("bool_changed", value, this.electricitySourceAttribute);
    },
    handleRegionChanged(value) {
      this.$emit("string_changed", value, this.regionAttribute);

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }
    },
    handleNameChanged(value) {
      this.$emit("string_changed", value, this.nameAttribute);

      const emissionFactor =
        this.emissionFactors.find((x) => x.id === value) ?? null;

      this.$emit("emission_factor_changed", emissionFactor);
    },
    handleEmissionSourceChanged(value) {
      this.$emit("select_changed", value, this.emissionSourceAttribute);

      if (value === "cb483bd8-d20e-42a1-a252-c44ee12230ef") {
        this.$emit(
          "ghg_category_changed",
          "b0b345b4-87ad-4de5-b98f-4648d4c51edf"
        );
        this.updateData(
          this.ghgCategoryAttribute.id,
          "b0b345b4-87ad-4de5-b98f-4648d4c51edf"
        );
      }
      if (value === "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b") {
        this.$emit(
          "ghg_category_changed",
          "e82fdc22-1ae0-4066-a662-ffa744de11a3"
        );
        this.updateData(
          this.ghgCategoryAttribute.id,
          "e82fdc22-1ae0-4066-a662-ffa744de11a3"
        );
      }
      if (value === "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde") {
        this.$emit(
          "ghg_category_changed",
          "a588c70c-a620-4d67-b65e-5ef7b11a8f87"
        );
        this.updateData(
          this.ghgCategoryAttribute.id,
          "a588c70c-a620-4d67-b65e-5ef7b11a8f87"
        );
      }
    },
    handleGhgCategoryIdChanged(value) {
      this.$emit("string_changed", value, this.ghgCategoryAttribute);
      this.$emit("ghg_category_changed", value);
    },
    getEmissionSectors() {
      this.loadingFields["sectors"] = true;

      fetch(
        this.route("api.emission-sectors.index", {
          calculationMethod: this.calculationMethod,
          unitType: this.formattedUnitType,
          selectedFactorId: this.selectedEmissionFactorId,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionSectors = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["sectors"] = false;
        });
    },
    getEmissionCategories(sectorId) {
      this.loadingFields["categories"] = true;
      fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          calculationMethod: this.calculationMethod,
          unitType:
            this.formattedUnitType !== "unknown"
              ? this.formattedUnitType
              : null,
          selectedFactorId: this.selectedEmissionFactorId,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionCategories = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["categories"] = false;
        });
    },
    getEmissionRegions(categoryId) {
      this.loadingFields["regions"] = true;
      fetch(
        this.route("api.emission-regions.index", {
          emissionCategoryId: categoryId,
          spendBased: this.isSpendBasedMethod,
          unitType: this.formattedUnitType,
          selectedFactorId: this.selectedEmissionFactorId,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data.map((x) => ({
            ...x,
            value: x.countryName ? x.countryName : x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["regions"] = false;
        });
    },
    getEmissionFactors(sectorId, categoryId, regionId) {
      if (!sectorId || !categoryId || !regionId) return;

      this.loadingFields["emissionFactors"] = true;
      fetch(
        this.route("api.emission-factors.index", {
          emissionSectorId: sectorId,
          emissionCategoryId: categoryId,
          emissionRegionId: regionId,
          emissionFactorId: this.selectedEmissionFactorId,
          spendBased: this.isSpendBasedMethod,
          unitType: this.formattedUnitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionFactors = data.map((x) => ({
            ...x,
            value: formatEmissionFactorName(x),
          }));
        })
        .finally(() => {
          this.loadingFields["emissionFactors"] = false;
        });
    },
    approveEmission() {
      if (!this.asset?.id) return;

      this.$inertia.patch(
        this.route("assets.emission-factor.approve", this.asset.id),
        {},
        {
          onSuccess: () => this.$emit("reload"),
        }
      );
    },
    getFactorVersionLabel(factor) {
      const {
        activityId,
        activityUnit,
        lcaActivity,
        yearReleased,
        yearsCalculatedFrom,
      } = this.emissionFactor ?? {};

      if (
        factor.activityId !== activityId ||
        factor.activityUnit !== activityUnit ||
        factor.lcaActivity !== lcaActivity
      )
        return "";

      const year = yearsCalculatedFrom ?? yearReleased;
      const factorYear = factor.yearsCalculatedFrom ?? factor.yearReleased;

      const labelText =
        factorYear > year
          ? this.$t("common.statuses.emissionFactor.version.updated")
          : this.$t("common.statuses.emissionFactor.version.outdated");

      return `(${labelText})`;
    },
  },
  computed: {
    calculationMethod() {
      let calculationMethod = "average";

      if (this.isSpendBasedMethod) {
        calculationMethod = "spend";
      } else if (this.isSupplierSpecificMethod) {
        calculationMethod = "supplier";
      }

      return calculationMethod;
    },
    isCustomEmissionFactor() {
      return (
        this.emissionFactor?.source == null &&
        (this.emissionFactor?.organizationId !== null ||
          this.emissionFactor?.organizationGroupId !== null)
      );
    },
    hasEnoughDataForAI() {
      return (
        typeof this.calculatedEmissions === "undefined" &&
        this.assetQuantity &&
        this.purchasePrice &&
        this.purchasePriceCurrency &&
        this.validCurrencies.includes(this.purchasePriceCurrency) &&
        this.formattedUnitType !== "unknown"
      );
    },
    emissionSourceData() {
      return this.assetAttributeLinkData[fixedAttributes.co2EmissionSourceId];
    },
    emissionSourceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2EmissionSourceId
      );
    },
    ghgCategoryAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2GhgCategoryId
      );
    },
    methodData() {
      return this.assetAttributeLinkData[fixedAttributes.co2MethodId];
    },
    methodAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2MethodId);
    },
    sectorData() {
      return this.assetAttributeLinkData[fixedAttributes.co2SectorId];
    },
    sectorAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2SectorId);
    },
    categoryData() {
      return this.assetAttributeLinkData[fixedAttributes.co2CategoryId];
    },
    electricitySourceData() {
      return this.assetAttributeLinkData[
        fixedAttributes.co2ElectricitySourceId
      ];
    },
    categoryAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2CategoryId
      );
    },
    electricitySourceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2ElectricitySourceId
      );
    },
    regionData() {
      const assetRegion =
        this.assetAttributeLinkData[fixedAttributes.co2RegionId];

      const fallbackRegion = this.settings.defaultRegionId;

      // When region data is automatically set to default region emission factors are not updated correctly.
      const selectedRegion = assetRegion ? assetRegion : fallbackRegion;

      // Made unconditional to fix VER-509
      // Added getEmissionFactors to force an update (ref VER-333)
      this.getEmissionFactors(
        this.sectorData,
        this.categoryData,
        selectedRegion
      );

      return selectedRegion;
    },
    regionAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2RegionId);
    },
    nameData() {
      return this.assetAttributeLinkData[fixedAttributes.co2NameId];
    },
    nameAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2NameId);
    },
    purchasePrice() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalPurchasePriceId
      ];
    },
    purchasePriceCurrency() {
      const currency =
        this.assetAttributeLinkData[
          fixedAttributes.generalPurchasePriceId + "-secondValue"
        ];

      return currency?.trim() ?? this.defaultCurrencyCode;
    },
    unitType() {
      return this.assetAttributeLinkData[fixedAttributes.generalUnitTypeId];
    },
    unitTypeAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalUnitTypeId
      );
    },
    formattedUnitType() {
      const defaultValue = this.$t(
        "pages.assets.form.fixedLabels.unknownUnitType"
      );

      if (!this.unitType || !this.unitTypeAttribute) return defaultValue;

      const selectedType = this.unitTypeAttribute.attributeSelectOptions.find(
        (x) => x.id === this.unitType
      )?.value;

      return selectedType ?? defaultValue;
    },
    emissionsAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2EmissionsId
      );
    },
    emissionsData() {
      return this.assetAttributeLinkData[fixedAttributes.co2EmissionsId];
    },
    documentationAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2DocumentationId
      );
    },
    documentationData() {
      return this.assetAttributeLinkData[fixedAttributes.co2DocumentationId];
    },
    documentationSecondValueData() {
      return this.assetAttributeLinkData[
        fixedAttributes.co2DocumentationId + "-secondValue"
      ];
    },
    documentationUploadedFile() {
      if (!this.documentationSecondValueData?.length) return null;

      return this.documentationSecondValueData[0];
    },
    factorTypeAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.co2FactorTypeId
      );
    },
    assetQuantity() {
      return this.assetAttributeLinkData[fixedAttributes.generalQuantityId];
    },
    formattedPurchasePrice() {
      if (!this.purchasePrice || !this.purchasePriceCurrency) return null;

      return `${this.purchasePriceCurrency} ${fullNumberFormatter.format(
        this.purchasePrice
      )}`;
    },
    formattedEmissionsData() {
      if (!this.emissionsData) return null;

      return emissionsFormatter.format(this.emissionsData);
    },
    formattedQuantity() {
      if (!this.assetQuantity) return null;

      return fullNumberFormatter.format(this.assetQuantity);
    },
    formattedEmissionFactor() {
      if (!this.emissionFactor) return "";

      let factor = this.emissionFactor.kgCo2eAr6;

      if (!factor) factor = this.emissionFactor.kgCo2eAr5;
      if (!factor) factor = this.emissionFactor.kgCo2eAr4;
      if (!factor) factor = 0;

      return fullNumberFormatter.format(factor);
    },
    formattedEnergyFactor() {
      const energyFactorKWh =
        Number(this.emissionFactor?.energyFactor?.mWh ?? 0) * 1000;
      return emissionsFormatter.format(energyFactorKWh);
    },
    energyFactorKWh() {
      const value =
        this.assetAttributeLinkData[fixedFieldAttributes.co2EnergyFactorKwhId];
      return emissionsFormatter.format(value);
    },
    calculatedEmissions() {
      if (this.isPurchasedAsUsed) {
        return "0";
      }

      if (this.isSpendBasedMethod) {
        return calculateEmissionsSpendBased(
          this.purchasePrice,
          this.purchasePriceCurrency,
          this.emissionFactor
        );
      } else if (this.isAverageDataMethod && this.activityUnitMatch) {
        return calculateEmissionsAverageData(
          this.assetQuantity,
          this.formattedUnitType,
          this.emissionFactor
        );
      }
      return undefined;
    },
    selectedFactor() {
      if (!this.emissionFactor) return;

      return this.emissionFactor.kgCo2eAr6
        ? "AR 6"
        : this.emissionFactor.kgCo2eAr5
        ? "AR 5"
        : "AR 4";
    },
    isAverageDataMethod() {
      return this.methodData === co2MethodOptions.averageDataMethodId;
    },
    isSpendBasedMethod() {
      return this.methodData === co2MethodOptions.spendBasedMethodId;
    },
    isSupplierSpecificMethod() {
      return this.methodData === co2MethodOptions.supplierSpecificMethodId;
    },
    activityUnit() {
      if (!this.emissionFactor)
        return this.$t("pages.assets.form.fixedLabels.unknownUnitType");

      return this.emissionFactor.activityUnit;
    },
    activityUnitMatch() {
      return (
        this.activityUnit?.toUpperCase() ===
        this.formattedUnitType?.toUpperCase()
      );
    },
    activityUnitMatchEnergyFactor() {
      return (
        this.emissionFactor?.energyFactor?.activityUnit?.toUpperCase() ===
        this.formattedUnitType?.toUpperCase()
      );
    },
    purchasePriceCurrencyMatch() {
      const priceCurrency = this.purchasePriceCurrency;
      const activityCurrency = this.activityUnit;

      // We don't handle missing values in this computed value
      if (!priceCurrency || !activityCurrency) return true;
      else if (priceCurrency === activityCurrency) return true;
      else if (
        emissionConvertibleCurrencies.includes(priceCurrency) &&
        emissionConvertibleCurrencies.includes(activityCurrency)
      )
        return true;
      else return false;
    },
    factorStatusData() {
      return this.assetAttributeLinkData[
        fixedFieldAttributes.co2EmissionFactorStatusId
      ];
    },
    factorIsDefined() {
      return this.factorStatusData === emissionFactorStatus.defined;
    },
    isPurchasedAsUsed() {
      let isPurchasedAsUsed =
        this.assetAttributeLinkData[fixedAttributes.generalProductionDateId];

      if (isPurchasedAsUsed) {
        this.$emit("emission_factor_changed", null);
        this.$emit("decimal_changed", 0, this.emissionsAttribute);
      }

      return isPurchasedAsUsed;
    },
    filteredGhgCategories() {
      if (!this.emissionSourceData) return [];

      const categories = this.ghgCategories
        .filter((x) => x.scopeId === this.emissionSourceData)
        .sort((a, b) => ((a.sortOrder ?? 0) > (b.sortOrder ?? 0) ? 1 : -1));

      return categories;
    },
    chosenByData() {
      return this.assetAttributeLinkData[fixedFieldAttributes.co2ChosenById];
    },
    selectedEmissionFactorId() {
      return this.emissionFactor?.id ?? null;
    },
  },
  mounted() {
    this.getEmissionSectors();

    if (this.sectorData) {
      this.getEmissionCategories(this.sectorData);
    }

    if (this.categoryData) {
      this.getEmissionRegions(this.categoryData);
    }

    if (this.sectorData && this.categoryData && this.regionData) {
      this.getEmissionFactors(
        this.sectorData,
        this.categoryData,
        this.regionData
      );
    }

    // Copilot auto apply attribute links
    if (this.autoApplyAssetAttributeLinks) {
      this.updateData(
        fixedAttributes.co2SectorId,
        this.autoApplyAssetAttributeLinks[fixedAttributes.co2SectorId]
      );
      this.updateData(
        fixedAttributes.co2CategoryId,
        this.autoApplyAssetAttributeLinks[fixedAttributes.co2CategoryId]
      );
      this.updateData(
        fixedAttributes.co2RegionId,
        this.autoApplyAssetAttributeLinks[fixedAttributes.co2RegionId]
      );
      this.updateData(
        fixedAttributes.co2NameId,
        this.autoApplyAssetAttributeLinks[fixedAttributes.co2NameId]
      );
      setTimeout(() => {
        this.handleNameChanged(
          this.autoApplyAssetAttributeLinks[fixedAttributes.co2NameId]
        );
      }, 4000);
    }
  },
  watch: {
    methodData() {
      this.getEmissionSectors();
    },
    sectorData(value) {
      this.getEmissionCategories(value);
    },
    categoryData(value) {
      this.getEmissionRegions(value);
    },
    regionData(value) {
      if (!this.sectorData || !this.categoryData) return;
      this.getEmissionFactors(this.sectorData, this.categoryData, value);
    },
    resetEmissionFactor(value) {
      if (!value) return;

      if (!this.isAverageDataMethod) {
        this.$emit("emissionFactorReset");
        return;
      }

      if (this.sectorData) {
        this.$emit("string_changed", null, this.sectorAttribute);
        this.updateData(fixedAttributes.co2SectorId, null);
      }

      if (this.categoryData) {
        this.$emit("string_changed", null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.electricitySourceData) {
        this.$emit("bool_changed", null, this.electricitySourceAttribute);
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionCategories.length) this.emissionCategories = [];
      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];

      this.getEmissionSectors();

      this.$emit("emissionFactorReset");
    },
  },
};
</script>

<style lang="scss" scoped>
.documentation-item {
  background: #fef2ef;
  border: 1px solid #fef2ef;

  .documentation-btn {
    color: #686868;
    flex: 1;
    overflow: hidden;
    letter-spacing: 0;
  }

  &:hover {
    border-color: #f25d3b;

    .documentation-btn * {
      color: #f25d3b;
    }
  }
}
</style>
