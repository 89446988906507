<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 pt-2">
            <div class="d-flex flex-row align-center">
              <div class="flex-grow-1">
                <p class="page-title">
                  {{ $t("components.reportTemplates.emissionsByGhgs.title") }}
                </p>
              </div>
              <div class="d-flex flex-row">
                <div
                  v-for="(item, index) in [
                    { color: '#266663', name: 'Scope 1' },
                    { color: '#6493BE', name: 'Scope 2' },
                    { color: '#E35E3E', name: 'Scope 3' },
                  ]"
                  :key="index"
                  class="d-flex ml-sm-4 mb-4 mb-sm-0 align-center"
                >
                  <div
                    class="mr-2 rounded-4"
                    :style="
                      'background-color:' +
                      item.color +
                      ';display: inline-block; min-width: 16px; height: 16px; border-radius: 4px;'
                    "
                  ></div>
                  <div style="color: #b4b4b4">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <!---->
            <v-row>
              <v-col v-for="category in categories" :key="category.id" cols="3">
                <icon-with-percentage
                  :title="category.name"
                  :icon-back-color="category.iconColor"
                  :percent-value="category.emissions"
                  :percentage-prefix="
                    category.emissions === 0.1 ? '<' : undefined
                  "
                >
                  <template v-slot:icon>
                    <!-- Dynamically render the icon based on the category -->
                    <component
                      :is="category.iconComponent"
                      :width="category.iconWidth"
                      :height="category.iconHeight"
                      :style="{
                        width: category.iconWidth,
                        height: category.iconHeight,
                        borderRadius: 0,
                      }"
                    ></component>
                  </template>
                </icon-with-percentage>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div class="bottom-note">
          {{
            "* " +
            $t("components.reportTemplates.emissionsByGhgs.dueToRounding")
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessTravelIcon from "../../../src/assets/svg/report-business-travel.svg";
import CapitalGoodsIcon from "../../../src/assets/svg/report-capital-goods.svg";
import CompanyFacilitiesIcon from "../../../src/assets/svg/report-company-facilities.svg";
import CompanyVehiclesIcon from "../../../src/assets/svg/report-company-vehicles.svg";
import DownstreamLeasedAssetsIcon from "../../../src/assets/svg/report-downstream-leased-assets.svg";
import DownstreamTransportationAndDistributionIcon from "../../../src/assets/svg/report-downstream-transportation-and-distribution.svg";
import ElectricityHeatingIcon from "../../../src/assets/svg/report-electricity-and-heating.svg";
import ElectricityLocationBasedIcon from "../../../src/assets/svg/report-electricity-location-based.svg";
import ElectricityMarketBasedIcon from "../../../src/assets/svg/report-electricity-market-based.svg";
import EmployeeCommutingIcon from "../../../src/assets/svg/report-employee-commuting.svg";
import EndOfLifeTreatmentOfSoldProductsIcon from "../../../src/assets/svg/report-end-of-life-treatment-of-sold-products.svg";
import FranchisesIcon from "../../../src/assets/svg/report-franchises.svg";
import FuelAndEnergyRelatedActivitiesIcon from "../../../src/assets/svg/report-fuel-and-energy-related-activities.svg";
import InvestmentsIcon from "../../../src/assets/svg/report-investments.svg";
import ProcessingOfSoldProductsIcon from "../../../src/assets/svg/report-processing-of-sold-products.svg";
import PurchasedGoodsServicesIcon from "../../../src/assets/svg/report-purchased-goods-services.svg";
import SteamHeatingCoolingIcon from "../../../src/assets/svg/report-steam-heating-cooling.svg";
import UpstreamLeasedAssetsIcon from "../../../src/assets/svg/report-upstream-leased-assets.svg";
import UseOfSoldProductsIcon from "../../../src/assets/svg/report-use-of-sold-products.svg";
import WasteGeneratedInOperationsIcon from "../../../src/assets/svg/report-waste-generated-in-operations.svg";
import IconWithPercentage from "../Outputs/IconWithPercentage.vue";

export default {
  components: {
    IconWithPercentage,
    CompanyFacilitiesIcon,
    CapitalGoodsIcon,
    ElectricityHeatingIcon,
    ElectricityMarketBasedIcon,
    ElectricityLocationBasedIcon,
    SteamHeatingCoolingIcon,
    BusinessTravelIcon,
    CompanyVehiclesIcon,
    DownstreamLeasedAssetsIcon,
    DownstreamTransportationAndDistributionIcon,
    EmployeeCommutingIcon,
    EndOfLifeTreatmentOfSoldProductsIcon,
    FranchisesIcon,
    FuelAndEnergyRelatedActivitiesIcon,
    ProcessingOfSoldProductsIcon,
    PurchasedGoodsServicesIcon,
    UpstreamLeasedAssetsIcon,
    WasteGeneratedInOperationsIcon,
    UseOfSoldProductsIcon,
    InvestmentsIcon,
  },
  props: {
    dateRange: { type: Object, default: () => ({ from: "", to: "" }) },
    ghgCategorySummariesCurrentPeriod: Array,
  },
  data() {
    return {
      categories: [
        //NOTE: Removed because of the split of el sources on the backend
        // {
        //   id: "e82fdc22-1ae0-4066-a662-ffa744de11a3",
        //   name: "Electricity Heating",
        //   iconColor: "#6493BE33",
        //   emissionPercent: 0,
        //   iconComponent: "ElectricityHeatingIcon",
        //   iconWidth: "36px",
        //   iconHeight: "34px",
        // },
        {
          id: "292656bd-cae4-4265-ba52-7e69c1d3e78f",
          name: this.$t(
            "common.ghgCategories.292656bd-cae4-4265-ba52-7e69c1d3e78f"
          ),
          iconColor: "#6493BE33",
          emissionPercent: 0,
          iconComponent: "ElectricityMarketBasedIcon",
          iconWidth: "21px",
          iconHeight: "30px",
        },
        {
          id: "b0672a9c-3008-406a-b6f8-02ea99465b7e",
          name: this.$t(
            "common.ghgCategories.b0672a9c-3008-406a-b6f8-02ea99465b7e"
          ),
          iconColor: "#6493BE33",
          emissionPercent: 0,
          iconComponent: "ElectricityLocationBasedIcon",
          iconWidth: "32px",
          iconHeight: "29px",
        },
        {
          id: "b2a165b7-5e04-482f-92bd-9366707ddd0e",
          name: this.$t(
            "common.ghgCategories.b2a165b7-5e04-482f-92bd-9366707ddd0e"
          ),
          iconColor: "#6493BE33",
          emissionPercent: 0,
          iconComponent: "SteamHeatingCoolingIcon",
          iconWidth: "26px",
          iconHeight: "34px",
        },
        {
          id: "a588c70c-a620-4d67-b65e-5ef7b11a8f87",
          name: this.$t(
            "common.ghgCategories.a588c70c-a620-4d67-b65e-5ef7b11a8f87"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "PurchasedGoodsServicesIcon",
          iconWidth: "32px",
          iconHeight: "30px",
        },
        {
          id: "3b8537c0-102c-40a1-be40-796ee826b9f8",
          name: this.$t(
            "common.ghgCategories.3b8537c0-102c-40a1-be40-796ee826b9f8"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "UpstreamLeasedAssetsIcon",
          iconWidth: "30px",
          iconHeight: "28px",
        },
        {
          id: "783b4f4b-54c0-48c4-8949-b2cdf4a03911",
          name: this.$t(
            "common.ghgCategories.783b4f4b-54c0-48c4-8949-b2cdf4a03911"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "DownstreamTransportationAndDistributionIcon",
          iconWidth: "32px",
          iconHeight: "32px",
        },
        {
          id: "7595baac-87cd-40b5-a907-b1899b7fc62c",
          name: this.$t(
            "common.ghgCategories.7595baac-87cd-40b5-a907-b1899b7fc62c"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "DownstreamTransportationAndDistributionIcon",
          iconWidth: "32px",
          iconHeight: "32px",
        },
        {
          id: "3edee4c8-a1e4-4a77-a978-5da86d2ad7ba",
          name: this.$t(
            "common.ghgCategories.3edee4c8-a1e4-4a77-a978-5da86d2ad7ba"
          ),
          iconColor: "#26666333",
          emissions: 0,
          iconComponent: "CompanyFacilitiesIcon",
          iconWidth: "29px",
          iconHeight: "31px",
        },
        {
          id: "4eddd004-d305-4f4c-8ed5-a7d131f31db2",
          name: this.$t(
            "common.ghgCategories.4eddd004-d305-4f4c-8ed5-a7d131f31db2"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "ProcessingOfSoldProductsIcon",
          iconWidth: "32px",
          iconHeight: "32px",
        },
        {
          id: "0fcb2df8-35bf-429e-bf6f-f73851616b58",
          name: this.$t(
            "common.ghgCategories.0fcb2df8-35bf-429e-bf6f-f73851616b58"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "UseOfSoldProductsIcon",
          iconWidth: "43px",
          iconHeight: "28px",
        },
        {
          id: "06b4a64c-d4be-43d9-bf73-6968fc778414",
          name: this.$t(
            "common.ghgCategories.06b4a64c-d4be-43d9-bf73-6968fc778414"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "EmployeeCommutingIcon",
          iconWidth: "36px",
          iconHeight: "27px",
        },
        {
          id: "a431af20-eb08-420d-8a62-9e2d2f011f28",
          name: this.$t(
            "common.ghgCategories.a431af20-eb08-420d-8a62-9e2d2f011f28"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "FranchisesIcon",
          iconWidth: "32px",
          iconHeight: "28px",
        },
        {
          id: "24f8d5db-3871-464a-a52f-af31df23c978",
          name: this.$t(
            "common.ghgCategories.24f8d5db-3871-464a-a52f-af31df23c978"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "CapitalGoodsIcon",
          iconWidth: "31px",
          iconHeight: "34px",
        },
        {
          id: "97b1cb84-a7a0-4992-a0af-24d61f415510",
          name: this.$t(
            "common.ghgCategories.97b1cb84-a7a0-4992-a0af-24d61f415510"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "BusinessTravelIcon",
          iconWidth: "28px",
          iconHeight: "28px",
        },
        {
          id: "e966141a-3d58-4096-967d-2f4e0776c75a",
          name: this.$t(
            "common.ghgCategories.e966141a-3d58-4096-967d-2f4e0776c75a"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "WasteGeneratedInOperationsIcon",
          iconWidth: "27px",
          iconHeight: "29px",
        },
        {
          id: "3057fd53-9d3b-412a-9050-f8ad989e3727",
          name: this.$t(
            "common.ghgCategories.3057fd53-9d3b-412a-9050-f8ad989e3727"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "DownstreamLeasedAssetsIcon",
          iconWidth: "30px",
          iconHeight: "28px",
        },
        {
          id: "b0b345b4-87ad-4de5-b98f-4648d4c51edf",
          name: this.$t(
            "common.ghgCategories.b0b345b4-87ad-4de5-b98f-4648d4c51edf"
          ),
          iconColor: "#26666333",
          emissions: 0,
          iconComponent: "CompanyVehiclesIcon",
          iconWidth: "32px",
          iconHeight: "24px",
        },
        {
          id: "023053e9-84e3-47cb-9692-dc0243794af9",
          name: this.$t(
            "common.ghgCategories.023053e9-84e3-47cb-9692-dc0243794af9"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "FuelAndEnergyRelatedActivitiesIcon",
          iconWidth: "20px",
          iconHeight: "23px",
        },
        {
          id: "c3e8af39-30ee-44ab-84f7-58b28077a0de",
          name: this.$t(
            "common.ghgCategories.c3e8af39-30ee-44ab-84f7-58b28077a0de"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "InvestmentsIcon",
          iconWidth: "32px",
          iconHeight: "32px",
        },
        {
          id: "a147d878-dca6-436d-89ff-ab210f302d96",
          name: this.$t(
            "common.ghgCategories.a147d878-dca6-436d-89ff-ab210f302d96"
          ),
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "EndOfLifeTreatmentOfSoldProductsIcon",
          iconWidth: "30px",
          iconHeight: "28px",
        },
      ],
      ghgCategorySummariesCurrentPeriodFiltered: Array,
    };
  },
  watch: {
    ghgCategorySummariesCurrentPeriod(to) {
      //Removing the total of the el and heating since we split it in 3
      const filteredData = to?.filter(
        (category) => category.id !== "e82fdc22-1ae0-4066-a662-ffa744de11a3"
      );

      this.ghgCategorySummariesCurrentPeriodFiltered = filteredData;
      this.addEmissionPercentToCategories();
      this.orderCategories();
    },
  },
  methods: {
    addEmissionPercentToCategories() {
      let totalEmissions = 0;

      Object.values(this.categories).forEach((category) => {
        const categoryEmissions =
          this.findCategoryById(category.id)?.emissions || 0;
        totalEmissions += categoryEmissions;
      });

      Object.keys(this.categories).forEach((key) => {
        const category = this.categories[key];
        const categoryEmissions =
          this.findCategoryById(category.id)?.emissions || 0;

        this.categories[key].emissions = this.getPercentage(
          categoryEmissions,
          totalEmissions
        );
      });

      this.adjustPercentagesIfNeeded();

      this.orderCategories();
    },
    adjustPercentagesIfNeeded() {
      const percentageValues = Object.values(this.categories).map(
        (category) => category.emissions
      );

      const sum = percentageValues.reduce((acc, val) => acc + val, 0);

      if (sum > 100) {
        const maxIndex = percentageValues.indexOf(
          Math.max(...percentageValues)
        );

        const excess = sum - 100;

        const adjustedEmissions = this.categories[maxIndex].emissions - excess;

        this.categories[maxIndex].emissions =
          Math.round(adjustedEmissions * 10) / 10;
      }
    },
    orderCategories() {
      this.categories = this.categories.sort((a, b) => {
        return a.emissions >= b.emissions ? -1 : 1;
      });
    },
    findCategoryById(categoryId) {
      return this.ghgCategorySummariesCurrentPeriod?.find(
        (cat) => cat.id === categoryId
      );
    },
    getPercentage(emissions, total) {
      if (isNaN(total) || isNaN(emissions) || total === 0) return 0;

      const emissionPercentageFactor = (emissions / total) * 1000;

      // will be rounded to 0% so we adjust to 0,1%
      if (emissionPercentageFactor != 0 && emissionPercentageFactor < 0.5) {
        return 0.1;
      }

      return Math.round(emissionPercentageFactor) / 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 35px;
}
.bottom-note {
  width: 595px;
  height: 48px;
  background-color: rgba(244, 244, 244, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding: 8px;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
}

.bottom-note:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 48px solid rgba(249, 249, 249, 1);
  border-left: 30px solid rgba(244, 244, 244, 1);
  width: 0;
}
</style>
