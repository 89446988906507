<template>
  <v-row dense>
    <!--<v-col v-if="hideLabel === undefined" cols="4" align-self="center">
      {{ inputLabel + ":" }}
    </v-col>-->

    <!-- BOOLEAN -->
    <v-col
      v-if="attribute.format == 'Boolean' && !selectOptions"
      align-self="center"
    >
      <v-select
        :key="rerenderCounter"
        v-model="currentValue"
        :items="boolValueOptions"
        hide-details
        clearable
        item-text="text"
        item-value="value"
        solo
        @change="$emit('bool_changed', $event)"
        flat
        single-line
        filled
        readonly
        :loading="loading"
      ></v-select>
    </v-col>
    <!-- /BOOLEAN -->

    <!-- CURRENCY -->
    <v-col
      v-if="attribute.format == 'Currency' && !selectOptions"
      align-self="center"
    >
      <dialog-form-input-wrapper :title="inputLabel">
        <v-row dense align="center">
          <v-col :cols="onlyCurrency ? '12' : '6'" align-self="center">
            <v-autocomplete
              class="rounded-0 mb-2"
              background-color="#F7F7F7"
              color="#F25D3B"
              solo
              flat
              single-line
              filled
              :hide-details="true"
              :key="rerenderCounter"
              v-model="currentCurrencyCode"
              :items="currencies"
              clearable
              hide-selected
              item-text="currencyCode"
              item-value="currencyCode"
              @change="handleCurrencyCodeChanged"
              :loading="loading"
            >
            </v-autocomplete>
          </v-col>
          <v-col v-if="!onlyCurrency" cols="6" align-self="center">
            <v-text-field
              ref="numberInput"
              class="rounded-0 mb-2"
              color="#F25D3B"
              background-color="#F7F7F7"
              solo
              flat
              single-line
              filled
              :hide-details="true"
              :key="rerenderCounter"
              hide-spin-buttons
              :value="renderedPrice"
              @input="($value) => handlePriceInput($value)"
              clearable
              @change="$emit('currency_changed', currentValue)"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </dialog-form-input-wrapper>
    </v-col>
    <!-- /CURRENCY -->

    <!-- DATE -->
    <v-col
      v-if="attribute.format == 'Date' && !selectOptions"
      align-self="center"
    >
      <dialog-form-date-selector
        :key="rerenderCounter"
        :title="inputLabel"
        @change="$emit('dateTime_changed', $event)"
        :placeholder="datePlaceholder"
        :disabled="disabledDate"
        :value="currentValue"
        :date-format="dateFormat"
      ></dialog-form-date-selector>
    </v-col>

    <!-- DATETIME -->
    <v-col
      v-if="attribute.format == 'DateTime' && !selectOptions"
      align-self="center"
    >
      <date-picker
        :lang="lang"
        first-day-of-week="1"
        :key="rerenderCounter"
        style="width: full"
        :appendToBody="false"
        v-model="currentValue"
        type="datetime"
        :disabled-date="disabledDate"
        @change="$emit('dateTime_changed', $event)"
      >
      </date-picker>
    </v-col>
    <v-col v-if="attribute.format == 'DateTime'" align-self="center">
      <span>{{ $t("pages.assets.form.dateTimeFormat") }}</span>
    </v-col>
    <!-- /DATETIME -->

    <!-- NUMBER -->
    <v-col
      v-if="attribute.format == 'Number' && !selectOptions"
      align-self="center"
    >
      <dialog-form-input-wrapper :title="inputLabel">
        <v-text-field
          ref="numberInput"
          :key="rerenderCounter"
          hide-spin-buttons
          hide-details
          :value="renderedNumber"
          @input="($value) => handleNumberInputEvent($value)"
          clearable
          solo
          class="rounded-0 mb-2"
          background-color="#F7F7F7"
          flat
          single-line
          filled
          :loading="loading"
        >
        </v-text-field>
      </dialog-form-input-wrapper>
    </v-col>
    <!-- /NUMBER -->

    <!-- PERCENTAGE -->
    <v-col
      v-if="attribute.format == 'Percentage' && !selectOptions"
      align-self="center"
    >
      <dialog-form-input-wrapper :title="inputLabel">
        <v-text-field
          :key="rerenderCounter"
          dense
          hide-details
          :value="currentValue"
          @input="
            ($value) =>
              (currentValue = isNaN($value) ? null : parseFloat($value))
          "
          clearable
          type="number"
          solo
          hide-spin-buttons
          append-icon="mdi-percent-outline"
          @change="$emit('decimal_changed', $event)"
          :loading="loading"
        >
        </v-text-field>
      </dialog-form-input-wrapper>
    </v-col>
    <!-- /PERCENTAGE -->

    <!-- SELECT -->
    <v-col
      v-if="attribute.format == 'Select' || selectOptions"
      align-self="center"
    >
      <dialog-form-input-wrapper :title="inputLabel">
        <v-autocomplete
          :key="rerenderCounter"
          hide-details
          v-model="currentValue"
          :items="selectDropdownOptions"
          clearable
          hide-selected
          :item-text="itemText"
          :item-value="itemValue"
          solo
          @change="$emit('select_changed', $event)"
          class="rounded-0 mb-2"
          style="cursor: pointer"
          background-color="#F7F7F7"
          flat
          filled
          light
          :menu-props="{ top: false, offsetY: true }"
          color="red"
          item-color="red"
          error-messages=""
          :value="value"
          :loading="loading"
          :placeholder="placeholder"
        >
          <template v-if="$scopedSlots.selectItem" #item="params">
            <slot name="selectItem" v-bind="params"></slot>
          </template>
        </v-autocomplete>
      </dialog-form-input-wrapper>
    </v-col>
    <!-- /SELECT -->

    <!-- TEXT -->
    <v-col
      v-if="attribute.format == 'Text' && !selectOptions"
      align-self="center"
    >
      <DialogFormTextInput
        :title="inputLabel"
        v-model="currentValue"
        @change="$emit('string_changed', $event)"
      ></DialogFormTextInput>
      <!--<v-text-field
        :key="rerenderCounter"
        dense
        hide-details
        v-model="currentValue"
        clearable
        type="text"
        solo
        @change="$emit('string_changed', $event)"
      >
      </v-text-field>-->
    </v-col>
    <!-- /TEXT -->

    <!-- TEXTAREA -->
    <v-col
      v-if="attribute.format == 'TextArea' && !selectOptions"
      align-self="center"
    >
      <DialogFormTextAreaInput
        :title="inputLabel"
        v-model="currentValue"
        @change="$emit('string_changed', $event)"
      ></DialogFormTextAreaInput>
    </v-col>
    <!-- /TEXTAREA -->

    <!-- FILE -->
    <v-col
      v-if="attribute.format == 'File' && !selectOptions"
      align-self="center"
    >
      <dialog-form-input-wrapper :title="inputLabel">
        <div v-if="currentSecondValue !== null" class="d-flex flex-column">
          <div
            v-for="file in currentSecondValue"
            :key="file.id"
            class="d-flex flex-row align-center mb-1"
          >
            <a
              class="text-decoration-none"
              :href="
                route('api.files.content', {
                  id: file.id,
                  filename: file.originalName,
                })
              "
              target="_blank"
            >
              <v-icon>mdi-attachment</v-icon>
              {{ file.originalName }}
            </a>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="ml-2"
              @click="() => $emit('file_removed', file.id)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-file-input
          :key="rerenderCounter"
          prepend-icon=""
          :multiple="attribute.multipleFiles"
          hide-details
          v-model="currentValue"
          show-size
          :rules="fileSizeRules"
          solo
          flat
          filled
          style="cursor: pointer"
          background-color="#F7F7F7"
          @change="$emit('file_changed', $event)"
        />
      </dialog-form-input-wrapper>
    </v-col>
    <!-- /FILE -->
  </v-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import DialogFormTextInput from "../Dialog/inputs/DialogFormTextInput.vue";
import DialogFormTextAreaInput from "../Dialog/inputs/DialogFormTextAreaInput.vue";
import DialogFormInputWrapper from "../Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormDateSelector from "../Dialog/inputs/DialogFormDateSelector.vue";

export default {
  components: {
    DatePicker,
    DialogFormTextInput,
    DialogFormTextAreaInput,
    DialogFormInputWrapper,
    DialogFormDateSelector,
  },
  props: [
    "value",
    "secondValue",
    "attribute",
    "currencies",
    "selectOptions",
    "integerInput",
    "numberMinValue",
    "numberMaxValue",
    "hideLabel",
    "customLabel",
    "selectItemText",
    "selectItemValue",
    "disabledDate",
    "loading",
    "placeholder",
    "onlyCurrency",
    "dateFormat",
  ],
  emits: [
    "file_changed",
    "string_changed",
    "currency_changed",
    "currencyCode_changed",
    "select_changed",
    "decimal_changed",
    "dateTime_changed",
    "bool_changed",
  ],
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      boolValueOptions: [
        { value: true, text: "true" },
        { value: false, text: "false" },
      ],
      fileSizeRules: [
        (value) =>
          !value ||
          value.size < 30000000 ||
          "File size should be less than 26 MB",
      ],
      rerenderCounter: 0,
    };
  },
  computed: {
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode;
    },
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentSecondValue: {
      get() {
        return this.secondValue;
      },
      set(value) {
        this.$emit("input_secondValue", value);
      },
    },
    currentCurrencyCode: {
      get() {
        if (
          !this.onlyCurrency &&
          this.defaultCurrencyCode &&
          (!this.secondValue || this.secondValue.trim() === "")
        )
          return this.defaultCurrencyCode;

        return this.secondValue;
      },
      set(value) {
        if (value === this.defaultCurrencyCode) {
          this.currentSecondValue = null;
          return;
        }

        this.currentSecondValue = value;
      },
    },
    selectDropdownOptions() {
      if (!this.selectOptions && !this.attribute.attributeSelectOptions)
        return [];

      if (this.selectOptions) return this.selectOptions;

      return this.attribute.attributeSelectOptions;
    },
    inputLabel() {
      if (this.customLabel) return this.customLabel;

      const attributeName = this.attribute?.name;

      if (!attributeName) return "";

      const translationKey = `common.attributeNames.${attributeName}`;

      const isNameTranslated = this.$te(translationKey);

      return isNameTranslated ? this.$t(translationKey) : attributeName;
    },
    itemText() {
      return this.selectItemText ?? "value";
    },
    itemValue() {
      return this.selectItemValue ?? "id";
    },
    renderedPrice() {
      // when currentvalue changes
      return this.currentValue !== null
        ? this.currentValue.toString().replace(".", ",")
        : "";
    },
    renderedNumber() {
      return this.currentValue !== null
        ? this.currentValue.toString().replace(".", ",")
        : "";
    },
    datePlaceholder() {
      if (this.dateFormat === "YYYY-MM-DD")
        return this.$t("pages.assets.form.dateFormat");
      else if (this.dateFormat === "DD-MM-YYYY")
        return this.$t("pages.assets.form.dateFormatAlt");
      else return this.$t("pages.assets.form.dateFormat");
    },
  },
  methods: {
    handlePriceInput(value) {
      // When rendered input changes.
      // Problem:
      // currentValue doesnt change when invalid characters are stripped.
      // This results in renderedPrice not being updated and thus not removing
      // the invalid characters from the input even though they are not present
      // in currentValue.

      // Solution attempt.
      // Tried to use setCurrentValue() which did fix the problem visually, but
      // for some reason that doesn't fire the change event. So the value is
      // never passed to parent.

      // Solution attempt.
      // update the renderedValue on input. On change of rendered value update currentValue...
      // Use currentValue as rendered value and emit system formatted currentValue

      // remove all characters except numbers 0-9 and ,
      let v = value.replace(/[^0-9,]/g, "");

      // remove all , but one
      v = v.replace(new RegExp(`[,]`, "g"), (match, index) => {
        return index === v.indexOf(",") ? match : "";
      });

      this.currentValue = v.replace(",", ".");
    },
    handleCurrencyCodeChanged(value) {
      if (value === this.defaultCurrencyCode) {
        this.$emit("currencyCode_changed", null);
        return;
      }
      this.$emit("currencyCode_changed", value);
    },
    handleNumberInputEvent(value) {
      const systemValue = value.replace(",", ".");
      if (value == this.currentValue) return;

      if (isNaN(systemValue) || systemValue === "" || systemValue === null) {
        this.setCurrentValue(null);
        this.$emit("decimal_changed", null);
        return;
      }

      const parsedValue = this.integerInput
        ? parseInt(systemValue)
        : parseFloat(systemValue);

      if (this.numberMinValue !== undefined) {
        const minValue = this.integerInput
          ? parseInt(this.numberMinValue)
          : parseFloat(this.numberMinValue);

        const isLowerThanMin = parsedValue < minValue;

        if (isLowerThanMin) {
          this.setCurrentValue(minValue);
          this.$emit("decimal_changed", minValue);
          return;
        }
      }

      if (this.numberMaxValue !== undefined) {
        const maxValue = this.integerInput
          ? parseInt(this.numberMaxValue)
          : parseFloat(this.numberMaxValue);

        const isHigherThanMax = parsedValue > maxValue;

        if (isHigherThanMax) {
          this.setCurrentValue(maxValue);
          this.$emit("decimal_changed", maxValue);
          return;
        }
      }

      this.setCurrentValue(value);
      this.$emit("decimal_changed", parsedValue);
    },
    setCurrentValue(value) {
      this.currentValue = value;

      this.rerenderCounter++;

      this.$nextTick(() => {
        this.$refs.numberInput.focus();
      });
    },
  },
};
</script>
