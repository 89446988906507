var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('v-btn',{class:_vm.$slots['default']
        ? 'd-block text-none text-left'
        : 'date-range-button pa-1 d-flex flex-row align-center justify-center border-right white--text text--lighten-1',style:(_vm.buttonStyle),attrs:{"height":"auto","plain":"","light":"","elevation":"0","text":""},on:{"click":function($event){_vm.showDateRangePicker = !_vm.showDateRangePicker}}},[(_vm.$slots['default'])?_vm._t("default"):[_c('CalendarIcon',{staticClass:"mr-4 d-none d-md-block"}),_c('div',{staticClass:"d-flex flex-sm-row align-center"},[_c('span',{staticClass:"caption text-capitalize text-center text-sm-left",class:_vm.small ? 'text-lg-body-2' : 'text-lg-body-1'},[_vm._v(_vm._s(_vm.formatDates[0]))]),_c('ArrowRightIcon',{staticClass:"mx-4"}),_c('span',{staticClass:"caption text-capitalize text-center text-sm-left",class:_vm.small ? 'text-lg-body-2' : 'text-lg-body-1'},[_vm._v(_vm._s(_vm.formatDates[1]))])],1)]],2),_c('v-dialog',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.onClickOutside,
    }),expression:"{\n      handler: onClickOutside,\n    }"}],ref:"dialog",staticClass:"dialog",attrs:{"persistent":"","max-width":"836px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showDateRangePicker = false}},model:{value:(_vm.showDateRangePicker),callback:function ($$v) {_vm.showDateRangePicker=$$v},expression:"showDateRangePicker"}},[_c('DateRangePicker',{ref:"picker",staticClass:"date-range-picker",attrs:{"opens":"inline","locale-data":{ firstDay: 1, format: 'yyyy-mm-dd' },"autoApply":"","ranges":_vm.ranges},on:{"update":function($event){/*() => dateRangeChanged([])*/}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"date-range-header"},[_vm._v(" "+_vm._s(_vm.$t("components.inputs.dateRangePicker.title"))+" ")]),_c('div',{staticClass:"date-range-inputs d-flex pt-4"},[_c('div',{staticClass:"flex-grow-1",staticStyle:{"max-width":"270px","width":"270px"}},[_c('DateTextInput',{staticClass:"mx-5",attrs:{"title":_vm.$t('components.inputs.dateRangePicker.fromDate'),"year":_vm.dates[0].split('-')[0],"month":_vm.dates[0].split('-')[1],"day":_vm.dates[0].split('-')[2],"tabKey":0,"defaultDateFormat":_vm.defaultDateFormat},on:{"change":(val) => {
                  _vm.dateInputUpdated('startDate', val);
                }}})],1),_c('div',{staticClass:"flex-grow-1",staticStyle:{"max-width":"270px","width":"270px"}},[_c('DateTextInput',{staticClass:"mx-5",attrs:{"title":_vm.$t('components.inputs.dateRangePicker.toDate'),"year":_vm.dates[1].split('-')[0],"month":_vm.dates[1].split('-')[1],"day":_vm.dates[1].split('-')[2],"tabKey":1,"defaultDateFormat":_vm.defaultDateFormat},on:{"change":(val) => {
                  _vm.dateInputUpdated('endDate', val);
                }}})],1)])]},proxy:true},{key:"date",fn:function(date){return [_c('div',{staticClass:"date-range-pin"},[_c('span',[_vm._v(_vm._s(new Date(date.date).getDate()))])])]}},{key:"ranges",fn:function(ranges){return [_c('div',{staticClass:"ranges d-flex flex-column"},[_c('ul',{staticClass:"d-flex flex-column flex-grow-1"},[_vm._l((ranges.ranges),function(range,name){return _c('li',{key:name,on:{"click":function($event){return ranges.clickRange(range)}}},[_vm._v(" "+_vm._s(name)+" ")])}),_c('li',{staticClass:"no-hover-effect",class:_vm.fiscalYearOverlapsCalendarYear
                  ? 'mt-3 mb-4 d-flex flex-column flex-grow-1 justify-end'
                  : 'mt-3 mb-3 d-flex flex-column flex-grow-1 justify-end'},[_c('p',{staticClass:"custom-range-heading mb-2"},[_vm._v(" "+_vm._s(_vm.$t("components.inputs.dateRangePicker.ranges.calendarYear"))+" "+_vm._s(_vm.fiscalYearOverlapsCalendarYear ? "/ " + _vm.$t( "components.inputs.dateRangePicker.ranges.fiscalYear" ) : null)+" ")]),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.previousCalendarYear()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-left")])],1),_c('v-btn',{staticClass:"flex-grow-1 text-center",attrs:{"text":""},on:{"click":function($event){return _vm.selectEntireYear()}}},[_vm._v(_vm._s(_vm.currentSelectedYear))]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.nextCalendarYear()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1)]),(!_vm.fiscalYearOverlapsCalendarYear)?_c('li',{staticClass:"no-hover-effect mb-8"},[_c('p',{staticClass:"custom-range-heading mb-2"},[_vm._v(" "+_vm._s(_vm.$t("components.inputs.dateRangePicker.ranges.fiscalYear"))+" ")]),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.previousFiscalYear()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-left")])],1),_c('v-btn',{staticClass:"flex-grow-1 text-center",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.selectEntireFiscalYear()}}},[_vm._v(" "+_vm._s(_vm.currentFiscalYear)+" ")]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.nextFiscalYear()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1)]):_vm._e()],2)])]}}]),model:{value:(_vm.rawDates),callback:function ($$v) {_vm.rawDates=$$v},expression:"rawDates"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }