<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 py-4">
            <p class="page-title">
              {{ $t("pages.reports.climateReport.totalGhgAndScopes.title") }}
            </p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="100%" class="text-left">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.ghgEmissions"
                        )
                      }}
                    </th>
                    <th class="text-right">
                      {{
                        $t("pages.reports.climateReport.totalGhgAndScopes.unit")
                      }}
                    </th>
                    <th class="text-right">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.previousPeriod"
                        )
                      }}
                    </th>
                    <th class="text-right">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.currentPeriod"
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="table-section-head">
                    <td>
                      <p>
                        {{
                          $t(
                            "pages.reports.climateReport.totalGhgAndScopes.scope1TableHeading"
                          )
                        }}
                      </p>
                    </td>
                    <td class="text-right">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.tonCO2e"
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        renderEmissions(
                          sumEmissionsForScope(
                            "cb483bd8-d20e-42a1-a252-c44ee12230ef"
                          )["prev"]
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        renderEmissions(
                          sumEmissionsForScope(
                            "cb483bd8-d20e-42a1-a252-c44ee12230ef"
                          )["current"]
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="ghgCategory in getGhgCategorySummariesForScope(
                      'cb483bd8-d20e-42a1-a252-c44ee12230ef'
                    )"
                    :key="ghgCategory['current'].id"
                  >
                    <td>{{ ghgCategory["current"].name }}</td>
                    <td class="text-right">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.tonCO2e"
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{ getEmissionsLabel(ghgCategory, "prev") }}
                    </td>
                    <td class="text-right">
                      {{ getEmissionsLabel(ghgCategory, "current") }}
                    </td>
                  </tr>

                  <!-- Scope 2 GHG emissions -->
                  <tr class="table-section-head">
                    <td>
                      <p>
                        {{
                          $t(
                            "pages.reports.climateReport.totalGhgAndScopes.scope2TableHeading"
                          )
                        }}
                      </p>
                    </td>
                    <td class="text-right">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.tonCO2e"
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        renderEmissions(
                          sumEmissionsForScope(
                            "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b"
                          )["prev"]
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        renderEmissions(
                          sumEmissionsForScope(
                            "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b"
                          )["current"]
                        )
                      }}
                    </td>
                  </tr>

                  <tr
                    v-for="ghgCategory in getGhgCategorySummariesForScope(
                      '6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b'
                    )"
                    :key="ghgCategory['current'].id"
                  >
                    <td>{{ ghgCategory["current"].name }}</td>
                    <td class="text-right">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.tonCO2e"
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{ getEmissionsLabel(ghgCategory, "prev") }}
                    </td>
                    <td class="text-right">
                      {{ getEmissionsLabel(ghgCategory, "current") }}
                    </td>
                  </tr>

                  <!-- Scope 3 GHG emissions -->
                  <tr class="table-section-head">
                    <td>
                      <p>
                        {{
                          $t(
                            "pages.reports.climateReport.totalGhgAndScopes.scope3TableHeading"
                          )
                        }}
                      </p>
                    </td>
                    <td class="text-right">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.tonCO2e"
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        renderEmissions(
                          sumEmissionsForScope(
                            "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde"
                          )["prev"]
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        renderEmissions(
                          sumEmissionsForScope(
                            "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde"
                          )["current"]
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="ghgCategory in getGhgCategorySummariesForScope(
                      '6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde'
                    )"
                    :key="ghgCategory['current'].id"
                  >
                    <td>{{ ghgCategory["current"].name }}</td>
                    <td class="text-right">
                      {{
                        $t(
                          "pages.reports.climateReport.totalGhgAndScopes.tonCO2e"
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{ getEmissionsLabel(ghgCategory, "prev") }}
                    </td>
                    <td class="text-right">
                      {{ getEmissionsLabel(ghgCategory, "current") }}
                    </td>
                  </tr>

                  <!--<tr v-for="item in desserts" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.calories }}</td>
                  </tr>-->
                </tbody>
              </template>
            </v-simple-table>
            <div class="d-flex justify-end mt-2 grey--text">
              <div class="mr-4">
                {{
                  $t(
                    "pages.reports.climateReport.totalGhgAndScopes.nrDescription"
                  )
                }}
              </div>
              <div>
                {{
                  $t(
                    "pages.reports.climateReport.totalGhgAndScopes.ncDescription"
                  )
                }}
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Formatters from "../../mixins/Formatters.vue";

export default {
  mixins: [Formatters],
  props: {
    dateRange: { type: Object, default: () => ({ from: "", to: "" }) },
    ghgCategorySummariesCurrentPeriod: Array,
    ghgCategorySummariesPreviousPeriod: Array,
  },
  methods: {
    getGhgCategorySummariesForScope(scopeId) {
      const filtered = this.ghgCategorySummariesCurrentPeriod?.filter(
        (category) => category.scopeId === scopeId
      );

      let merged = filtered?.map((cat) => ({
        current: cat,
        prev: this.ghgCategorySummariesPreviousPeriod.find(
          (c) => c.id === cat.id
        ),
      }));

      if (merged) {
        merged = merged.sort(
          (a, b) => a.current.sortOrder - b.current.sortOrder
        );
      }

      return merged;
    },
    sumEmissionsForScope(scopeId) {
      return {
        prev: this.ghgCategorySummariesPreviousPeriod
          ?.filter((category) => category.scopeId === scopeId)
          .reduce((acc, a) => acc + (a?.included === 1 ? a?.emissions : 0), 0),
        current: this.ghgCategorySummariesCurrentPeriod
          ?.filter((category) => category?.scopeId === scopeId)
          .reduce((acc, a) => acc + (a?.included === 1 ? a?.emissions : 0), 0),
      };

      // added included check to not count not included emissions (a.included === 1)
    },
    getEmissionsLabel(ghgCategory, period) {
      const optionKey = ghgCategory[period]?.included ?? 1;

      if (optionKey === 1) {
        const { value } = this.formatKgToTonEmissions(
          ghgCategory[period]?.emissions
        );
        return value;
      }

      if (optionKey === 2) {
        return "nr";
      }

      return "nc";
    },
    renderEmissions(val) {
      const { value } = this.formatKgToTonEmissions(val);
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 35px;
}

.v-data-table > .v-data-table__wrapper {
  table {
    > thead {
      > tr:last-child > th {
        border-bottom: none;
      }
      > tr > th {
        font-size: 14px;
        font-weight: normal;
        border-bottom: none;
        height: 31px;
        &:first-child {
          font-size: 20px;
        }
      }
    }

    > tbody {
      > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
        border-bottom: none;
      }

      > tr {
        background-color: #fbfbfb;
        > td {
          height: 31px;
          font-size: 14px;
          font-weight: normal;
          white-space: nowrap;

          &:first-child {
            font-size: 14px;
          }
        }
      }

      > .table-section-head {
        background-color: transparent;
        > td {
          border-bottom: 1px solid #00000014 !important;
          white-space: nowrap;
          > p {
            font-size: 18px;
            margin-bottom: 6px;
            margin-top: 11px;
          }
        }
      }
    }
  }
}
</style>
