<template>
  <dialog-form :show-dialog="show" max-width="500px" :persistent="true">
    <div class="d-flex flex-column px-0 pt-16">
      <div class="text-center mb-2 pt-10">
        <div v-if="iconType === 'check'" class="check">
          <check-circle class="check-icon"></check-circle>
        </div>
        <v-list-item-avatar
          v-else-if="!!iconType"
          color="#FDECE7"
          class="d-flex align-center justify-center items-center mx-auto"
          width="62px"
          height="62px"
        >
          <warning v-if="iconType === 'warning'" class="small-icon" />
          <warning-error v-else-if="iconType === 'error'" class="small-icon" />
        </v-list-item-avatar>
        <h1
          class="text-h6 black--text mb-2 text-center pt-2 px-4"
          :class="!subMessage ? 'pb-16' : ''"
        >
          {{ confirmMessage }}
        </h1>
        <h1
          v-if="subMessage"
          class="text-h6 black--text mb-2 text-center pt-2 pb-16 px-4"
        >
          {{ subMessage }}
        </h1>
      </div>
      <v-card-actions class="d-flex footer-section justify-end pa-4">
        <slot name="buttons"></slot>
        <v-btn
          tile
          x-large
          elevation="0"
          color="orange"
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
          type="submit"
          @click="confirm()"
          >{{ $t("common.actions.ok") }}</v-btn
        >
      </v-card-actions>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from "./Dialog/DialogForm.vue";
import CheckCircle from "../assets/svg/check-circle.svg";
import Warning from "../assets/svg/warning.svg";
import WarningError from "../assets/svg/warning-error.svg";
export default {
  components: { DialogForm, CheckCircle, Warning, WarningError },
  props: {
    value: Boolean,
    confirmMessage: String,
    subMessage: String,
    iconType: {
      type: String,
      default: "check",
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirm() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(229, 237, 236);
  position: relative;
  z-index: 9998;
  margin: 0 auto;
}
.check-icon {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 9999;
}

.small-icon {
  width: 32px;
  height: 32px;
  border-radius: 0;
}
</style>
