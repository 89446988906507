<template>
  <dialog-form :show-dialog="show" @showStateChanged="(val) => (show = val)">
    <dialog-header
      :title="
        asset != null && asset.id != null
          ? '#' + asset.referenceKey + ' ' + assetName
          : $t('pages.assets.form.title')
      "
      :tabs="attributeClassTabs.map((tab) => tab.name)"
      :selected-tab="tab"
      @onChangedTab="(t) => (tab = t)"
    >
    </dialog-header>
    <div
      ref="scrollable-area"
      style="height: calc(81vh - 175px); overflow-y: scroll"
      :style="
        attributeClassTabs[tab]?.id === 'asset-logs'
          ? 'overflow-y:hidden;'
          : null
      "
    >
      <v-tabs-items v-model="tab" style="">
        <template v-for="(attributeClass, index) in attributeClassTabs">
          <tab-static-co-2
            v-if="attributeClass.id === fixedClasses.co2Id"
            :key="attributeClass.id"
            :asset="asset"
            :tabValue="index"
            :assetAttributeLinkData="assetAttributeLinkData"
            :attributes="attributes"
            :ghgCategories="ghgCategories"
            :emissionFactor="emissionFactor"
            :calculated-emission-factor-id="calculationEmissionFactorId"
            :defaultCurrencyCode="defaultCurrencyCode"
            :resetEmissionFactor="resetEmissionFactor"
            :ghgCategoryId="form.ghgCategoryId"
            :settings="settings"
            @bool_changed="updateAssetAttributeLinkBoolValue"
            @select_changed="updateAssetAttributeLinkSelectOption"
            @string_changed="updateAssetAttributeLinkStringValue"
            @decimal_changed="updateAssetAttributeLinkDecimalValue"
            @file_changed="updateAssetAttributeFileLink"
            @file_removed="removeAssetAttributeFileLink"
            @asset_attribute_data_changed="updateAssetAttributeLinkData"
            @asset_attribute_data_second_value_changed="
              updateAssetAttributeLinkDataSecondValue
            "
            @emission_factor_changed="setEmissionFactor"
            @ghg_category_changed="form.ghgCategoryId = $event"
            @reload="$emit('reload')"
            @emissionFactorReset="() => (resetEmissionFactor = false)"
            @onRunAi="(onEnd) => runAiOnAsset(onEnd)"
            :autoApplyAssetAttributeLinks="autoApplyAssetAttributeLinks"
          />
          <tab-static-fixed-assets
            v-else-if="attributeClass.id === fixedClasses.fixedAssetsId"
            :key="attributeClass.id"
            :tabValue="index"
            :assetAttributeLinkData="assetAttributeLinkData"
            :attributes="attributes"
            :currencies="currencies"
            :defaultCurrencyCode="defaultCurrencyCode"
            @select_changed="updateAssetAttributeLinkSelectOption"
            @string_changed="updateAssetAttributeLinkStringValue"
            @decimal_changed="updateAssetAttributeLinkDecimalValue"
            @currencyCode_changed="updateAssetAttributeLinkCurrency"
            @currency_changed="updateAssetAttributeLinkCurrency"
            @dateTime_changed="updateAssetAttributeLinkDateTimeValue"
            @asset_attribute_data_changed="updateAssetAttributeLinkData"
            @asset_attribute_data_second_value_changed="
              updateAssetAttributeLinkDataSecondValue
            "
          />
          <tab-static-assessment
            v-else-if="attributeClass.id === fixedClasses.assessmentId"
            :key="attributeClass.id"
            :tabValue="index"
            :assetAttributeLinkData="assetAttributeLinkData"
            :attributes="attributes"
            :currencies="currencies"
            @dateTime_changed="updateAssetAttributeLinkDateTimeValue"
            @select_changed="updateAssetAttributeLinkSelectOption"
            @string_changed="updateAssetAttributeLinkStringValue"
            @currencyCode_changed="updateAssetAttributeLinkCurrency"
            @currency_changed="updateAssetAttributeLinkCurrency"
            @asset_attribute_data_changed="updateAssetAttributeLinkData"
            @asset_attribute_data_second_value_changed="
              updateAssetAttributeLinkDataSecondValue
            "
          />
          <tab-static-general
            v-else-if="attributeClass.id === fixedClasses.generalId"
            :show="show"
            :key="attributeClass.id"
            :tabValue="index"
            :assetAttributeLinkData="assetAttributeLinkData"
            :attributes="attributes"
            :currencies="currencies"
            :asset="asset"
            :settings="settings"
            @select_changed="updateAssetAttributeLinkSelectOption"
            @string_changed="updateAssetAttributeLinkStringValue"
            @currencyCode_changed="updateAssetAttributeLinkCurrency"
            @currency_changed="updateAssetAttributeLinkCurrency"
            @dateTime_changed="updateAssetAttributeLinkDateTimeValue"
            @decimal_changed="updateAssetAttributeLinkDecimalValue"
            @asset_attribute_data_changed="updateAssetAttributeLinkData"
            @asset_attribute_data_second_value_changed="
              updateAssetAttributeLinkDataSecondValue
            "
          />
          <tab-static-info
            v-else-if="attributeClass.id === fixedClasses.infoId"
            :key="attributeClass.id"
            :tabValue="index"
            :attributeLinkData="assetAttributeLinkData"
            :assetAttributeLinks="form.assetAttributeLinks"
            :attributes="attributes"
            @decimal_changed="updateAssetAttributeLinkDecimalValue"
            @string_changed="updateAssetAttributeLinkStringValue"
            @file_changed="updateAssetAttributeFileLink"
            @file_removed="removeAssetAttributeFileLink"
            @asset_attribute_data_changed="updateAssetAttributeLinkData"
            @asset_attribute_data_second_value_changed="
              updateAssetAttributeLinkDataSecondValue
            "
            :integrationId="asset?.integrationId"
          />
          <tab-notes
            v-else-if="attributeClass.id === 'asset-notes'"
            :key="attributeClass.id"
            :tabValue="index"
            :asset-notes="asset?.assetNotes ?? []"
            :delete-asset-note-ids.sync="form.deleteAssetNoteIds"
            :note-text.sync="form.assetNote.text"
            :note-files.sync="form.assetNote.files"
          />
          <tab-logs
            v-else-if="attributeClass.id === 'asset-logs'"
            :key="attributeClass.id"
            :tabValue="index"
            :logs="logData"
            @selectLogEntry="(id) => selectLogEntry(id)"
            :selectedLog="selectedLog"
          ></tab-logs>
          <tab-dynamic
            v-else
            :key="attributeClass.id"
            :tabValue="index"
            :attributeClass="attributeClass"
            :assetAttributeLinkData="assetAttributeLinkData"
            :attributes="attributes"
            :currencies="currencies"
            @bool_changed="updateAssetAttributeLinkBoolValue"
            @currencyCode_changed="updateAssetAttributeLinkCurrency"
            @currency_changed="updateAssetAttributeLinkCurrency"
            @dateTime_changed="updateAssetAttributeLinkDateTimeValue"
            @decimal_changed="updateAssetAttributeLinkDecimalValue"
            @select_changed="updateAssetAttributeLinkSelectOption"
            @string_changed="updateAssetAttributeLinkStringValue"
            @file_changed="updateAssetAttributeFileLink"
            @file_removed="removeAssetAttributeFileLink"
            @asset_attribute_data_changed="updateAssetAttributeLinkData"
            @asset_attribute_data_second_value_changed="
              updateAssetAttributeLinkDataSecondValue
            "
          />
        </template>
      </v-tabs-items>
    </div>
    <v-alert dense v-if="form.errors.alert" type="error" class="mb-0">
      {{ form.errors.alert }}
    </v-alert>
    <v-card-actions
      class="py-6 px-6 d-flex flex-column-reverse flex-md-row footer-section"
    >
      <v-btn
        v-if="form.id"
        @click="deleteAsset()"
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        elevation="0"
        text
        tile
        x-large
      >
        <v-icon class="mr-3" color="#686868">mdi-delete-outline</v-icon>
        {{ $t("common.actions.delete") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="show = false"
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        elevation="0"
        text
        tile
        x-large
      >
        <v-icon class="mr-3" color="#686868">mdi-close</v-icon>
        {{ $t("common.actions.cancel") }}
      </v-btn>
      <v-btn
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
        color="#F25D3B"
        elevation="0"
        tile
        x-large
        :disabled="loading || !formIsFilledOut"
        @click="saveAsset(false, clearFormOnSave)"
      >
        {{ $t("common.actions.save") }}
      </v-btn>
      <v-btn
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch white--text"
        color="#266663"
        elevation="0"
        tile
        x-large
        @click="saveAsset"
        :disabled="loading || !formIsFilledOut"
      >
        <v-icon class="mr-3" color="#fff"
          >mdi-content-save-check-outline</v-icon
        >
        {{ $t("common.actions.saveAndClose") }}
      </v-btn>
    </v-card-actions>
    <confirm-dialog
      v-model="showConfirmDialog"
      v-show="showConfirmDialog"
      :confirm-message="confirmMessage"
      @confirmed="handleConfirmation"
    ></confirm-dialog>
  </dialog-form>
</template>

<script>
import "vue2-datepicker/index.css";
import { serialize } from "object-to-formdata";
import TabDynamic from "./Form/TabDynamic.vue";
import TabStaticCo2 from "./Form/TabStaticCo2.vue";
import TabStaticFixedAssets from "./Form/TabStaticFixedAssets.vue";
import TabStaticAssessment from "./Form/TabStaticAssessment.vue";
import TabStaticGeneral from "./Form/TabStaticGeneral.vue";
import TabStaticInfo from "./Form/TabStaticInfo.vue";
import { removeTimezoneOffset, addTimezoneOffset } from "@/util/dateTime";
import { fixedClasses, fixedAttributes } from "../../util/fixedAssetData";
import { generatePredefinedAttributeLinks } from "../../util/attributeForm";
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogHeader from "../../Components/Dialog/DialogFormHeader.vue";
import ConfirmDialog from "../../Components/ConfirmDialog.vue";
import TabLogs from "./Form/TabLogs.vue";
import TabNotes from "./Form/TabNotes.vue";

export default {
  components: {
    TabDynamic,
    TabStaticCo2,
    TabStaticFixedAssets,
    TabStaticAssessment,
    TabStaticGeneral,
    DialogForm,
    DialogHeader,
    TabStaticInfo,
    ConfirmDialog,
    TabLogs,
    TabNotes,
  },
  props: {
    value: Boolean,
    dialog: Boolean,
    asset: Object,
    attributes: Array,
    attributeClasses: Array,
    currencies: Array,
    ghgCategories: Array,
    organizationId: String,
    isTemplatePage: { type: Boolean, default: false },
    settings: Object,
    defaultCurrencyCode: String,
    disableCreateButton: Boolean,
    autoApplyAssetAttributeLinks: Object,

    // Sry bout this. 
    // This is needed to fix input being cleared issue 
    // when saving (without closing) from entry view
    clearFormOnSave: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formIsFilledOut() {
      return (
        !!this.form.ghgCategoryId &&
        this.checkArrayForAttributeId(
          this.form.assetAttributeLinks,
          fixedAttributes.co2EmissionSourceId
        ) &&
        this.assetAttributeLinkData["649f345f-bb47-47b5-af15-6ef294e72afd"]
      );
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    assetName() {
      if (this.asset[fixedAttributes.generalNameId] === undefined) {
        return "";
      }

      return this.asset[fixedAttributes.generalNameId].stringValue;
    },
    attributeClassTabs() {
      const attributeClasses = this.attributeClasses.filter((x) => !x.hidden);

      const tabs = [...attributeClasses];

      if (this.attributes.some((x) => x.attributeClassId == null)) {
        tabs.push({ name: this.$t("pages.assets.form.noClass"), id: null });
      }

      return [
        ...tabs,
        { name: this.$t("pages.assets.form.notes.title"), id: "asset-notes" },
        { name: this.$t("pages.assets.form.log"), id: "asset-logs" },
      ];
    },
    availableAttributes() {
      var search = this.availableAttributesSearch.toLowerCase();
      return this.attributes.filter((x) => {
        return (
          x.name.toLowerCase().includes(search) &&
          !this.form.attributes.some((y) => x.id == y.id)
        );
      });
    },
    selectedAttributes() {
      var search = this.selectedAttributesSearch.toLowerCase();
      return this.form.attributes.filter((x) => {
        return x.name.toLowerCase().includes(search);
      });
    },
    boolValueOptions() {
      return [
        { value: true, text: this.$t("common.boolean.true") },
        { value: false, text: this.$t("common.boolean.false") },
      ];
    },
  },
  data() {
    const [assetAttributeLinkData, assetAttributeLinks] =
      generatePredefinedAttributeLinks(this.attributes);

    return {
      loading: false,
      tab: null,
      assetAttributeLinkData,
      emissionFactor: null,
      calculationEmissionFactorId: null,
      resetEmissionFactor: false,
      form: this.$inertia.form({
        id: null,
        emissionFactorId: null,
        ghgCategoryId: null,
        assetAttributeLinks,
        assetNote: {
          text: null,
          files: null,
        },
        deleteAssetNoteIds: [],
      }),
      fileSizeRules: [
        (value) =>
          !value ||
          value.size < 30000000 ||
          this.$t("pages.assets.form.fileSizeRule"),
      ],
      fixedClasses,
      fixedAttributes,
      showConfirmDialog: false,
      confirmMessage: this.$t("pages.assets.index.confirmDelete"),
      logData: [],
      selectedLog: null,
    };
  },
  watch: {
    asset: function (asset) {
      this.setForm(asset);
      if (asset) {
        this.getAssetLogs();
      }
    },
    show(value) {
      if (!value) {
        this.setForm(null);
        this.$emit("clear");
        this.tab = null;
        this.selectedLog = null;
      }
    },
    tab() {
      // Scroll to top
      if (this.$refs["scrollable-area"] !== undefined) {
        this.$refs["scrollable-area"].scrollTop = 0;
      }
    },
    autoApplyAssetAttributeLinks(to) {
      if (to) {
        this.tab = 1;
      }
    },
  },
  methods: {
    checkArrayForAttributeId(array, targetAttributeId) {
      function containsAttributeId(obj, attributeId) {
        return (
          Object.prototype.hasOwnProperty.call(obj, "attributeId") &&
          obj["attributeId"] === attributeId
        );
      }
      for (const obj of array) {
        if (containsAttributeId(obj, targetAttributeId)) {
          return true;
        }
      }
      return false;
    },
    setForm(asset) {
      if (asset != null) {
        this.form.id = asset.id;
        this.form.emissionFactorId = asset.emissionFactorId;
        this.form.ghgCategoryId = asset.ghgCategoryId;
        this.form.assetNote.text = null;
        this.form.assetNote.files = null;
        this.form.deleteAssetNoteIds = [];
        this.emissionFactor = asset.emissionFactor;
        this.calculationEmissionFactorId = asset.emissionFactor?.id;
        this.setAssetAttributeLinkData(asset.assetAttributeLinks ?? []);
        this.form.assetAttributeLinks =
          asset.assetAttributeLinks?.map((x) => {
            let stringValue = x.stringValue;

            if (
              !stringValue &&
              x.attribute?.format === "Currency" &&
              !!this.defaultCurrencyCode
            ) {
              stringValue = this.defaultCurrencyCode;
            }

            return {
              attributeId: x.attributeId,
              stringValue: stringValue,
              dateTimeValue: x.dateTimeValue,
              decimalValue: x.decimalValue,
              boolValue: x.boolValue,
              fileId: x.fileId,
              fileIds: x.assetAttributeFileLinks
                ? x.assetAttributeFileLinks.map((y) => y.fileId)
                : [],
              attributeSelectOptionId: x.attributeSelectOptionId,
            };
          }) ?? [];
      } else {
        this.form.id = null;
        this.form.emissionFactorId = null;
        this.form.ghgCategoryId = null;
        this.form.assetAttributeLinks = [];
        this.form.assetNote.text = null;
        this.form.assetNote.files = null;
        this.form.deleteAssetNoteIds = [];
        generatePredefinedAttributeLinks(
          this.attributes,
          this.assetAttributeLinkData,
          this.form.assetAttributeLinks
        );
        this.emissionFactor = null;
        this.calculationEmissionFactorId = null;
      }
    },
    setAssetAttributeLinkData(assetAttributeLinks) {
      this.attributes?.forEach((attribute) => {
        var assetAttributeLink = assetAttributeLinks.find(
          (x) => x.attributeId == attribute.id
        );
        if (assetAttributeLink) {
          switch (attribute.format) {
            case "Text":
              this.assetAttributeLinkData[attribute.id] =
                assetAttributeLink.stringValue;
              break;
            case "TextArea":
              this.assetAttributeLinkData[attribute.id] =
                assetAttributeLink.stringValue;
              break;
            case "Select":
              this.assetAttributeLinkData[attribute.id] =
                assetAttributeLink.attributeSelectOptionId;
              break;
            case "Number":
            case "Percentage":
              this.assetAttributeLinkData[attribute.id] =
                assetAttributeLink.decimalValue;
              break;
            case "DateTime":
            case "Date":
              this.assetAttributeLinkData[attribute.id] = addTimezoneOffset(
                new Date(assetAttributeLink.dateTimeValue + "Z")
              );
              break;
            case "Currency":
              this.assetAttributeLinkData[attribute.id + "-secondValue"] =
                assetAttributeLink.stringValue ??
                this.defaultCurrencyCode ??
                null;
              this.assetAttributeLinkData[attribute.id] =
                assetAttributeLink.decimalValue;
              break;
            case "Boolean":
              this.assetAttributeLinkData[attribute.id] =
                assetAttributeLink.boolValue;
              break;
            case "File": {
              let files = [];
              if (assetAttributeLink.file) {
                files.push(assetAttributeLink.file);
              }

              files = files.concat(
                assetAttributeLink.assetAttributeFileLinks.map((x) => x.file)
              );

              this.assetAttributeLinkData[attribute.id + "-secondValue"] =
                files;
              break;
            }
            default:
              break;
          }
        } else {
          this.assetAttributeLinkData[attribute.id] = null;
          if (attribute.format == "File" || attribute.format == "Currency") {
            this.assetAttributeLinkData[attribute.id + "-secondValue"] = null;
          }
        }
      });
    },
    setEmissionFactor(emissionFactor) {
      this.emissionFactor = emissionFactor;
      this.form.emissionFactorId = emissionFactor?.id ?? null;
    },
    updateAssetAttributeFileLink(value, attribute) {
      if (value == null || value.length === 0) {
        const assetAttributeLink = this.form.assetAttributeLinks.find(
          (x) => x.attributeId === attribute.id
        );

        if (
          assetAttributeLink.fileId == null &&
          !assetAttributeLink.fileIds.length
        ) {
          this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
            (x) => x.attributeId != attribute.id
          );
        }

        return;
      }

      const newFiles = value.length === undefined ? [value] : value;

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );

      if (assetAttributeLink != null) {
        assetAttributeLink.newFiles = newFiles;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          newFiles: newFiles,
        });
      }
    },
    removeAssetAttributeFileLink(fileId, attribute) {
      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );

      if (assetAttributeLink != null) {
        assetAttributeLink.fileIds = assetAttributeLink.fileIds.filter(
          (x) => x !== fileId
        );
        this.assetAttributeLinkData[attribute.id + "-secondValue"] =
          this.assetAttributeLinkData[attribute.id + "-secondValue"].filter(
            (x) => x.id !== fileId
          );
      }
    },
    updateAssetAttributeLinkDateTimeValue(value, attribute) {
      if (value == null) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      const assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );

      if (assetAttributeLink != null) {
        assetAttributeLink.dateTimeValue = removeTimezoneOffset(value);
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          dateTimeValue: removeTimezoneOffset(value),
        });
      }

      if (
        attribute.id === fixedAttributes.generalPurchaseDateId &&
        value !== null
      ) {
        const timeOfCommissioningAssetAttributeLink = this.attributes.find(
          (x) => x.id == fixedAttributes.fixedAssetsTimeOfCommissioningId
        );

        if (timeOfCommissioningAssetAttributeLink)
          this.updateAssetAttributeLinkDateTimeValue(
            null,
            timeOfCommissioningAssetAttributeLink
          );
      }
    },
    updateAssetAttributeLinkDecimalValue(value, attribute) {
      if (value == null || isNaN(value)) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.decimalValue = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          decimalValue: value,
        });
      }
    },
    updateAssetAttributeLinkBoolValue(value, attribute) {
      if (value == null) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.boolValue = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          boolValue: value,
        });
      }
    },
    updateAssetAttributeLinkSelectOption(value, attribute) {
      if (value == null) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.attributeSelectOptionId = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          attributeSelectOptionId: value,
        });
      }
    },
    updateAssetAttributeLinkStringValue(value, attribute) {
      if (value == null || !value) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.stringValue = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          stringValue: value,
        });
      }
    },
    updateAssetAttributeLinkCurrency(value, currencyCode, attribute) {
      if (value == null && currencyCode == null) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      if (!currencyCode && !!this.defaultCurrencyCode)
        currencyCode = this.defaultCurrencyCode;

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.stringValue = currencyCode;
        assetAttributeLink.decimalValue = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          stringValue: currencyCode,
          decimalValue: value,
        });
      }
    },
    updateAssetAttributeLinkData(attributeId, value) {
      this.formIsFilledOut;
      this.assetAttributeLinkData[attributeId] = value;

      if (attributeId === fixedAttributes.generalPurchaseDateId && value) {
        this.assetAttributeLinkData[
          fixedAttributes.fixedAssetsTimeOfCommissioningId
        ] = null;
      }
    },
    updateAssetAttributeLinkDataSecondValue(attributeId, value) {
      this.assetAttributeLinkData[attributeId + "-secondValue"] = value;
    },
    saveAsset(close = true, clearForm = true) {
      if (this.loading) return;
      this.loading = true;

      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          organizationId: this.organizationId,
          template: this.isTemplatePage,
        };

        return serialize(transformedData, {
          dotsForObjectNotation: true,
          indices: true,
          nullsAsUndefineds: true,
          noFilesWithArrayNotation: true,
        });
      });

      if (this.form.id) {
        preparedForm.put(this.route("assets.update", this.asset.id), {
          preserveScroll: true,
          onSuccess: () => {
            if (!close) {
              if (clearForm) {
                this.setForm(null);
              }
              this.$emit("reload");
              return;
            }
            this.$emit("reload", this.asset.id); // makes sense to also reload on form close?

            this.show = false;
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      } else {
        preparedForm.post(this.route("assets.store"), {
          preserveScroll: true,
          onSuccess: () => {
            if (!close) {
              this.setForm(null);
              this.$emit("reload");
              return;
            }
            this.$emit("reload"); // makes sense to also reload on form close?

            this.show = false;
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      }
    },
    runAiOnAsset(onEnd) {
      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          organizationId: this.organizationId,
          template: this.isTemplatePage,
        };

        return serialize(transformedData, {
          dotsForObjectNotation: true,
          indices: true,
          nullsAsUndefineds: true,
          noFilesWithArrayNotation: true,
        });
      });
      if (this.form.id) {
        preparedForm.put(this.route("assets.update", this.asset.id), {
          preserveScroll: true,
          onSuccess: () => {
            setTimeout(() => {
              this.$inertia.post(
                this.route("assets.ai.run", this.asset.id),
                {},
                {
                  onSuccess: () => {
                    this.$emit("reload");
                  },
                  onError: () => {
                    console.log("error");
                  },
                  onFinish: () => {
                    onEnd();
                  },
                }
              );
            }, 300);
          },
          onError: () => {
            onEnd();
          },
        });
      } else {
        preparedForm.post(this.route("assets.store"), {
          preserveScroll: true,
          onSuccess: () => {
            this.$emit("reload");
            setTimeout(() => {
              const assetId = this.$page.props.modifiedAsset.id;
              this.$inertia.post(
                this.route("assets.ai.run", assetId),
                {},
                {
                  onSuccess: () => {
                    this.$emit("reload");
                  },
                  onError: () => {
                    console.log("error");
                  },
                  onFinish: () => {
                    onEnd();
                  },
                }
              );
            }, 300);
          },
          onError: () => {
            onEnd();
          },
        });
      }
    },
    deleteAsset() {
      this.showConfirmDialog = true;
    },
    handleConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmDeleteAsset();
      }
    },
    confirmDeleteAsset() {
      this.$emit("deleteAsset", this.form.id);
      this.show = false;
    },
    getAssetLogs() {
      fetch(this.route("api.assets.logs", this.asset.id))
        .then((res) => res.json())
        .then((data) => {
          this.logData = data.data;
        });
    },
    selectLogEntry(id) {
      //Get log entry data
      fetch(this.route("api.assets.log", id))
        .then((res) => res.json())
        .then((data) => {
          this.selectedLog = data;
        });
    },
  },
};
</script>
<style>
.footer-section {
  position: sticky;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #e8e8e8;
}
</style>
