<template>
  <v-row class="px-2">
    <v-col lg="3" class="pt-2 pb-0 px-2 pr-0 d-flex">
      <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
        <v-text-field
          :label="$t('components.accountingEntries.listFilter.search')"
          color="#686868"
          :class="`search-field rounded-0 pt-0 ${query ? 'no-result' : ''}`"
          height="44"
          light
          hide-details="auto"
          background-color="#F7F7F7"
          filled
          solo
          dense
          prepend-inner-icon="mdi-magnify"
          flat
          clearable
          @click:clear="query = null"
          @change="(val) => (query = val)"
          :value="query"
        ></v-text-field>
      </div>
    </v-col>
    <v-col cols="auto" lg="2" class="pt-2 px-0 pr-2 pr-lg-0 pb-0 d-flex">
      <div class="flex-grow-1 white pa-4 pl-2 d-flex align-center">
        <v-badge
          overlap
          :content="layoutOptions.filters.length"
          :value="layoutOptions.filters.length > 0"
        >
          <FiltersModal
            v-model="showFiltersModal"
            @apply-filters="(filters) => updateFilters(filters)"
            :filters="layoutOptions.filters"
            :filterFields="filterFields"
            :sortOnlyFields="sortOnlyFields"
            :quickFilters="quickFilters"
            :hiddenFields="hiddenFields"
          >
            <template #trigger-button>
              <v-icon color="#686868">mdi-filter-outline</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("components.assets.listFilter.moreFilters") }}
              </span>
            </template>
          </FiltersModal>
        </v-badge>
      </div>
    </v-col>
    <v-col cols="12" lg="7" class="pt-0 pt-lg-2 pb-0 px-2 pl-lg-0 d-flex">
      <div
        class="flex-grow-1 white pa-4 d-flex d-flex-row align-center justify-end flex-column flex-sm-row"
      >
        <AccountingEntryLayoutOptions
          :fieldOptions="accountingEntryFields"
          :fieldSelections="layoutOptions.fields"
          @apply-fields="(fields) => updateFields(fields)"
          @reset-layout="() => resetLayout()"
        >
          <template #trigger-button>
            <layout-icon class="mr-3"></layout-icon>
            {{ $t("components.assets.listFilter.layoutOptions") }}
          </template>
        </AccountingEntryLayoutOptions>

        <!-- CREATE BUTTON -->

        <!--<span
          class="d-none d-lg-block mx-4 mx-md-8"
          style="border-right: 1px solid #d9d9d9; height: 56px"
        ></span>-->

        <!--<v-menu class="create-menu" offset-y light>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              class="align-self-stretch align-self-sm-center mt-5 mt-sm-0"
              elevation="0"
              tile
              color="#F25D3B"
              v-bind="attrs"
              v-on="on"
              dark
            >
              <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
              {{ $t("components.assets.listFilter.create.label") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('clickCreateSingle', $event)">
              <v-list-item-title>
                {{ $t("components.assets.listFilter.create.manual") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('clickImportFromCSV', $event)">
              <v-list-item-title>
                {{ $t("components.assets.listFilter.create.import") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>-->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FiltersModal from "../AccountingEntry/AccountingEntryFiltersModal.vue";
import LayoutIcon from "../../assets/svg/layout.svg";
import AccountingEntryLayoutOptions from "../AccountingEntry/AccountingEntryLayoutOptions.vue";

export default {
  components: {
    FiltersModal,
    LayoutIcon,
    AccountingEntryLayoutOptions,
  },
  props: {
    layoutOptions: Object,
    filterFields: Array,
    sortOnlyFields: Array,
    accountingEntryFields: Array,
    hiddenFields: Array,
  },
  data() {
    return {
      query: this.layoutOptions.searchText,
      showFiltersModal: false,
      quickFilters: [
        {
          name: this.$t(
            "components.accountingEntries.quickFilterOptions.ShowInvalidEntries"
          ),
          quickFilterId: 1,
          propertyName: "ShowInvalidEntries",
        },
        {
          name: this.$t(
            "components.accountingEntries.quickFilterOptions.OnlyShowInvalidEntries"
          ),
          quickFilterId: 2,
          propertyName: "OnlyShowInvalidEntries",
        },
        {
          name: this.$t(
            "components.accountingEntries.quickFilterOptions.TotalCo2Emissions"
          ),
          accountingEntryLayout: null,
          filterText: null,
          filterType: null,
          propertyName: "TotalCo2Emissions",
          sortDesc: true,
          quickFilterId: 3,
        },
        {
          name: this.$t(
            "components.accountingEntries.quickFilterOptions.EntryAmount"
          ),
          quickFilterId: 4,
          propertyName: "EntryAmount",
          sortDesc: true,
        },
        {
          name: this.$t(
            "components.accountingEntries.quickFilterOptions.EmissionFactorStatus"
          ),
          filterText: "Undefined",
          filterType: "NotEqual",
          propertyName: "EmissionFactorStatus",
          sortDesc: null,
          quickFilterId: 5,
        },
      ],
    };
  },
  methods: {
    updateFilters(filters) {
      this.$emit("update-filters", filters);
    },
    updateFields(fields) {
      this.$emit("update-fields", fields);
    },
    resetLayout() {
      this.$emit("update-fields", []);
    },
  },
  watch: {
    query(to) {
      this.$emit("search-text", to);
    },
  },
};
</script>

<style lang="scss" scoped>
$no-search-result-color: #f44336;

.search-field {
  &:deep(.v-text-field__slot .v-label) {
    color: #aeaeae !important;
    font-size: 15px;
    font-weight: 400;
  }

  &.no-result {
    border-bottom: solid 2px $no-search-result-color;

    :deep(i.v-icon.v-icon::before) {
      color: $no-search-result-color;
    }
  }
}
</style>
