<template>
  <div class="fill-height grow d-flex flex-column flex-nowrap">
    <v-container fluid class="py-4">
      <div class="fill-height fill-width page-container pa-4">
        <div
          class="ma-2 d-flex justify-space-between"
          v-if="isCancelled"
          style="background-color: #fcdfd8; border-radius: 5px"
        >
          <p class="ma-4 py-4">
            {{
              this.$t(
                "pages.billing.yourBilling.cancelledSubscriptionWarning",
                {
                  subscriptionEndDate: formatDate(payment.subscriptionEndDate),
                }
              )
            }}
          </p>
          <div
            class="d-flex pr-6 align-center justify-end font-weight-bold green--text"
            style="width: 300px; cursor: pointer"
            @click="resumeSubscription()"
          >
            {{ $t("pages.billing.yourBilling.resumeSubscription") }}
          </div>
        </div>
        <div>
          <h1 class="text-h5 main-text-color ma-2">
            {{ $t("pages.billing.yourBilling.title") }}
          </h1>
        </div>
        <div
          class="d-flex flex-column ma-2 pa-2"
          style="border: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between">
            <div
              class="d-flex justify-space-between ma-2 pb-2"
              style="width: 100%; border-bottom: 1px solid #ccc"
            >
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.yourBilling.billingCycle") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.yourBilling.billingPeriod") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.yourBilling.price") }}
              </div>
              <div
                class="d-flex justify-space-between"
                style="width: 300px"
              ></div>
            </div>
          </div>
          <div class="d-flex justify-space-between font-weight-bold">
            <div class="d-flex justify-space-between ma-2" style="width: 100%">
              <div
                class="d-flex justify-space-between align-self-end"
                style="width: 300px"
              >
                <div v-if="hasValidBillingSubscription">
                  {{ billingSubscription.billingCycle }}
                </div>
              </div>
              <div
                class="d-flex justify-space-between align-self-end"
                style="width: 300px"
              >
                <div v-if="hasValidBillingSubscription">
                  {{ formatDate(billingSubscription.currentPeriodStart) }} -
                  {{ formatDate(billingSubscription.currentPeriodEnd) }}
                </div>
              </div>
              <div class="d-flex justify-start" style="width: 300px">
                <div v-if="hasValidBillingSubscription">
                  {{ billingSubscription.currency }}
                  {{ formatPrice(billingSubscription.price) }}
                  <div class="tooltip-container">
                    <info-icon class="pl-2"></info-icon>
                    <div class="tooltip">
                      {{ $t("pages.billing.yourBilling.info") }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-end pr-4 align-self-end"
                style="width: 300px; color: #f25d3b; cursor: pointer"
                @click="openPaymentDialog()"
              >
                <div v-if="hasValidBillingSubscription">
                  {{ $t("pages.billing.yourBilling.updateSubscription") }}
                </div>
                <div v-else>
                  {{ $t("pages.billing.yourBilling.purchaseSubscription") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="billingPaymentMethods && !isCancelled"
          class="d-flex justify-end mx-4"
        >
          <div
            class="d-flex justify-end pr-6 align-self-end font-weight-bold"
            style="width: 300px; color: #f25d3b; cursor: pointer"
            @click="cancelSubscription()"
          >
            {{ $t("pages.billing.yourBilling.cancelSubscription") }}
          </div>
        </div>

        <div>
          <h1 class="text-h5 main-text-color ma-2 pt-6">
            {{ $t("pages.billing.yourSubscription.title") }}
          </h1>
        </div>
        <div
          class="d-flex flex-column ma-2 pa-2"
          style="border: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between">
            <div
              class="d-flex justify-space-between ma-2 pb-2"
              style="width: 100%; border-bottom: 1px solid #ccc"
            >
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.yourSubscription.billingPlan") }}
              </div>
              <!-- <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.yourSubscription.files") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.yourSubscription.orderLines") }}
              </div> -->
            </div>
          </div>
          <div class="d-flex justify-space-between font-weight-bold">
            <div class="d-flex justify-space-between ma-2" style="width: 100%">
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ currentSubscriptionPrice }}
              </div>
              <!-- <div style="width: 300px">
                <div class="d-flex justify-space-between" v-if="billingLimits">
                  {{ billingLimits.fileCount }}
                  {{ $t("pages.billing.yourSubscription.of") }}
                  {{ billingLimits.allowedFiles }}
                </div>
                <div class="progress-bar" v-if="billingLimits">
                  <div
                    class="progress-bar-fill"
                    :style="
                      'width:' +
                      billingLimits.percentageFilesUsed +
                      '%; background-color: ' +
                      getFillColor(billingLimits.percentageFilesUsed)
                    "
                  ></div>
                </div>
              </div>
              <div style="width: 300px">
                <div class="d-flex justify-space-between" v-if="billingLimits">
                  {{ billingLimits.lineCount }}
                  {{ $t("pages.billing.yourSubscription.of") }}
                  {{ billingLimits.allowedLines }}
                </div>
                <div class="progress-bar" v-if="billingLimits">
                  <div
                    class="progress-bar-fill"
                    :style="
                      'width:' +
                      billingLimits.percentageLinesUsed +
                      '%; background-color: ' +
                      getFillColor(billingLimits.percentageLinesUsed)
                    "
                  ></div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="pl-2 d-flex">
          <v-simple-checkbox
            color="red"
            :ripple="false"
            :value="autoUpdate"
            @input="setAutoUpdate"
          ></v-simple-checkbox>
          <div>
            {{ $t("pages.billing.yourSubscription.autoUpdate") }}
          </div>
        </div>

        <div v-if="autoUpdate" class="pl-2 d-flex justify-start">
          <div class="font-weight-bold main-text-color pr-2">
            {{ $t("pages.billing.yourSubscription.noteTitle") }}
          </div>
          {{ $t("pages.billing.yourSubscription.noteText") }}
        </div>

        <div class="d-flex justify-space-between">
          <h1 class="text-h5 main-text-color ma-2 pt-6">
            {{ $t("pages.billing.paymentMethod.title") }}
          </h1>
          <div
            class="d-flex justify-space-between"
            style="cursor: pointer"
            @click="addCardModal()"
          >
            <card-add-icon class="align-self-end mb-4"></card-add-icon>
            <h1 class="text-subtitle-2 grey--text ma-2 pt-6">
              {{ $t("pages.billing.paymentMethod.addCard") }}
            </h1>
          </div>
        </div>
        <div
          class="d-flex flex-column ma-2 pa-2"
          style="border: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between">
            <div
              class="d-flex justify-space-between ma-2 pb-2"
              style="width: 100%; border-bottom: 1px solid #ccc"
            >
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.paymentMethod.cardInfo") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.paymentMethod.expiration") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.paymentMethod.cardOwner") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.paymentMethod.billingAddress") }}
              </div>
              <div
                class="d-flex justify-space-between"
                style="width: 300px"
              ></div>
            </div>
          </div>
          <div
            v-for="billingPaymentMethod in billingPaymentMethods"
            :key="billingPaymentMethod.id"
            class="d-flex justify-space-between font-weight-bold"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between ma-2" style="width: 100%">
              <div
                class="d-flex justify-start align-center"
                style="width: 300px"
              >
                <div
                  v-if="billingPaymentMethods"
                  class="d-flex justify-start align-center"
                >
                  <div
                    v-if="billingPaymentMethod.cardBrand === 'visa'"
                    class="pr-1"
                  >
                    <card-visa></card-visa>
                  </div>
                  <div
                    v-else-if="billingPaymentMethod.cardBrand === 'mastercard'"
                    class="pr-1"
                  >
                    <card-mastercard></card-mastercard>
                  </div>
                  <div v-else class="pr-1">
                    <card-generic></card-generic>
                  </div>
                  {{ billingPaymentMethod.cardBrand }}
                  {{ $t("pages.billing.paymentMethod.endingIn") }}
                  {{ billingPaymentMethod.last4 }}
                </div>
              </div>
              <div
                v-if="billingPaymentMethods"
                class="d-flex justify-space-between align-center"
                style="width: 300px"
              >
                {{ formatExpiration(billingPaymentMethod.expiration) }}
              </div>
              <div
                class="d-flex justify-space-between align-center"
                style="width: 300px"
              >
                <div v-if="billingCustomers">
                  {{ billingCustomers[0].name }}
                </div>
              </div>
              <div
                class="d-flex justify-space-between align-center"
                style="width: 300px"
              >
                <div v-if="billingCustomers">
                  {{ billingCustomers[0].addressLine1 }}
                  {{ billingCustomers[0].postalCode }}
                  {{ billingCustomers[0].city }}
                  {{ billingCustomers[0].country }}
                </div>
              </div>
              <div class="d-flex justify-end align-center" style="width: 300px">
                <div
                  v-if="billingPaymentMethod.isPrimary"
                  style="color: #f25d3b"
                  class="pr-4"
                >
                  {{ $t("pages.billing.paymentMethod.primary") }}
                </div>

                <v-menu
                  offset-y
                  min-width="120px"
                  class="px-4"
                  left
                  v-if="billingCustomers && billingPaymentMethods.length > 0"
                >
                  <template
                    v-if="!billingPaymentMethod.isPrimary"
                    v-slot:activator="{ on, attrs }"
                  >
                    <div class="d-flex justify-end">
                      <v-btn v-bind="attrs" v-on="on" icon
                        ><v-icon>mdi-dots-vertical</v-icon></v-btn
                      >
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="selectCardAsPrimary(billingPaymentMethod)"
                    >
                      <v-list-item-title>
                        {{
                          $t("pages.billing.paymentMethod.menu.selectAsPrimary")
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="removeCard(billingPaymentMethod)"
                      v-if="billingPaymentMethods.length > 1"
                    >
                      <v-list-item-title>
                        {{ $t("pages.billing.paymentMethod.menu.remove") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-space-between">
          <h1 class="text-h5 main-text-color ma-2 pt-6">
            {{ $t("pages.billing.billingContact.title") }}
          </h1>
          <div
            class="d-flex justify-space-between"
            style="cursor: pointer"
            @click="addContactModal()"
          >
            <user-add-icon class="align-self-end mb-4"></user-add-icon>
            <h1 class="text-subtitle-2 grey--text ma-2 pt-6">
              {{ $t("pages.billing.billingContact.addContact") }}
            </h1>
          </div>
        </div>
        <div
          class="d-flex flex-column ma-2 pa-2"
          style="border: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between">
            <div
              class="d-flex justify-space-between ma-2 pb-2"
              style="width: 100%; border-bottom: 1px solid #ccc"
            >
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.billingContact.name") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 300px">
                {{ $t("pages.billing.billingContact.email") }}
              </div>
              <div
                class="d-flex justify-space-between"
                style="width: 300px"
              ></div>
              <div
                class="d-flex justify-space-between"
                style="width: 300px"
              ></div>
              <div
                class="d-flex justify-space-between"
                style="width: 300px"
              ></div>
            </div>
          </div>
          <div
            v-for="billingCustomer in billingCustomers"
            :key="billingCustomer.id"
            class="d-flex justify-space-between font-weight-bold"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between ma-2" style="width: 100%">
              <div class="d-flex justify-space-between" style="width: 300px">
                <div v-if="billingCustomers">
                  {{ billingCustomer.name }}
                </div>
              </div>
              <div class="d-flex justify-space-between" style="width: 300px">
                <div v-if="billingCustomers">
                  {{ billingCustomer.email }}
                </div>
              </div>
              <div
                class="d-flex justify-space-between"
                style="width: 300px"
              ></div>
              <div
                class="d-flex justify-space-between"
                style="width: 300px"
              ></div>
              <div class="d-flex justify-end align-center" style="width: 300px">
                <v-menu
                  offset-y
                  min-width="120px"
                  class="px-4"
                  left
                  v-if="billingCustomers"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex justify-end">
                      <v-btn v-bind="attrs" v-on="on" icon
                        ><v-icon>mdi-dots-vertical</v-icon></v-btn
                      >
                    </div>
                  </template>
                  <v-list>
                    <v-list-item @click="editContactModal(billingCustomer)">
                      <v-list-item-title>
                        {{ $t("pages.billing.billingContact.menu.edit") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h1 class="text-h5 main-text-color ma-2 pt-6">
            {{ $t("pages.billing.transactions.title") }}
          </h1>
        </div>
        <div
          class="d-flex flex-column ma-2 pa-2"
          style="border: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between">
            <div
              class="d-flex justify-space-between ma-2 pb-2"
              style="width: 100%; border-bottom: 1px solid #ccc"
            >
              <div class="d-flex justify-space-between" style="width: 200px">
                {{ $t("pages.billing.transactions.amount") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 150px">
                {{ $t("pages.billing.transactions.status") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 150px">
                {{ $t("pages.billing.paymentMethod.title") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 200px">
                {{ $t("pages.billing.transactions.type") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 200px">
                {{ $t("pages.billing.transactions.customer") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 250px">
                {{ $t("pages.billing.transactions.date") }}
              </div>
              <div class="d-flex justify-space-between" style="width: 250px">
                {{ $t("pages.billing.transactions.invoice") }}
              </div>
            </div>
          </div>
          <div
            v-for="paymentIntent in constructedPaymentIntents"
            :key="paymentIntent.id"
            class="d-flex justify-space-between font-weight-bold"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between ma-2" style="width: 100%">
              <div class="d-flex justify-space-between" style="width: 200px">
                {{ paymentIntent.currency }}
                {{ formatPrice(paymentIntent.amount) }}
              </div>
              <div class="d-flex justify-space-between" style="width: 150px">
                {{ paymentIntent.status }}
              </div>
              <div class="d-flex justify-start" style="width: 150px">
                <div v-if="paymentIntent.cardBrand === 'visa'">
                  <card-visa-small></card-visa-small>
                </div>
                <div v-else-if="paymentIntent.cardBrand === 'mastercard'">
                  <card-mastercard-small></card-mastercard-small>
                </div>
                <div v-else>
                  <card-generic-small></card-generic-small>
                </div>
                &nbsp;•••• {{ paymentIntent.last4 }}
              </div>
              <div class="d-flex justify-space-between" style="width: 200px">
                {{ paymentIntent.description }}
              </div>
              <div class="d-flex justify-space-between" style="width: 200px">
                {{ paymentIntent.customer }}
              </div>
              <div class="d-flex justify-space-between" style="width: 250px">
                {{ formatInvoiceDate(paymentIntent.invoiceCreatedAt) }}
              </div>
              <div
                class="d-flex justify-space-between"
                style="width: 250px; color: #f25d3b"
              >
                <div class="d-flex justify-space-between">
                  {{ paymentIntent.invoiceNumber }}
                  <open-in-new-icon
                    class="mt-2 ml-1"
                    style="cursor: pointer"
                    @click="openInvoiceLink(paymentIntent.invoiceUrl)"
                  >
                  </open-in-new-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <billing-contact-modal
        v-if="hasValidBillingSubscription"
        v-model="showContactModalPopup"
        v-show="showContactModalPopup"
        :paymentId="billingSubscription.paymentId"
        :id="customerToEdit.id"
        :name="customerToEdit.name"
        :email="customerToEdit.email"
        :edit="isEditingContact"
      ></billing-contact-modal>
      <card-modal
        v-if="billingPaymentMethods"
        v-model="showCardModalPopup"
        v-show="showCardModalPopup"
        :publishable-key="publishableKey"
      >
      </card-modal>
      <payment-dialog
        v-model="paymentDialog"
        :publishableKey="publishableKey"
        :billingSubscription="billingSubscription"
        :paymentReturnUrl="paymentReturnUrl"
        :openedFromSettings="true"
        :paymentTierIds="paymentTierIds"
        :discountPercentage="discountPercentage"
        :organization="organization"
      />
      <confirm-dialog
        v-model="showCancelSubscriptionConfirmDialog"
        v-show="showCancelSubscriptionConfirmDialog"
        :confirm-message="confirmCancelSubscriptionMessage"
        @confirmed="handleCancelSubscriptionConfirmation"
      ></confirm-dialog>
      <confirm-dialog
        v-model="showResumeSubscriptionConfirmDialog"
        v-show="showResumeSubscriptionConfirmDialog"
        :confirm-message="confirmResumeSubscriptionMessage"
        @confirmed="handleResumeSubscriptionConfirmation"
      ></confirm-dialog>
    </v-container>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import { serialize } from "object-to-formdata";
import CardAddIcon from "../../assets/svg/card-add.svg";
import CardGenericSmall from "../../assets/svg/card-generic-small.svg";
import CardGeneric from "../../assets/svg/card-generic.svg";
import CardMastercardSmall from "../../assets/svg/card-mastercard-small.svg";
import CardMastercard from "../../assets/svg/card-mastercard.svg";
import CardVisaSmall from "../../assets/svg/card-visa-small.svg";
import CardVisa from "../../assets/svg/card-visa.svg";
import InfoIcon from "../../assets/svg/info-icon.svg";
import OpenInNewIcon from "../../assets/svg/open-in-new.svg";
import UserAddIcon from "../../assets/svg/user-add.svg";
import ConfirmDialog from "../../Components/ConfirmDialog.vue";
import PaymentDialog from "../../Components/Onboarding/PaymentDialog.vue";
import { FreeId } from "../../util/paymentProductPrice.js";
import BillingContactModal from "./BillingContactModal.vue";
import CardModal from "./CardModal.vue";

export default {
  layout: appLayout({
    title: "pages.billing.title",
    pushContent: false,
  }),
  components: {
    CardVisa,
    CardVisaSmall,
    CardMastercard,
    CardMastercardSmall,
    CardGeneric,
    CardGenericSmall,
    InfoIcon,
    CardAddIcon,
    UserAddIcon,
    OpenInNewIcon,
    BillingContactModal,
    CardModal,
    PaymentDialog,
    ConfirmDialog,
  },
  props: {
    payment: Object,
    billingSubscription: Object,
    billingPaymentMethods: Array,
    billingCustomers: Array,
    billingTransactions: Array,
    billingPaymentIntents: Array,
    billingLimits: Object,
    publishableKey: String,
    paymentReturnUrl: String,
    paymentTierIds: Object,
    discountPercentage: Number,
    organization: Object,
  },
  data() {
    return {
      showContactModalPopup: false,
      isEditingContact: false,
      showCardModalPopup: false,
      showCancelSubscriptionConfirmDialog: false,
      showResumeSubscriptionConfirmDialog: false,
      paymentDialog: false,
      customerToEdit: { id: null, name: null, email: null },
      deleteCardForm: this.$inertia.form({
        paymentId: String,
        paymentMethodId: String,
        paymentCustomerId: String,
        stripePaymentMethodId: String,
      }),
      primaryCardForm: this.$inertia.form({
        paymentId: String,
        paymentMethodId: String,
        paymentCustomerId: String,
        stripePaymentMethodId: String,
      }),
      cancelSubscriptionForm: this.$inertia.form({
        paymentId: String,
      }),
      resumeSubscriptionForm: this.$inertia.form({
        paymentId: String,
      }),
      autoUpdate: this.payment.autoUpdate,
    };
  },
  mounted() {
    const searchParams = new URLSearchParams(window.location.search);
    const openPayment = searchParams.get("openPayment");

    if (openPayment === "true") {
      this.openPaymentDialog();
    }
  },
  computed: {
    filteredBillingTransactions() {
      const filteredBillingTransactions = this.billingTransactions?.filter(
        (transaction) => transaction.status !== "draft"
      );

      return filteredBillingTransactions;
    },
    constructedPaymentIntents() {
      let modifiedPaymentIntents = this.billingPaymentIntents.map((item) => {
        let correspondingTransaction = this.billingTransactions.find(
          (transaction) =>
            transaction.stripeTransactionId === item.stripeInvoiceId
        );
        if (correspondingTransaction) {
          item.invoiceUrl = correspondingTransaction.invoiceUrl;
          item.invoiceNumber = correspondingTransaction.invoiceNumber;
          item.invoiceCreatedAt = correspondingTransaction.createdInStripe;
        } else {
          item.invoiceUrl = null;
          item.invoiceNumber = null;
          item.invoiceCreatedAt = null;
        }

        let correspondingCustomer = this.billingCustomers.find(
          (customer) =>
            customer.payment.stripeCustomerId === item.stripeCustomerId
        );
        if (correspondingCustomer) {
          item.customer = correspondingCustomer.name;

          let correspondingpaymentMethod = this.billingPaymentMethods.find(
            (paymentMethod) =>
              paymentMethod.stripePaymentMethodId ===
              correspondingCustomer.payment.stripePaymentMethodId
          );

          if (correspondingpaymentMethod) {
            item.cardBrand = correspondingpaymentMethod.cardBrand;
            item.last4 = correspondingpaymentMethod.last4;
          } else {
            item.cardBrand = null;
            item.last4 = null;
          }
        } else {
          item.customer = null;
          item.cardBrand = null;
          item.last4 = null;
        }
        return item;
      });

      modifiedPaymentIntents.sort(
        (a, b) => new Date(b.invoiceCreatedAt) - new Date(a.invoiceCreatedAt)
      );

      return modifiedPaymentIntents;
    },
    hasValidBillingSubscription() {
      return this.billingSubscription?.status === "active";
    },
    isCancelled() {
      if (this.payment.subscriptionEndDate) {
        return true;
      }
      return false;
    },
    productPriceFree() {
      return FreeId;
    },
    productPriceTier500() {
      return this.paymentTierIds[500];
    },
    productPriceTier1000() {
      return this.paymentTierIds[1000];
    },
    productPriceTier3000() {
      return this.paymentTierIds[3000];
    },
    productPriceTier6000() {
      return this.paymentTierIds[6000];
    },
    productPriceTier12000() {
      return this.paymentTierIds[12000];
    },
    productPriceTier20000() {
      return this.paymentTierIds[20000];
    },
    productPriceTier30000() {
      return this.paymentTierIds[30000];
    },
    currentSubscriptionPrice() {
      switch (this.payment.stripeProductPriceId) {
        case this.productPriceTier500:
          return this.$t(
            "pages.billing.yourSubscription.subscriptionname.tier500"
          );
        case this.productPriceTier1000:
          return this.$t(
            "pages.billing.yourSubscription.subscriptionname.tier1000"
          );
        case this.productPriceTier3000:
          return this.$t(
            "pages.billing.yourSubscription.subscriptionname.tier3000"
          );
        case this.productPriceTier6000:
          return this.$t(
            "pages.billing.yourSubscription.subscriptionname.tier6000"
          );
        case this.productPriceTier12000:
          return this.$t(
            "pages.billing.yourSubscription.subscriptionname.tier12000"
          );
        case this.productPriceTier20000:
          return this.$t(
            "pages.billing.yourSubscription.subscriptionname.tier20000"
          );
        case this.productPriceTier30000:
          return this.$t(
            "pages.billing.yourSubscription.subscriptionname.tier30000"
          );

        default:
          return this.payment.stripeProductPriceId;
      }
    },
    confirmCancelSubscriptionMessage() {
      return this.$t("pages.billing.yourBilling.cancelSubscriptionMessage");
    },
    confirmResumeSubscriptionMessage() {
      return this.$t("pages.billing.yourBilling.resumeSubscriptionMessage");
    },
  },
  methods: {
    formatDate(dateToFormat) {
      const inputDate = new Date(dateToFormat);

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      return inputDate.toLocaleDateString("en-US", options);
    },
    formatPrice(priceToFormat) {
      const numberString = priceToFormat.toString();
      const part1 = numberString.slice(0, -2);
      const part2 = numberString.slice(-2);

      const formattedPart1 = part1.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return formattedPart1 + "." + part2;
    },
    formatExpiration(expirationToFormat) {
      const parts = expirationToFormat.split(/\s+/);

      if (parts.length === 2) {
        const [month, year] = parts;

        const formattedMonth = month.trim();
        const formattedYear = year.trim().slice(-2);

        const formattedDate = `${formattedMonth.padStart(
          2,
          "0"
        )}/${formattedYear}`;
        return formattedDate;
      } else {
        return expirationToFormat;
      }
    },
    formatInvoiceDate(date) {
      const originalDate = new Date(date);

      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return originalDate.toLocaleDateString("en-US", options);
    },
    openInvoiceLink(url) {
      window.open(url, "_blank");
    },
    getFillColor(percentage) {
      if (percentage <= 50) {
        return "#266663";
      } else if (percentage > 50 && percentage <= 75) {
        return "#F2933B";
      } else {
        return "#D32C2C";
      }
    },
    addContactModal() {
      this.isEditingContact = false;
      this.showContactModalPopup = true;
    },
    editContactModal(billingCustomer) {
      this.customerToEdit.id = billingCustomer.id;
      this.customerToEdit.name = billingCustomer.name;
      this.customerToEdit.email = billingCustomer.email;

      this.isEditingContact = true;
      this.showContactModalPopup = true;
    },
    addCardModal() {
      this.showCardModalPopup = true;
    },
    openPaymentDialog() {
      this.paymentDialog = true;
    },
    selectCardAsPrimary(paymentMethod) {
      this.primaryCardForm.paymentId = paymentMethod.payment.id;
      this.primaryCardForm.paymentMethodId = paymentMethod.id;
      this.primaryCardForm.paymentCustomerId = paymentMethod.paymentCustomerId;
      this.primaryCardForm.stripePaymentMethodId =
        paymentMethod.stripePaymentMethodId;
      this.primaryCardForm.post(this.route("payments.select-card-as-primary"));
    },
    removeCard(paymentMethod) {
      this.deleteCardForm.paymentId = paymentMethod.payment.id;
      this.deleteCardForm.paymentMethodId = paymentMethod.id;
      this.deleteCardForm.paymentCustomerId = paymentMethod.paymentCustomerId;
      this.deleteCardForm.stripePaymentMethodId =
        paymentMethod.stripePaymentMethodId;
      this.deleteCardForm.post(this.route("payments.delete-card"));
    },
    cancelSubscription() {
      this.showCancelSubscriptionConfirmDialog = true;
    },
    handleCancelSubscriptionConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmCancelSubscription();
      }
    },
    confirmCancelSubscription() {
      this.cancelSubscriptionForm.paymentId = this.payment.id;
      this.cancelSubscriptionForm.post(
        this.route("payments.cancel-subscription")
      );
    },
    resumeSubscription() {
      this.showResumeSubscriptionConfirmDialog = true;
    },
    handleResumeSubscriptionConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmResumeSubscription();
      }
    },
    confirmResumeSubscription() {
      this.resumeSubscriptionForm.paymentId = this.payment.id;
      this.resumeSubscriptionForm.post(
        this.route("payments.resume-subscription")
      );
    },
    setAutoUpdate(value) {
      const body = {
        paymentId: this.payment.id,
        autoUpdate: value,
      };

      const formData = serialize(body);

      fetch(this.route("payments.set-auto-update"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.autoUpdate = data.autoUpdate;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  width: 350px;
  background-color: black;
  color: white;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  font-weight: normal;
  white-space: normal;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  max-width: 100%;
}

.main-text-color {
  color: rgba(0, 0, 0, 0.87);
}
</style>
