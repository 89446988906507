<template>
  <v-card-actions class="footer-section py-6 px-6 d-flex flex-column-reverse flex-md-row">
    <slot name="left"></slot>
    <v-spacer></v-spacer>
    <slot name="right"></slot>
  </v-card-actions>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer-section {
  position: sticky;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  z-index: 1;
}
</style>
