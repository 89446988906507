<template>
  <div class="d-flex flex-row align-center">
    <div class="d-flex flex-column fill-width">
      <v-textarea
        :value="text"
        :label="label"
        :rows="rows"
        :cols="cols"
        dense
        outlined
        readonly
        hide-details
        no-resize
        class="fill-width note-text"
      ></v-textarea>
      <FileBlock
        v-for="file in files"
        :key="file.id"
        :file="file"
        readonly
        class="mt-2"
      />
    </div>
    <v-btn icon class="ml-2" @click="$emit('remove', assetNote?.id)">
      <v-icon>mdi-trash-can-outline</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { normalizeISOString } from "../../util/dateTime";
import FileBlock from "../Files/FileBlock.vue";

export default {
  components: { FileBlock },
  emits: ["remove"],
  props: {
    assetNote: Object,
  },
  computed: {
    label() {
      let userName = this.assetNote?.cacheUserName?.trim() ?? "";

      if (!userName) {
        userName = this.$t("pages.assets.form.notes.unknownUser");
      }

      let timestamp = this.assetNote?.createdAt;

      if (!timestamp) {
        timestamp = this.$t("pages.assets.form.notes.unknownDate");
      } else {
        timestamp = this.formatDate(timestamp);
      }

      return `${userName} - ${timestamp}`;
    },
    text() {
      const text = this.assetNote?.text?.trim() ?? "";

      if (!text) {
        return this.$t("pages.assets.form.notes.emptyText");
      }

      return text;
    },
    files() {
      return this.assetNote?.files ?? [];
    },
    settings() {
      return this.$inertia.page.props.settings;
    },
    cols() {
      return 48;
    },
    rows() {
      return this.text.split("\n").reduce((total, line) => {
        const lineLength = Math.max(line.length, 1);

        return total + Math.ceil(lineLength / this.cols);
      }, 0);
    },
  },
  methods: {
    formatDate(timestamp) {
      if (!timestamp) return null;

      const normalizedDate = normalizeISOString(timestamp);

      if (this.settings.defaultDateFormat === "YYYY-MM-DD") {
        return DateTime.fromISO(normalizedDate).toFormat("yyyy-MM-dd HH:mm:ss");
      } else if (this.settings.defaultDateFormat === "DD-MM-YYYY") {
        return DateTime.fromISO(normalizedDate).toFormat("dd-MM-yyyy HH:mm:ss");
      } else return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.note-text {
  :deep(textarea) {
    margin-top: 8px !important;
  }
}
</style>
