const importFieldName = {
  accountNumber: "AccountNumber",
  entryText: "EntryText",
  entryDate: "EntryDate",
  entryAmount: "EntryAmount",
  voucherNumber: "VoucherNumber",
  quantity: "Quantity",
  unitType: "UnitType",
};

export { importFieldName };
