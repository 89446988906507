<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="12" sm="6" xl="3">
          <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
            <v-text-field
              :label="$t('pages.settings.index.searchInRules')"
              color="#686868"
              class="search-field rounded-0 pt-0"
              height="44"
              light
              hide-details="auto"
              background-color="#F7F7F7"
              filled
              solo
              dense
              prepend-inner-icon="mdi-magnify"
              flat
              clearable
              @change="(val) => updateSearch(val)"
              :value="filters.ruleSearch"
            ></v-text-field>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-btn
            large
            class="align-self-stretch align-self-sm-center mt-5 mr-4 mt-sm-0"
            elevation="0"
            tile
            color="#F25D3B"
            dark
            @click="showForm = true"
          >
            <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
            {{ $t("common.actions.create") }}
          </v-btn>
          <asset-rule-form
            v-model="showForm"
            :assetRuleTriggerProperties="assetRuleTriggerProperties"
            :assetRuleEntryTriggerProperties="assetRuleEntryTriggerProperties"
            :asset-rule="editingForm"
            :currencies="currencies"
            :settings="settings"
            :ghgCategories="ghgCategories"
            @setTab="$emit('setTab', tabId)"
            @ruleChanged="() => (editingForm = null)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selectedAssetRules"
            class="data-table elevation-0 row-pointer rounded-r-0 fill-height"
            :headers="headers"
            :items="assetRules.data"
            :server-items-length="assetRules.totalCount"
            :options.sync="pagination"
            :footer-props="tableFooterProps"
            :no-data-text="$t('common.dataTable.noData')"
            @dblclick:row="(event, { item }) => editAssetRule(item)"
            @update:page="changePage(pagination)"
            @update:items-per-page="changePage(pagination)"
            @update:sort-by="changePage(pagination)"
            @update:sort-desc="changePage(pagination)"
          >
            <template #item.triggerValue="{ item }">
              <trigger-preview :asset-rule="item" :attributes="attributes" />
            </template>
            <template #item.actions="{ item }">
              <span v-if="item.isIgnoreRule" class="font-weight-bold">
                {{ $t("pages.settings.index.fields.ignore") }}
              </span>
              <template v-else>
                <span v-for="(action, index) in item.actions" :key="action.id">
                  <span class="font-weight-bold">
                    {{ getActionName(action) }}:
                  </span>
                  {{ action.displayText
                  }}{{ index + 1 === item.actions.length ? "" : "," }}
                </span>
              </template>
            </template>
            <template #item.edit="{ item }">
              <v-menu offset-y min-width="120px" class="px-4" left>
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="editAssetRule(item)">
                    <v-list-item-title>
                      {{ $t("common.actions.edit") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="destroyAssetRule(item.id)">
                    <v-list-item-title>
                      {{ $t("common.actions.delete") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <confirm-dialog
      v-model="showConfirmDialog"
      v-show="showConfirmDialog"
      :confirm-message="confirmMessage"
      @confirmed="handleConfirmation"
    ></confirm-dialog>
  </v-tab-item>
</template>
<script>
import debounce from "lodash/debounce";
import qs from "qs";
import { footerProps } from "@/util/dataTable";
import { serialize } from "object-to-formdata";
import AssetRuleForm from "../../AssetSettings/AssetRuleForm.vue";
import TriggerPreview from "../../../Components/AssetRule/TriggerPreview.vue";
import ConfirmDialog from "../../../Components/ConfirmDialog.vue";

export default {
  components: { AssetRuleForm, TriggerPreview, ConfirmDialog },
  props: {
    tabHref: String,
    tabId: Number,
    assetRules: Object,
    assetRuleTriggerProperties: Array,
    assetRuleEntryTriggerProperties: Array,
    currencies: Array,
    settings: Object,
    ghgCategories: Array,
    attributes: Array,
  },
  emits: ["updateAssetRuleSearch", "setTab"],
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      pagination: {
        page: this.assetRules.currentPage,
        itemsPerPage: this.assetRules.pageSize,
        sortBy: [searchParams.ruleSortBy ?? "referenceKey"],
        sortDesc: [searchParams.ruleSortDesc === "true"],
      },
      filters: {
        ruleSearch: searchParams["ruleSearch"] ?? null,
      },
      tableFooterProps: footerProps,
      selectedAssetRules: [],
      showForm: false,
      editingForm: null,
      showConfirmDialog: false,
      confirmMessage: this.$t("pages.settings.index.confirmDeleteAssetRule"),
      itemId: String,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("common.fieldNames.id"),
          value: "referenceKey",
        },
        {
          text: this.$t("pages.settings.index.fields.trigger"),
          value: "triggerValue",
        },
        {
          text: this.$t("pages.settings.index.fields.action"),
          value: "actions",
        },
        /*  cant find value in row data
          {
          text: this.$t("pages.settings.index.fields.applied"),
          value: "applied",
        },*/
        {
          text: "",
          value: "edit",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    updateSearch(value) {
      this.filters.ruleSearch = value;
    },
    changePage(options) {
      const existingQuery = qs.parse(window.location.search.substring(1));

      const query = {
        ...existingQuery,
        rulePage: options.page,
        rulePageSize: options.itemsPerPage,
        ruleSearch: this.filters?.ruleSearch ?? null,
        ruleSortBy:
          typeof options.sortBy !== "undefined" ? options.sortBy[0] : null,
        ruleSortDesc:
          typeof options.sortDesc !== "undefined" ? options.sortDesc[0] : null,
      };

      this.$inertia.get(
        this.route("assets.more", query),
        {},
        {
          preserveState: true,
          preserveScroll: true,
          onFinish: () => {
            this.$emit("setTab", this.tabId);
          },
        }
      );
    },
    editAssetRule(item) {
      this.editingForm = this.$inertia.form({
        ...item,
        applyToExisting: true,
      });

      this.showForm = true;
    },
    removeAssetRuleRange() {
      const confirmText = this.$t(
        "pages.settings.index.confirmDeleteAssetRules",
        { amount: this.selectedAssetRules.length }
      );

      if (!this.selectedAssetRules.length || !confirm(confirmText)) return;

      const form = serialize({
        assetRuleIds: this.selectedAssetRules.map((x) => x.id),
      });

      this.$inertia.post(this.route("asset-rules.destroy.range"), form, {
        onSuccess: () => (this.selectedAssetRules = []),
        onFinish: () => {
          this.$emit("setTab", this.tabId);
        },
      });
    },
    destroyAssetRule(id) {
      this.itemId = id;
      this.showConfirmDialog = true;
    },
    handleConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmDestroyAssetRule();
      }
    },
    confirmDestroyAssetRule() {
      this.$inertia.delete(this.route("asset-rules.destroy", this.itemId));
    },
    getActionName(action) {
      const attributeName = action.attribute?.name;

      return attributeName ?? action.propertyName;
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          page: 1,
          itemsPerPage: 50,
          sortBy: [],
          sortDesc: [],
        };
        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.data-table :deep(tr) {
  height: 70px !important;
}
</style>
