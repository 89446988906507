<template>
  <div class="fill-width">
    <dialog-form-section-one-col v-if="substep === 1">
      <dialog-form-file-upload-input
        :selectedImportFile="chartOfAccountsFile"
        :allowedImportMimeTypes="allowedMimeTypes"
        :allowedFileExpensions="allowedFileExtensions"
        :overrideUploadFileText="
          $t('components.integrations.manualFileUpload.stepTwo.upload')
        "
        :overrideDragOrClickText="
          $t('components.integrations.manualFileUpload.dragAndDrop')
        "
        @fileChanged="chartOfAccountsFile = $event"
        :legacyInputEvent="false"
      ></dialog-form-file-upload-input>
      <v-alert v-if="chartOfAccountsParseError" dense type="error">
        {{ chartOfAccountsParseError }}
      </v-alert>
      <div class="d-flex align-center flex-column">
        <span class="d-inline-block grey--text">
          {{
            $t("components.integrations.manualFileUpload.supportedFiletypes")
          }}
        </span>
        <span class="d-inline-block grey--text">
          {{
            $t(
              "components.integrations.manualFileUpload.stepTwo.seeRequiredFormat"
            )
          }}
        </span>
        <a
          class="d-inline-block"
          href="/examples/Chart of accounts.csv"
          target="_blank"
        >
          Chart of accounts.csv
        </a>
      </div>
    </dialog-form-section-one-col>
    <dialog-form-section-one-col v-else-if="substep === 2">
      <v-list class="category-account-mapper pt-0" tile dense>
        <div class="list-header">
          <p class="name">
            {{ $t("components.onboarding.accountMapper.name") }}
          </p>
          <p class="syncTo">
            {{ $t("components.onboarding.accountMapper.syncTo") }}
          </p>
        </div>
        <v-list-item
          v-for="account in accounts"
          :key="account.accountNumber"
          class="account-number"
        >
          <v-list-item-title class="account-mapping-line">
            {{ account.accountNumber }} {{ account.name }}
          </v-list-item-title>
          <SelectGHGCategory
            valueKey="id"
            standard-accounts
            :items="standardAccountOptions"
            @change="(id) => handleAccountMapping(account, id)"
          ></SelectGHGCategory>
        </v-list-item>
      </v-list>
    </dialog-form-section-one-col>
  </div>
</template>
<script>
import { serialize } from "object-to-formdata";
import DialogFormSectionOneCol from "../../Dialog/DialogFormSectionOneCol.vue";
import DialogFormFileUploadInput from "../../Dialog/inputs/DialogFormFileUploadInput.vue";
import SelectGHGCategory from "../../Inputs/SelectGHGCategory.vue";

export default {
  components: {
    DialogFormSectionOneCol,
    DialogFormFileUploadInput,
    SelectGHGCategory,
  },
  props: {
    substep: Number,
    loading: Boolean,
    accounts: Array,
    accountMappings: Array,
    standardAccounts: Array,
  },
  data() {
    return {
      chartOfAccountsFile: null,
      chartOfAccountsParseError: null,

      allowedFileExtensions: ".xlsx,.xls,.csv",
      allowedMimeTypes: [
        "application/csv",
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    };
  },
  computed: {
    standardAccountOptions() {
      const headlineOption = {
        name: `[${this.$t(
          "components.integrations.manualFileUpload.stepTwo.headline"
        )}]`,
        id: "headline",
      };

      let options = [headlineOption];

      if (this.standardAccounts) {
        const formattedStandardAccounts = this.standardAccounts.map((x) => ({
          ...x,
          name: x.accountName,
        }));

        options = [...options, ...formattedStandardAccounts];
      }

      return options;
    },
  },
  methods: {
    parseChartOfAccounts(file) {
      this.$emit("update:loading", true);

      const requestBody = {
        chartOfAccounts: file,
      };

      const formData = serialize(requestBody);

      fetch(this.route("api.manual-file-upload.accounts.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errorCode) {
            const errorPrefix =
              "components.integrations.manualFileUpload.stepTwo.errors.";

            this.chartOfAccountsParseError = this.$t(
              `${errorPrefix}${data.errorCode}`,
              {
                lineNumber: data.lineNumber,
              }
            );
            this.$emit("update:accounts", []);
            return;
          }

          this.$emit("update:accounts", data ?? []);
          this.chartOfAccountsParseError = null;
        })
        .finally(() => {
          this.$emit("update:loading", false);
        });
    },
    handleAccountMapping(account, standardAccountId) {
      let existingMappings = [...this.accountMappings];

      let accountMapping = existingMappings.find(
        (x) => x.accountNumber === account.accountNumber
      );

      const mappingExists = !!accountMapping;

      if (mappingExists && !standardAccountId) {
        existingMappings = existingMappings.filter((x) => x !== accountMapping);
        this.$emit("update:accountMappings", existingMappings);
        return;
      }

      if (!mappingExists) {
        accountMapping = {
          accountNumber: account.accountNumber,
          standardAccountId: null,
          specifyAsHeadline: false,
        };
      }

      const specifyAsHeadline = standardAccountId === "headline";

      accountMapping.standardAccountId = specifyAsHeadline
        ? null
        : standardAccountId;
      accountMapping.specifyAsHeadline = specifyAsHeadline;

      if (mappingExists) {
        existingMappings = existingMappings.map((x) =>
          x.accountNumber === accountMapping.accountNumber ? accountMapping : x
        );
      } else {
        existingMappings.push(accountMapping);
      }

      this.$emit("update:accountMappings", existingMappings);
    },
  },
  watch: {
    chartOfAccountsFile(value) {
      if (!value) return;

      this.parseChartOfAccounts(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.category-account-mapper {
  border-top: 1px solid #e8e8e8;
  /*border-bottom: 1px solid #e8e8e8;*/

  .v-list-item__title {
    font-weight: normal !important;
  }

  &:deep(.v-list-group__header .v-list-item__icon) {
    margin-right: 0px !important;
    align-self: center;
  }

  > &:deep(.v-list-group__header.v-list-item) {
    padding: 0 16px !important;
  }

  .account-number {
    background: #fafafa !important;
    height: 48px;

    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}

.list-header {
  border-bottom: 1px solid #e8e8e8;
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;

  p {
    font-size: 12px;
    color: #b4b4b4;
    margin-bottom: 0;
  }

  .name {
    flex: 1;
  }

  .syncTo {
    width: 380px;
  }
}

.account-mapping-line {
  flex: 1;
  color: #868686;
  display: inline-block;
  align-items: center;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  &.sync-account {
    color: #000;
  }
}
</style>
