<template>
  <div style="position: relative">
    <v-btn
      @click="showDateRangePicker = !showDateRangePicker"
      height="auto"
      plain
      light
      elevation="0"
      :style="buttonStyle"
      text
      :class="
        $slots['default']
          ? 'd-block text-none text-left'
          : 'date-range-button pa-1 d-flex flex-row align-center justify-center border-right white--text text--lighten-1'
      "
    >
      <slot v-if="$slots['default']"></slot>
      <template v-else>
        <CalendarIcon class="mr-4 d-none d-md-block"></CalendarIcon>
        <div class="d-flex flex-sm-row align-center">
          <span
            class="caption text-capitalize text-center text-sm-left"
            :class="small ? 'text-lg-body-2' : 'text-lg-body-1'"
            >{{ formatDates[0] }}</span
          >
          <ArrowRightIcon class="mx-4"></ArrowRightIcon>
          <span
            class="caption text-capitalize text-center text-sm-left"
            :class="small ? 'text-lg-body-2' : 'text-lg-body-1'"
            >{{ formatDates[1] }}</span
          >
        </div>
      </template>
    </v-btn>
    <v-dialog
      ref="dialog"
      class="dialog"
      v-model="showDateRangePicker"
      persistent
      max-width="836px"
      @keydown.esc="showDateRangePicker = false"
      v-click-outside="{
        handler: onClickOutside,
      }"
    >
      <DateRangePicker
        opens="inline"
        class="date-range-picker"
        ref="picker"
        :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
        v-model="rawDates"
        autoApply
        @update="/*() => dateRangeChanged([])*/"
        :ranges="ranges"
      >
        <template v-slot:header="">
          <div class="date-range-header">
            {{ $t("components.inputs.dateRangePicker.title") }}
          </div>
          <div class="date-range-inputs d-flex pt-4">
            <div class="flex-grow-1" style="max-width: 270px; width: 270px">
              <DateTextInput
                :title="$t('components.inputs.dateRangePicker.fromDate')"
                class="mx-5"
                :year="dates[0].split('-')[0]"
                :month="dates[0].split('-')[1]"
                :day="dates[0].split('-')[2]"
                @change="
                  (val) => {
                    dateInputUpdated('startDate', val);
                  }
                "
                :tabKey="0"
                :defaultDateFormat="defaultDateFormat"
              ></DateTextInput>
            </div>
            <div class="flex-grow-1" style="max-width: 270px; width: 270px">
              <DateTextInput
                :title="$t('components.inputs.dateRangePicker.toDate')"
                class="mx-5"
                :year="dates[1].split('-')[0]"
                :month="dates[1].split('-')[1]"
                :day="dates[1].split('-')[2]"
                @change="
                  (val) => {
                    dateInputUpdated('endDate', val);
                  }
                "
                :tabKey="1"
                :defaultDateFormat="defaultDateFormat"
              ></DateTextInput>
            </div>
          </div>
        </template>
        <template v-slot:date="date">
          <div class="date-range-pin">
            <span>{{ new Date(date.date).getDate() }}</span>
          </div>
        </template>
        <template #ranges="ranges">
          <div class="ranges d-flex flex-column">
            <ul class="d-flex flex-column flex-grow-1">
              <li
                v-for="(range, name) in ranges.ranges"
                :key="name"
                @click="ranges.clickRange(range)"
              >
                {{ name }}
              </li>
              <li
                class="no-hover-effect"
                :class="
                  fiscalYearOverlapsCalendarYear
                    ? 'mt-3 mb-4 d-flex flex-column flex-grow-1 justify-end'
                    : 'mt-3 mb-3 d-flex flex-column flex-grow-1 justify-end'
                "
              >
                <p class="custom-range-heading mb-2">
                  {{
                    $t("components.inputs.dateRangePicker.ranges.calendarYear")
                  }}
                  {{
                    fiscalYearOverlapsCalendarYear
                      ? "/ " +
                        $t(
                          "components.inputs.dateRangePicker.ranges.fiscalYear"
                        )
                      : null
                  }}
                </p>
                <div class="d-flex flex-row align-center">
                  <v-btn icon small @click="previousCalendarYear()"
                    ><v-icon small>mdi-arrow-left</v-icon></v-btn
                  >
                  <v-btn
                    class="flex-grow-1 text-center"
                    text
                    @click="selectEntireYear()"
                    >{{ currentSelectedYear }}</v-btn
                  >
                  <v-btn icon small @click="nextCalendarYear()"
                    ><v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </div>
              </li>
              <li
                class="no-hover-effect mb-8"
                v-if="!fiscalYearOverlapsCalendarYear"
              >
                <p class="custom-range-heading mb-2">
                  {{
                    $t("components.inputs.dateRangePicker.ranges.fiscalYear")
                  }}
                </p>
                <div class="d-flex flex-row align-center">
                  <v-btn icon small @click="previousFiscalYear()"
                    ><v-icon small>mdi-arrow-left</v-icon></v-btn
                  >
                  <v-btn
                    text
                    @click="selectEntireFiscalYear()"
                    class="flex-grow-1 text-center"
                    small
                  >
                    {{ currentFiscalYear }}
                  </v-btn>
                  <v-btn icon small @click="nextFiscalYear()"
                    ><v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </div>
              </li>
            </ul>
          </div>
        </template>
      </DateRangePicker>
    </v-dialog>
  </div>
</template>

<script>
import ArrowRightIcon from "../../assets/svg/arrow-right.svg";
import CalendarIcon from "../../assets/svg/calendar.svg";
import DateRangePicker from "vue2-daterange-picker";
import { EventBus } from "../../eventBus";

//you need to import the CSS manually

import "./../../sass/date-range-selector.scss";
// On mobile write date as fx Lør, 28 Feb 2023 => 28/3/2023
import { DateTime } from "luxon";
import Vue from "vue";
import DateTextInput from "./DateTextInput.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    ArrowRightIcon,
    CalendarIcon,
    DateRangePicker,
    DateTextInput,
  },
  props: {
    fromDate: String,
    toDate: String,
    small: { type: Boolean, default: false },
    defaultDateFormat: { type: String, default: "YYYY-MM-DD" },
    buttonStyle: String,
  },
  data() {
    const today = DateTime.local().startOf("day");
    //const yesterday = today.minus({ days: 1 });

    let ranges = {};
    ranges[this.$t("components.inputs.dateRangePicker.ranges.allTime")] = [
      today.toJSDate(),
      today.toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.today")] = [
      today.toJSDate(),
      today.toJSDate(),
    ];
    /*ranges[this.$t("components.inputs.dateRangePicker.ranges.yesterday")] = [
      yesterday.toJSDate(),
      yesterday.toJSDate(),
    ];*/

    /*ranges[this.$t("components.inputs.dateRangePicker.ranges.thisWeek")] = [
      today.startOf("week").toJSDate(),
      today.endOf("week").startOf("day").startOf("day").toJSDate(),
    ];*/
    /*ranges[this.$t("components.inputs.dateRangePicker.ranges.lastWeek")] = [
      today.minus({ weeks: 1 }).startOf("week").toJSDate(),
      today.minus({ weeks: 1 }).endOf("week").startOf("day").toJSDate(),
    ];*/
    /*ranges[this.$t("components.inputs.dateRangePicker.ranges.thisMonth")] = [
      today.startOf("month").toJSDate(),
      today.endOf("month").startOf("day").toJSDate(),
    ];*/
    /*ranges[this.$t("components.inputs.dateRangePicker.ranges.lastMonth")] = [
      today.minus({ months: 1 }).startOf("month").toJSDate(),
      today.minus({ months: 1 }).endOf("month").startOf("day").toJSDate(),
    ];*/
    /*ranges[this.$t("components.inputs.dateRangePicker.ranges.last30Days")] = [
      today.minus({ days: 30 }).toJSDate(),
      today.toJSDate(),
    ];*/
    /*ranges[this.$t("components.inputs.dateRangePicker.ranges.last90Days")] = [
      today.minus({ days: 90 }).toJSDate(),
      today.toJSDate(),
    ];*/

    const fiscalYearStartMonth =
      this.$inertia.page.props.auth.settings.fiscalYearStartMonth;
    const fiscalYearEndMonth =
      this.$inertia.page.props.auth.settings.fiscalYearEndMonth;

    const fiscalYearFollowsCalendarYear = fiscalYearStartMonth === 1;

    ranges[
      fiscalYearFollowsCalendarYear
        ? this.$t(
            "components.inputs.dateRangePicker.ranges.thisYearAndFiscalYear"
          )
        : this.$t("components.inputs.dateRangePicker.ranges.thisYear")
    ] = [
      today.startOf("year").toJSDate(),
      today.endOf("Year").startOf("day").toJSDate(),
    ];
    ranges[
      fiscalYearFollowsCalendarYear
        ? this.$t(
            "components.inputs.dateRangePicker.ranges.lastYearAndFiscalYear"
          )
        : this.$t("components.inputs.dateRangePicker.ranges.lastYear")
    ] = [
      today.minus({ years: 1 }).startOf("year").toJSDate(),
      today.minus({ years: 1 }).endOf("year").startOf("day").toJSDate(),
    ];

    // only show fiscal year options if they dont follow calendar year
    if (!fiscalYearFollowsCalendarYear) {
      // Add range for "This fiscal year"
      const thisFiscalYearStart = today
        .minus({ years: today.get("month") > fiscalYearStartMonth ? 0 : 1 })
        .startOf("year")
        .set({ month: fiscalYearStartMonth });

      const thisFiscalYearEnd = today
        .startOf("year")
        .plus({ years: today.get("month") > fiscalYearStartMonth ? 1 : 0 })
        .set({ month: fiscalYearEndMonth })
        .endOf("month");

      ranges[
        this.$t("components.inputs.dateRangePicker.ranges.thisFiscalYear")
      ] = [thisFiscalYearStart.toJSDate(), thisFiscalYearEnd.toJSDate()];

      // Add range for "Last fiscal year"
      const lastFiscalYearStart = thisFiscalYearStart.minus({ years: 1 });
      const lastFiscalYearEnd = thisFiscalYearEnd.minus({ years: 1 });
      ranges[
        this.$t("components.inputs.dateRangePicker.ranges.lastFiscalYear")
      ] = [lastFiscalYearStart.toJSDate(), lastFiscalYearEnd.toJSDate()];
    }

    return {
      dates: [this.fromDate, this.toDate],
      showDateRangePicker: false,
      rawDates: { startDate: this.fromDate, endDate: this.toDate },
      ranges: ranges,
      blockDismissModal: false,
      fiscalYearStartMonth: fiscalYearStartMonth,
      fiscalYearEndMonth: fiscalYearEndMonth,
    };
  },
  watch: {
    fromDate(to) {
      this.dates = [to, this.toDate];
      this.rawDates = {
        startDate: new Date(to),
        endDate: new Date(this.toDate),
      };
    },
    toDate(to) {
      this.dates = [this.fromDate, to];
      this.rawDates = {
        startDate: new Date(this.fromDate),
        endDate: new Date(to),
      };
    },
    rawDates(to) {
      const fromDate = DateTime.fromJSDate(to.startDate).toISODate();
      const toDate = DateTime.fromJSDate(to.endDate).toISODate();

      this.dates = [fromDate, toDate];
    },
    dates(to) {
      this.dateRangeChanged(to);
    },
  },
  computed: {
    formatDates() {
      const formattedFrom = this.formatDate(this.dates[0]);
      const formattedTo = this.formatDate(this.dates[1]);
      return [formattedFrom, formattedTo];
    },
    fiscalYearOverlapsCalendarYear() {
      return this.fiscalYearStartMonth === 1;
    },
    currentSelectedYear() {
      return DateTime.fromISO(this.dates[0]).startOf("year").get("year");
    },
    currentFiscalYear() {
      const selectionFromDate = DateTime.fromISO(this.dates[0]);

      const fiscalYearEndOffset =
        selectionFromDate.get("month") < this.fiscalYearEndMonth + 1 ? 0 : 1;

      const fromDate = selectionFromDate
        .startOf("year")
        .plus({ month: this.fiscalYearStartMonth - 1 });
      const toDate = selectionFromDate
        .startOf("year")
        .plus({ month: this.fiscalYearEndMonth - 1 })
        .plus({ year: fiscalYearEndOffset })
        .endOf("month");

      if (this.defaultDateFormat === "YYYY-MM-DD") {
        return (
          toDate.startOf("year").get("year") +
          " " +
          fromDate.get("month") +
          "/" +
          fromDate.get("day") +
          " - " +
          toDate.get("month") +
          "/" +
          toDate.get("day")
        );
      }
      return (
        fromDate.get("day") +
        "/" +
        fromDate.get("month") +
        " - " +
        toDate.get("day") +
        "/" +
        toDate.get("month") +
        " " +
        toDate.startOf("year").get("year")
      );
    },
  },
  methods: {
    async getOldestAssetDate() {
      try {
        const response = await fetch(this.route("api.assets.oldest", {}));
        if (!response.ok) {
          return Promise.reject();
        }
        const data = await response.json();
        const formattedDate = data.split(".")[0];
        const oldestDate = new Date(formattedDate);

        const allTimeKey = this.$t(
          "components.inputs.dateRangePicker.ranges.allTime"
        );
        this.$set(this.ranges, allTimeKey, [
          oldestDate,
          DateTime.utc().endOf("day").toJSDate(),
        ]);
      } catch (error) {
        console.error("Error fetching oldest asset date:", error);
      }
    },

    onClickOutside(e) {
      if (
        this.showDateRangePicker &&
        e.target.classList.contains("v-overlay__scrim")
      ) {
        this.showDateRangePicker = false;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      if (this.defaultDateFormat === "YYYY-MM-DD") {
        return `${year}-${month}-${day}`;
      }
      if (this.defaultDateFormat === "DD-MM-YYYY") {
        return `${day}-${month}-${year}`;
      }
    },
    dateRangeChanged(dates) {
      const _dates = [...dates];

      // Flip the dates if from is larger than to
      if (new Date(_dates[0]).getTime() > new Date(_dates[1]).getTime()) {
        _dates.unshift(_dates[1]);
        _dates.pop();
      }

      if (_dates[0] && _dates[1]) {
        this.showDateRangePicker = this.blockDismissModal ? true : false;
        this.blockDismissModal = false;
        this.$emit("change", {
          from: _dates[0],
          to: _dates[1],
        });
      }
    },
    previousFiscalYear() {
      const selectionFromDate = DateTime.fromISO(this.dates[0]);

      // If selection date is after end of last month add 1 year to to date else subract one year from start point
      const fiscalYearEndOffset =
        selectionFromDate.get("month") < this.fiscalYearEndMonth + 1 ? 0 : 1;

      const from = selectionFromDate
        .startOf("year")
        .plus({ month: this.fiscalYearStartMonth - 1 })
        .minus({ year: 1 - fiscalYearEndOffset })
        .minus({ year: 1 })
        .toJSDate();
      const to = selectionFromDate
        .startOf("year")
        .plus({ month: this.fiscalYearEndMonth - 1 })
        .plus({ year: fiscalYearEndOffset })
        .minus({ year: 1 })
        .endOf("month")
        .toJSDate();

      this.blockDismissModal = true;
      this.updateDates(from, to);
    },
    nextFiscalYear() {
      const selectionFromDate = DateTime.fromISO(this.dates[0]);

      // If selection date is after end of last month add 1 year to to date else subract one year from start point
      const fiscalYearEndOffset =
        selectionFromDate.get("month") < this.fiscalYearEndMonth + 1 ? 0 : 1;

      const from = selectionFromDate
        .startOf("year")
        .plus({ month: this.fiscalYearStartMonth - 1 })
        .minus({ year: 1 - fiscalYearEndOffset })
        .plus({ year: 1 })
        .toJSDate();
      const to = selectionFromDate
        .startOf("year")
        .plus({ month: this.fiscalYearEndMonth - 1 })
        .plus({ year: fiscalYearEndOffset })
        .plus({ year: 1 })
        .endOf("month")
        .toJSDate();

      this.blockDismissModal = true;
      this.updateDates(from, to);
    },
    previousCalendarYear() {
      const from = DateTime.fromISO(this.dates[0])
        .minus({ years: 1 })
        .startOf("year")
        .toJSDate();

      const to = DateTime.fromISO(this.dates[0])
        .minus({ years: 1 })
        .endOf("year")
        .toJSDate();
      this.blockDismissModal = true;
      this.updateDates(from, to);
    },
    nextCalendarYear() {
      const from = DateTime.fromISO(this.dates[0])
        .plus({ years: 1 })
        .plus(1)
        .startOf("year")
        .toJSDate();
      const to = DateTime.fromISO(this.dates[0])
        .plus({ years: 1 })
        .endOf("year")
        .toJSDate();
      this.blockDismissModal = true;
      this.updateDates(from, to);
    },
    selectEntireFiscalYear() {
      const selectionFromDate = DateTime.fromISO(this.dates[0]);

      // If selection date is after end of last month add 1 year to to date else subract one year from start point
      const fiscalYearEndOffset =
        selectionFromDate.get("month") < this.fiscalYearEndMonth + 1 ? 0 : 1;

      const from = selectionFromDate
        .startOf("year")
        .plus({ month: this.fiscalYearStartMonth - 1 })
        .minus({ year: 1 - fiscalYearEndOffset })
        .toJSDate();
      const to = selectionFromDate
        .startOf("year")
        .plus({ month: this.fiscalYearEndMonth - 1 })
        .plus({ year: fiscalYearEndOffset })
        .endOf("month")
        .toJSDate();

      this.blockDismissModal = true;
      this.updateDates(from, to);
    },
    selectEntireYear() {
      const from = DateTime.fromISO(this.dates[0]).startOf("year").toJSDate();
      const to = DateTime.fromISO(this.dates[0]).endOf("year").toJSDate();
      this.blockDismissModal = true;
      this.updateDates(from, to);
    },
    updateDates(from, to) {
      if (from && to) {
        Vue.set(this, "rawDates", { startDate: from, endDate: to });
        // Update calendar rendering range
        this.$refs.picker.monthDate = from;
        this.$refs.picker.nextMonthDate = DateTime.fromJSDate(from)
          .plus({ month: 1 })
          .toJSDate();
      }
    },
    debouncedDateInputUpdated: debounce(function (key, val) {
      this.blockDismissModal = true;
      const rawVal = DateTime.fromISO(val).toJSDate();
      const _rawDates = this.rawDates;
      _rawDates[key] = rawVal;

      this.updateDates(_rawDates.startDate, _rawDates.endDate);
    }, 1000),
    dateInputUpdated(key, val) {
      // Call the debounced function
      this.debouncedDateInputUpdated(key, val);
    },
  },
  mounted() {
    this.getOldestAssetDate();

    EventBus.$on("organizationChanged", this.getOldestAssetDate);
  },
  beforeDestroy() {
    EventBus.$off("organizationChanged", this.getOldestAssetDate);
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  z-index: 99999;
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 600px) {
  .date-picker {
    z-index: 99999;
    top: 100%;
    position: absolute;

    right: 0;
    transform: translate(0%, 0%);
  }
}

.no-hover-effect {
  background-color: unset !important;
  color: unset !important;
  cursor: default !important;
}

.custom-range-heading {
  font-size: 11px;
  color: #b4b4b4;
}
</style>
