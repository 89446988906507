<template>
  <dialog-form
    :show-dialog="show"
    :max-width="modalWidth"
    :persistent="true"
    @showStateChanged="(val) => (show = val)"
    @clickOutside="handleDismiss"
  >
    <div class="d-flex flex-column px-0 pt-16 modal-content">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>
      <step-visualizer
        class="pb-8"
        :steps="3"
        :selected-step="selectedStep"
        disable-navigation
      />
      <div>
        <h1 class="text-h5 black--text mb-2 text-center font-weight-bold">
          {{ stepTitle }}
        </h1>
      </div>
      <div
        class="d-flex relative"
        style="overflow-y: scroll; overflow-x: hidden; min-height: 450px"
      >
        <Transition name="fade">
          <manual-file-upload-step-two
            v-if="selectedStep === 2"
            class="transition-item"
            :substep="selectedSubstep"
            :loading.sync="loading"
            :accounts.sync="accounts"
            :account-mappings.sync="accountMappings"
            :standard-accounts="standardAccounts"
          />
          <manual-file-upload-step-three
            v-else-if="selectedStep === 3"
            class="transition-item"
            :substep="selectedSubstep"
            :loading.sync="loading"
            :entriesFile.sync="entriesFile"
            :entries.sync="entries"
            :entry-field-mappings.sync="entryFieldMappings"
            :integration-name.sync="integrationName"
            :entry-validation="entryValidation"
            :import-error-code="importErrorCode"
            :unit-types="unitTypes"
            @update:entry-field="updateEntryField"
            @update:editing-entry-mappings="editingEntryMappings = $event"
          />
        </Transition>
      </div>
      <v-card-actions class="d-flex footer-section pa-8 justify-end pa-4">
        <v-btn
          v-if="isFinalStep"
          tile
          block
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize white--text"
          :disabled="isFinishButtonDisabled || loading"
          :loading="loading"
          @click="submit"
        >
          {{ $t("common.actions.finish") }}
        </v-btn>
        <v-btn
          v-else
          tile
          elevation="0"
          large
          color="orange"
          class="font-weight-regular text-capitalize white--text"
          :disabled="isNextButtonDisabled || loading"
          :loading="loading"
          @click="moveToNextStep"
        >
          {{ $t("common.actions.next") }}
        </v-btn>
      </v-card-actions>
    </div>
  </dialog-form>
</template>
<script>
import DialogForm from "../Dialog/DialogForm.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
import StepVisualizer from "../StepVisualizer.vue";
import ManualFileUploadStepTwo from "./ManualFileUploadSteps/ManualFileUploadStepTwo.vue";
import ManualFileUploadStepThree from "./ManualFileUploadSteps/ManualFileUploadStepThree.vue";
import { serialize } from "object-to-formdata";
import { importFieldName } from "../../util/accountingEntryData";

export default {
  components: {
    DialogForm,
    LogoBlack,
    StepVisualizer,
    ManualFileUploadStepTwo,
    ManualFileUploadStepThree,
  },
  props: {
    value: Boolean,
    integrationId: String,
  },
  data() {
    return {
      selectedStep: this.initialStep(),
      selectedSubstep: this.initialSubstep(),
      steps: [
        {
          id: 2,
          substepTitles: {
            1: this.$t(
              "components.integrations.manualFileUpload.stepTwo.title"
            ),
            2: this.$t(
              "components.integrations.manualFileUpload.stepTwo.title"
            ),
          },
        },
        {
          id: 3,
          substepTitles: {
            1: this.$t(
              "components.integrations.manualFileUpload.stepThree.titles.substepOne"
            ),
            2: this.$t(
              "components.integrations.manualFileUpload.stepThree.titles.substepTwo"
            ),
            3: this.$t(
              "components.integrations.manualFileUpload.stepThree.titles.substepThree"
            ),
          },
        },
      ],

      loading: false,
      editingEntryMappings: false,

      accounts: [],
      accountMappings: [],
      entriesFile: null,
      entries: [],
      entryFieldMappings: null,
      integrationName: null,
      entryValidation: null,
      importErrorCode: null,

      standardAccounts: [],
      unitTypes: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalWidth() {
      const step = this.selectedStep;
      const substep = this.selectedSubstep;

      const stepTwoUploadStage = step === 2 && substep === 1;
      const stepThreeUploadStage = step === 3 && substep === 1;
      const stepThreeFinishStage = step === 3 && substep === 3;

      if (stepTwoUploadStage || stepThreeUploadStage || stepThreeFinishStage) {
        return "538px";
      }

      const stepThreeMappingStage = step === 3 && substep === 2;

      if (stepThreeMappingStage) {
        return "90%";
      }

      return undefined;
    },
    currentStep() {
      return this.steps.find((x) => x.id === this.selectedStep);
    },
    stepTitle() {
      return this.currentStep.substepTitles[this.selectedSubstep];
    },
    isNextButtonDisabled() {
      const step = this.selectedStep;
      const substep = this.selectedSubstep;

      const stepTwoMissingAccounts =
        step === 2 && substep === 1 && !this.accounts?.length;

      const stepThreeMissingEntries = step === 3 && !this.entries?.length;

      const stepThreeEditingMappings =
        step === 3 && substep === 2 && this.editingEntryMappings;

      return (
        stepTwoMissingAccounts ||
        stepThreeMissingEntries ||
        stepThreeEditingMappings
      );
    },
    isFinishButtonDisabled() {
      const integrationName = this.integrationName?.trim() ?? "";

      return integrationName.length < 1;
    },
    isVoucherNumberMapped() {
      if (!this.entryFieldMappings) return false;

      return Object.values(this.entryFieldMappings).includes(
        importFieldName.voucherNumber
      );
    },
    isFinalStep() {
      return this.selectedStep === 3 && this.selectedSubstep === 3;
    },
  },
  methods: {
    fetchStandardAccounts() {
      fetch(this.route("api.standard-financial-accounts.all"))
        .then((res) => res.json())
        .then((data) => (this.standardAccounts = data ?? []));
    },
    fetchUnitTypes() {
      fetch(this.route("api.manual-file-upload.unit-types"))
        .then((res) => res.json())
        .then((data) => (this.unitTypes = data ?? []));
    },
    async validateEntries() {
      this.loading = true;

      const requestBody = serialize(
        {
          accountsJson: JSON.stringify(this.accounts),
          fieldMappings: this.entryFieldMappings,
          entriesJson: this.formatEntries(this.entries),
          integrationId: this.integrationId,
        },
        { indices: true, dotsForObjectNotation: true }
      );

      return await fetch(
        this.route("api.manual-file-upload.entries.validate"),
        {
          method: "POST",
          body: requestBody,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.entryValidation = data;
          this.entries = data.validatedEntries;

          return !data.hasErrors;
        })
        .finally(() => (this.loading = false));
    },
    submit() {
      this.loading = true;

      const requestBody = serialize(
        {
          accountsJson: JSON.stringify(this.accounts),
          accountMappingsJson: JSON.stringify(this.accountMappings),
          entriesJson: this.formatEntries(this.entries),
          entryFieldMappings: this.entryFieldMappings,
          entriesFile: this.entriesFile,
          integrationName: this.integrationName,
          integrationId: this.integrationId,
        },
        {
          indices: true,
          dotsForObjectNotation: true,
          noFilesWithArrayNotation: true,
        }
      );

      fetch(this.route("api.manual-file-upload.import"), {
        method: "POST",
        body: requestBody,
      })
        .then((res) => {
          if (res.status === 204) {
            this.show = false;
            this.reset();
            this.$inertia.reload();
            return;
          }

          return res.json();
        })
        .then((data) => {
          if (data?.errorCode) {
            this.importErrorCode = data.errorCode;
          }
        })
        .finally(() => {
          this.loading = false;
          this.$emit("completed-setup");
        });
    },
    async moveToNextStep() {
      const step = this.selectedStep;
      const substep = this.selectedSubstep;

      if (step === 2 && substep === 1 && !!this.accounts?.length) {
        this.selectedSubstep = 2;
        return;
      }

      if (step === 2 && substep === 2) {
        this.selectedStep = 3;
        this.selectedSubstep = 1;
        return;
      }

      if (step === 3 && substep === 1 && !!this.entries?.length) {
        this.selectedSubstep = 2;
        return;
      }

      if (step === 3 && substep === 2) {
        const isValid = await this.validateEntries();

        if (!isValid) return;

        const confirmNoVoucherText = this.$t(
          "components.integrations.manualFileUpload.confirmNoVoucher"
        );

        if (!this.isVoucherNumberMapped && !confirm(confirmNoVoucherText))
          return;

        if (!this.integrationId) {
          this.selectedSubstep = 3;
        } else {
          this.submit();
        }
      }
    },
    handleDismiss() {
      const confirmText = this.$t(
        "components.integrations.manualFileUpload.confirmExit"
      );

      if (confirm(confirmText)) {
        this.show = false;
        this.reset();
      }
    },
    updateEntryField(index, field, value) {
      const entry = this.entries[index];

      const entryRow = entry.row;

      entryRow[field] = value;

      if (entry.columnErrorMessages) {
        delete entry.columnErrorMessages[field];
      }
    },
    reset() {
      this.selectedStep = this.initialStep();
      this.selectedSubstep = this.initialSubstep();

      this.loading = false;
      this.editingEntryMappings = false;

      this.accounts = [];
      this.accountMappings = [];
      this.entriesFile = null;
      this.entries = [];
      this.entryFieldMappings = null;
      this.integrationName = null;
      this.entryValidation = null;
    },
    initialStep() {
      if (this.integrationId) {
        return 3;
      }

      return 2;
    },
    initialSubstep() {
      return 1;
    },
    formatEntries(entries) {
      return JSON.stringify(entries.map((x) => x.row));
    },
  },
  watch: {
    show(value) {
      if (value && !this.integrationId && this.standardAccounts.length === 0) {
        this.fetchStandardAccounts();
      }

      if (value && this.unitTypes.length === 0) {
        this.fetchUnitTypes();
      }

      if (value) {
        this.reset();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content {
  &:deep(.main-section) {
    border-bottom: unset;
  }

  .footer-section {
    border-top: 1px solid #e8e8e8;
  }
}

.fade-enter-active {
  transition: all 0.4s ease;
}
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter {
  transform: translateX(20px);
  opacity: 1;
}

.fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.transition-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
