<template>
  <dialog-form-input-wrapper :title="title" :ai-status="aiStatus">
    <v-select
      class="rounded-0 custom-select"
      style="cursor: pointer"
      background-color="#F7F7F7"
      solo
      flat
      :label="placeholder"
      filled
      light
      :menu-props="{ top: false, offsetY: true }"
      color="red"
      item-color="red"
      :items="items"
      :hide-details="hideDetails"
      :error-messages="errorMessages"
      :value="value"
      :item-value="itemValue"
      :item-text="itemText"
      :clearable="clearable"
      :disabled="disabled"
      @change="changedVal"
      @input="inputVal"
      @click:clear="clearVal()"
    >
    </v-select>
  </dialog-form-input-wrapper>
</template>

<script>
import DialogFormInputWrapper from "./DialogFormInputWrapper.vue";

export default {
  components: { DialogFormInputWrapper },
  props: {
    title: String,
    placeholder: String,
    aiStatus: String,
    items: Array,
    itemValue: String,
    itemText: [String, Function],
    value: [Number, String],
    errorMessages: [Array, String],
    hideDetails: Boolean,
    clearable: Boolean,
    disabled: Boolean,
  },
  emits: ["update", "input"],
  methods: {
    changedVal(value) {
      this.$emit("update", value);
      this.$emit("change", value);
    },
    inputVal(value) {
      this.$emit("input", value);
    },
    clearVal() {
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select:deep(input) {
  box-shadow: none;
}
</style>
