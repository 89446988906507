<template>
  <span>
    <span class="font-weight-bold">{{ firstProperty }}:</span>
    {{ firstValue }}
    <template v-if="secondLogicOperator">
      <span class="font-weight-bold">
        {{
          $t(
            `pages.settings.assetRuleForm.stepOne.logicOperators.${secondLogicOperator}`
          )
        }}
      </span>
      <span class="font-weight-bold">{{ secondProperty }}:</span>
      {{ secondValue }}
    </template>
    <template v-if="thirdLogicOperator">
      <span class="font-weight-bold">
        {{
          $t(
            `pages.settings.assetRuleForm.stepOne.logicOperators.${thirdLogicOperator}`
          )
        }}
      </span>
      <span class="font-weight-bold">{{ thirdProperty }}:</span>
      {{ thirdValue }}
    </template>
  </span>
</template>
<script>
import { fixedClasses } from "../../util/fixedAssetData";

export default {
  props: {
    assetRule: Object,
    attributes: Array,
  },
  computed: {
    dimensionAttributes() {
      return this.attributes.filter(
        (x) => x.attributeClassId === fixedClasses.dimensionsId
      );
    },
    firstProperty() {
      const property = this.assetRule.triggerPropertyName;

      return this.getPropertyName(property);
    },
    firstValue() {
      const property = this.assetRule.triggerPropertyName;
      const value = this.assetRule.triggerValue;

      return this.getPropertyValue(property, value);
    },
    secondLogicOperator() {
      return this.assetRule.secondTriggerLogicOperator;
    },
    secondProperty() {
      const property = this.assetRule.secondTriggerPropertyName;

      return this.getPropertyName(property);
    },
    secondValue() {
      const property = this.assetRule.secondTriggerPropertyName;
      const value = this.assetRule.secondTriggerValue;

      return this.getPropertyValue(property, value);
    },
    thirdLogicOperator() {
      return this.assetRule.thirdTriggerLogicOperator;
    },
    thirdProperty() {
      const property = this.assetRule.thirdTriggerPropertyName;

      return this.getPropertyName(property);
    },
    thirdValue() {
      const property = this.assetRule.thirdTriggerPropertyName;
      const value = this.assetRule.thirdTriggerValue;

      return this.getPropertyValue(property, value);
    },
  },
  methods: {
    getPropertyName(property) {
      const dimensionName = this.dimensionAttributes.find(
        (x) => x.id === property
      )?.name;

      if (dimensionName) return dimensionName;

      return property;
    },
    getPropertyValue(property, value) {
      const dimensionValue = this.dimensionAttributes
        .find((x) => x.id === property)
        ?.attributeSelectOptions.find((x) => x.id === value)?.value;

      if (dimensionValue) return dimensionValue;

      return value;
    },
  },
};
</script>
