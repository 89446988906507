<script>
import {
  currencyFormatter,
  formatEmissions,
  formatKgToTonEmissions,
  integerNumberFormatter,
} from "../util/formatters";

export default {
  methods: {
    formatCurrency(value) {
      if (isNaN(value)) {
        return "-";
      }
      return currencyFormatter.format(value);
    },
    formatInteger(value) {
      if (isNaN(value)) {
        return "-";
      }

      return integerNumberFormatter.format(value);
    },
    formatEmissions(value) {
      const emissions = formatEmissions(value);
      emissions.unit = this.$t(`common.units.${emissions.unit}`);
      return emissions;
    },
    formatKgToTonEmissions(value) {
      const emissions = formatKgToTonEmissions(value);
      emissions.unit = this.$t(`common.units.${emissions.unit}`);
      return emissions;
    },
  },
};
</script>
