<template>
  <div class="px-8">
    <v-row>
      <v-textarea
        :value="apiToken"
        hide-details="auto"
        :label="this.$t('components.onboarding.stepFive.apiToken')"
        disabled
      ></v-textarea>
    </v-row>
    <v-row>
      <v-btn class="mt-2" @click="copyApiToken()">{{
        $t("components.onboarding.stepFive.copyApiToken")
      }}</v-btn>
      <p v-if="showCopied" class="ma-2">
        {{ $t("components.onboarding.stepFive.copiedToClipboard") }}
      </p>
    </v-row>
    <v-row>
      <p class="mt-2">
        {{ helpText }}
        <a target="_blank" href="https://verarca.dk/erp-partners">
          {{ linkText }}
        </a>
      </p>
    </v-row>
    <v-row> </v-row>
  </div>
</template>
<script>
import { ekomplet } from "@/util/integrations";

export default {
  components: {},
  data() {
    return {
      showCopied: false,
    };
  },
  props: {
    selectedIntegration: String,
    apiToken: String,
  },
  methods: {
    copyApiToken() {
      navigator.clipboard
        .writeText(this.apiToken)
        .then(() => (this.showCopied = true));
    },
  },
  computed: {
    helpText() {
      if (this.selectedIntegration === ekomplet) {
        return this.$t(
          "components.onboarding.stepFive.ekomplet.partnerHelpText"
        );
      }

      return this.$t("components.onboarding.stepFive.partnerHelpText");
    },
    linkText() {
      return this.$t("components.onboarding.stepFive.findPartnerLinkText");
    },
  },
};
</script>
<style type="scss">
textarea {
  resize: none;
}
</style>
