<template>
  <div>
    <!--- Front page --->
    <div class="print-page d-flex flex-column">
      <div class="d-flex align-center justify-end">
        <ReportCoverBlob height="994px" width="1323px"></ReportCoverBlob>
      </div>
      <div style="position: absolute; top: 80px; left: 80px">
        <PoweredByVerarca></PoweredByVerarca>
      </div>
      <div
        class="cover-content d-flex flex-column align-start justify-end flex-grow-1"
      >
        <p class="report-title mb-2">
          {{ $t("pages.reports.index.carbonEmissionReport") }}
        </p>
        <p class="company-name mb-12">
          {{ organization.name }}
        </p>
        <div
          class="report-date-range d-flex flex-row align-center justify-start border-right"
        >
          {{ formattedDateRange.from }}
          <ArrowRightIcon
            class="mx-4"
            width="20px"
            height="20px"
          ></ArrowRightIcon>
          {{ formattedDateRange.to }}
        </div>
        <div v-if="partner" class="partner-info d-flex flex-row mt-14 mr-10">
          <div class="flex-grow-1 d-flex flex-row align-center">
            <!--
                This is not currently possible with html2canvas due to cors (https://stackoverflow.com/questions/70887855/how-to-handle-downloading-external-images-using-html2canvas)
               
                <div class="mr-6">
            <img
                class="rounded-circle"
                height="65px"
                width="65px"
                style="background: red"
                :src="profilePictureUrl(partner.name)"
              />
            </div> -->
            <div class="">
              <div class="company">{{ partner.name }}</div>
              <div class="address">{{ partner.address }}</div>
            </div>
          </div>
          <div v-if="partner.phoneNumber" class="d-flex flex-row mr-12">
            <v-icon class="mr-2 align-start" color="#f25d3b" size="22px"
              >mdi-phone</v-icon
            >
            <div class="contact-info">
              <div class="label">
                {{ $t("pages.reports.index.phoneNumber") }}
              </div>
              <div class="value">{{ partner.phoneNumber }}</div>
            </div>
          </div>
          <div v-if="partner.email" class="d-flex flex-row pr-8">
            <v-icon class="mr-2 align-start" color="#f25d3b" size="22px"
              >mdi-email</v-icon
            >
            <div class="contact-info">
              <div class="label">{{ $t("pages.reports.index.email") }}</div>
              <div class="value">{{ partner.email }}</div>
            </div>
          </div>
        </div>
      </div>
      <!--<div
        style="position: absolute; bottom: 0; left: 0; right: 0"
        class="d-flex align-center justify-center"
      >
        <ReportTitleGraphics width="1313px" height="371px" />
      </div>-->
    </div>
    <!--- page 1 --->
    <div class="print-page d-flex">
      <!-- header -->
      <ReportPageHeader
        :formattedDateRange="formattedDateRange"
        :organization="organization"
        :partner="partner"
      ></ReportPageHeader>
      <CarbonFootprintOverview
        :dateRange="dateRange"
        :organization="organization"
        v-bind="data"
        :defaultDateFormat="defaultDateFormat"
      ></CarbonFootprintOverview>
      <ReportPageFooter
        :pageNumber="1"
        :totalNumberOfPages="totalNumberOfPages"
      ></ReportPageFooter>
    </div>
    <!--- page 2 --->
    <div class="print-page d-flex">
      <!-- header -->
      <ReportPageHeader
        :formattedDateRange="formattedDateRange"
        :organization="organization"
        :partner="partner"
      ></ReportPageHeader>
      <TotalGhgAndScopes
        :dateRange="dateRange"
        v-bind="data"
      ></TotalGhgAndScopes>
      <ReportPageFooter
        :pageNumber="2"
        :totalNumberOfPages="totalNumberOfPages"
      ></ReportPageFooter>
    </div>
    <!--- page 3 --->
    <div class="print-page d-flex">
      <!-- header -->
      <ReportPageHeader
        :formattedDateRange="formattedDateRange"
        :organization="organization"
        :partner="partner"
      ></ReportPageHeader>
      <EmissionsByGhgs :dateRange="dateRange" v-bind="data"></EmissionsByGhgs>
      <ReportPageFooter
        :pageNumber="3"
        :totalNumberOfPages="totalNumberOfPages"
      ></ReportPageFooter>
    </div>
    <div class="print-page d-flex" v-if="data.includeStatistics">
      <!-- header -->
      <ReportPageHeader
        :formattedDateRange="formattedDateRange"
        :organization="organization"
        :partner="partner"
      ></ReportPageHeader>
      <ReportStatistics v-bind="data"></ReportStatistics>
      <ReportPageFooter
        :pageNumber="
          totalNumberOfPages -
          (4 +
            (data.includeEmissionFactorsAppendix &&
            data.emissionFactorsAppendixPageData
              ? Math.ceil(data.emissionFactorsAppendixPageData?.length / 20)
              : 0))
        "
        :totalNumberOfPages="totalNumberOfPages"
      ></ReportPageFooter>
    </div>
    <div class="print-page d-flex" v-if="data.includeStatistics">
      <!-- header -->
      <ReportPageHeader
        :formattedDateRange="formattedDateRange"
        :organization="organization"
        :partner="partner"
      ></ReportPageHeader>
      <ReportEmissionCharacteristics
        v-bind="data"
      ></ReportEmissionCharacteristics>
      <ReportPageFooter
        :pageNumber="
          totalNumberOfPages -
          (3 +
            (data.includeEmissionFactorsAppendix &&
            data.emissionFactorsAppendixPageData
              ? Math.ceil(data.emissionFactorsAppendixPageData?.length / 20)
              : 0))
        "
        :totalNumberOfPages="totalNumberOfPages"
      ></ReportPageFooter>
    </div>
    <template
      v-if="
        data.includeEmissionFactorsAppendix &&
        data.emissionFactorsAppendixPageData
      "
    >
      <div
        v-for="(element, index) in Array(
          Math.ceil(data.emissionFactorsAppendixPageData?.length / 20)
        )"
        :key="index"
        class="print-page d-flex"
      >
        <!-- header -->
        <ReportPageHeader
          :formattedDateRange="formattedDateRange"
          :organization="organization"
          :partner="partner"
        ></ReportPageHeader>
        <ReportEmissionTypesAppendix
          :emissionFactorsAppendixPageData="
            data.emissionFactorsAppendixPageData?.slice(
              index * 20,
              index * 20 + 20 // 20 per page
            )
          "
        ></ReportEmissionTypesAppendix>
        <ReportPageFooter
          :pageNumber="
            totalNumberOfPages -
            (2 + Math.ceil(data.emissionFactorsAppendixPageData.length / 20)) +
            index
          "
          :totalNumberOfPages="totalNumberOfPages"
        ></ReportPageFooter>
      </div>
    </template>
    <!-- Appendix -->
    <div class="print-page d-flex">
      <!-- header -->
      <ReportPageHeader
        :formattedDateRange="formattedDateRange"
        :organization="organization"
        :partner="partner"
      ></ReportPageHeader>
      <ReportAppendix1></ReportAppendix1>
      <ReportPageFooter
        :pageNumber="totalNumberOfPages - 2"
        :totalNumberOfPages="totalNumberOfPages"
      ></ReportPageFooter>
    </div>
    <div class="print-page d-flex">
      <!-- header -->
      <ReportPageHeader
        :formattedDateRange="formattedDateRange"
        :organization="organization"
        :partner="partner"
      ></ReportPageHeader>
      <ReportAppendix2></ReportAppendix2>
      <ReportPageFooter
        :pageNumber="totalNumberOfPages - 1"
        :totalNumberOfPages="totalNumberOfPages"
      ></ReportPageFooter>
    </div>
    <div class="print-page d-flex">
      <!-- header -->
      <ReportPageHeader
        :formattedDateRange="formattedDateRange"
        :organization="organization"
        :partner="partner"
      ></ReportPageHeader>
      <ReportAppendix3></ReportAppendix3>
      <ReportPageFooter
        :pageNumber="totalNumberOfPages"
        :totalNumberOfPages="totalNumberOfPages"
      ></ReportPageFooter>
    </div>
    <div class="print-page d-flex">
      <div class="d-flex align-center justify-center flex-grow-1">
        <VerarcaLargeLogo></VerarcaLargeLogo>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from "../../assets/svg/arrow-right-dark-20px.svg";
import ReportCoverBlob from "../../assets/svg/report-cover-blob.svg";
import PoweredByVerarca from "../../assets/svg/powered-by-verarca.svg";
import VerarcaLargeLogo from "../../assets/svg/verarca-large-logo.svg";

import CarbonFootprintOverview from "./CarbonFootprintOverview.vue";
import TotalGhgAndScopes from "./TotalGhgAndScopes.vue";
import EmissionsByGhgs from "./EmissionsByGhgs.vue";
import ReportAppendix1 from "./ReportAppendix1.vue";
import ReportAppendix2 from "./ReportAppendix2.vue";
import ReportAppendix3 from "./ReportAppendix3.vue";
import ReportStatistics from "./ReportStatistics.vue";
import ReportEmissionTypesAppendix from "./ReportEmissionTypesAppendix.vue";
import ReportEmissionCharacteristics from "./ReportEmissionCharacteristics.vue";
import ReportPageHeader from "./ReportPageHeader.vue";
import ReportPageFooter from "./ReportPageFooter.vue";

export default {
  components: {
    ArrowRightIcon,
    ReportCoverBlob,
    PoweredByVerarca,
    CarbonFootprintOverview,
    TotalGhgAndScopes,
    EmissionsByGhgs,
    ReportAppendix1,
    ReportAppendix2,
    ReportAppendix3,
    VerarcaLargeLogo,
    ReportStatistics,
    ReportEmissionTypesAppendix,
    ReportEmissionCharacteristics,
    ReportPageHeader,
    ReportPageFooter,
  },
  props: {
    organization: Object,
    dateRange: { type: Object, default: () => ({ from: "", to: "" }) },
    data: {
      type: Object,
      default: () => ({ emissionFactorsAppendixPageData: [] }),
    },
    partner: {
      type: Object,
      default: null,
    },
    totalNumberOfPages: Number,
    defaultDateFormat: String,
  },
  data() {
    return {
      companyName: "",
    };
  },
  computed: {
    formattedDateRange() {
      const formattedFrom = this.formatDate(this.dateRange?.from);
      const formattedTo = this.formatDate(this.dateRange?.to);
      return { from: formattedFrom, to: formattedTo };
    },
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      if (this.defaultDateFormat === "YYYY-MM-DD") {
        return `${year}-${month}-${day}`;
      }
      if (this.defaultDateFormat === "DD-MM-YYYY") {
        return `${day}-${month}-${year}`;
      }
    },
    profilePictureUrl(name) {
      if (name) {
        const nameParts = name.split(" ");
        const initials = nameParts.map((name) => name.charAt(0)).join("");
        const initialsUppercase = initials.toUpperCase();

        return (
          "https://ui-avatars.com/api/?name=" +
          initialsUppercase +
          "&color=fff&background=F25D3B"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.print-page {
  position: relative;
  width: 1684px;
  height: calc(1684px / 1.4142135624); //1190.7678195181px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #bfbfbf;

  .cover-content {
    margin-bottom: 120px !important;
    margin-left: 120px;
    margin-right: 50px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
  }

  .report-title {
    color: #b4b4b4;
    font-size: 60px;
  }

  .company-name {
    color: #333333;
    font-size: 90px;
    text-wrap: wrap;
    font-weight: 500;
  }

  .report-date-range {
    color: #686868;
    font-size: 28px;
    path {
      fill: #686868;
    }
  }
}

.partner-info {
  width: 100%;

  font-size: 24px;

  .company {
    font-weight: 400;
  }
  .address {
    color: #b4b4b4;
    font-size: 18px;
  }
  .contact-info {
    .label {
      color: #b4b4b4;
      font-size: 18px;
    }
    .value {
      font-weight: 400;
    }
  }
}
</style>
