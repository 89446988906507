<template>
  <v-card-text class="main-section px-6 pb-0 pt-0">
    <v-container class="pa-0" :fluid="fluid">
      <v-row :dense="dense">
        <v-col cols="12">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
export default {
  props: {
    dense: Boolean,
    fluid: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  border-bottom: 1px solid #e8e8e8;
}
</style>
