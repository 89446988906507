<template>
  <div>
    <v-btn
      class="text-none font-weight-medium"
      style="color: #686868"
      outlined
      tile
      large
      @click="() => (show = !show)"
    >
      <slot name="trigger-button"></slot>
    </v-btn>
    <dialog-form
      :showDialog="show"
      @showStateChanged="(state) => (show = state)"
    >
      <dialog-form-header
        :title="$t('components.attributes.filters.title')"
      ></dialog-form-header>
      <v-list class="py-0">
        <v-list-item class="filter-item list-header px-6">
          <div class="filter-name">
            {{ $t("components.attributes.filters.quickFilters.quickFilters") }}
          </div>
          <!--<div class="filter-actions">Actions</div>-->
        </v-list-item>
        <v-list-item
          v-for="(filter, index) in quickFilters"
          :key="index"
          class="filter-item px-6"
          @click="toggleQuickFilter(filter.quickFilterId)"
        >
          <div
            class="filter-name"
            :class="{ selected: hasFilterSelected(filter.quickFilterId) }"
          >
            <span class="mr-2">{{ filter.name }}</span>
            <v-icon
              v-if="hasFilterSelected(filter.quickFilterId)"
              color="#F25D3B"
              size="20px"
              >mdi-check</v-icon
            >
          </div>
          <div class="filter-actions">
            <!-- Add action menu here later if necessary -->
          </div>
        </v-list-item>
      </v-list>
      <dialog-form-section-one-col>
        <v-row
          v-for="(filter, index) in updatedFilters"
          :key="index"
          class="black--text"
        >
          <v-col>
            <div>
              {{
                $t("components.accountingEntries.listFilter.fields.field.label")
              }}
            </div>
            <v-autocomplete
              class="rounded-0 mb-4"
              style="cursor: pointer"
              background-color="#F7F7F7"
              flat
              :label="
                $t(
                  'components.accountingEntries.listFilter.fields.field.inputLabel'
                )
              "
              single-line
              filled
              light
              :menu-props="{ top: false, offsetY: true }"
              color="red"
              item-color="red"
              :hide-details="true"
              dense
              v-model="filter.propertyName"
              :items="availableEntryFields"
              :placeholder="
                $t(
                  'components.accountingEntries.listFilter.fields.field.placeholder'
                )
              "
              hide-selected
              item-text="text"
              item-value="value"
              solo
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <div>
              {{
                $t(
                  "components.accountingEntries.listFilter.fields.filter.label"
                )
              }}
            </div>
            <v-select
              style="cursor: pointer"
              background-color="#F7F7F7"
              flat
              :label="
                $t(
                  'components.accountingEntries.listFilter.fields.filter.inputLabel'
                )
              "
              single-line
              filled
              light
              :menu-props="{ top: false, offsetY: true }"
              color="red"
              item-color="red"
              :hide-details="true"
              dense
              v-model="filter.filterType"
              :items="filterOptions"
              :placeholder="
                $t(
                  'components.accountingEntries.listFilter.fields.filter.placeholder'
                )
              "
              item-value="value"
              solo
              clearable
              :disabled="sortOnlyFields.includes(filter.propertyName)"
            >
              <template v-slot:item="{ item }">
                <v-icon class="mr-2">{{ item.icon }}</v-icon>
                <p class="mb-0">{{ item.text }}</p>
              </template>
              <template v-slot:selection="{ item }">
                <v-icon class="mr-2">{{ item.icon }}</v-icon>
                <p class="mb-0">{{ item.text }}</p>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <div>
              {{ $t("components.attributes.filters.fields.filterBy") }}
            </div>
            <v-text-field
              class="rounded-0 mb-4"
              background-color="#F7F7F7"
              solo
              flat
              single-line
              filled
              type="text"
              v-model="filter.filterText"
              dense
              :disabled="sortOnlyFields.includes(filter.propertyName)"
            />
          </v-col>

          <v-col>
            <div>
              {{ $t("components.attributes.filters.fields.sorting") }}
            </div>
            <v-select
              style="cursor: pointer"
              background-color="#F7F7F7"
              flat
              label="Select region"
              single-line
              filled
              light
              :menu-props="{ top: false, offsetY: true }"
              color="red"
              item-color="red"
              :hide-details="true"
              dense
              v-model="filter.sortDesc"
              :items="sortingOptions"
              item-text="text"
              item-value="value"
              solo
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="filter-name">
          <v-col>{{
            $t("components.attributes.filters.appliedFilters")
          }}</v-col>

          <v-btn color="blue darken-1" text @click="addFilter()">
            <v-icon class="red--text">mdi-plus</v-icon>
            <p class="red--text mb-0">
              {{ $t("components.attributes.filters.addFilter") }}
            </p>
          </v-btn>
        </v-row>

        <v-col
          v-for="(filter, index) in appliedFilters"
          :key="index + '-applied'"
          class="black--text filter-item py-2"
        >
          <v-row justify="space-between" class="px-2">
            <v-col>
              {{ filterName(filter.propertyName) }}
            </v-col>
            <v-col v-if="filter?.filterType">
              <v-icon class="black--text">{{
                filterOptions.find((x) => filter.filterType == x.value).icon
              }}</v-icon>
              {{ filterOptions.find((x) => filter.filterType == x.value).text }}
            </v-col>
            <v-col v-else />
            <v-col>
              {{ filter.filterText }}
            </v-col>
            <v-col>
              {{ sortingOptions.find((x) => x.value == filter.sortDesc).text }}
            </v-col>

            <v-btn
              class="align-self-center"
              icon
              @click="removeAppliedFilter(filter.id)"
            >
              <v-icon class="black--text">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </dialog-form-section-one-col>
      <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 px-6 align-self-stretch"
          color="#686868"
          dark
          elevation="0"
          tile
          x-large
          text
          @click="removeAllFilters()"
        >
          <v-icon color="#686868">mdi-trash-can-outline</v-icon>
          <p class="mb-0">
            {{ $t("components.attributes.filters.removeAllFilters") }}
          </p>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch"
          color="#686868"
          elevation="0"
          tile
          dark
          x-large
          text
          @click="show = false"
        >
          <v-icon color="#686868">mdi-close</v-icon>
          <p class="mb-0">
            {{ $t("common.actions.close") }}
          </p>
        </v-btn>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
          color="#266663"
          dark
          elevation="0"
          tile
          x-large
          @click="applyFilters()"
          :disabled="loading"
        >
          <v-icon class="white--text">mdi-content-save-outline</v-icon>
          <p class="white--text mb-0">
            {{ $t("common.actions.apply") }}
          </p>
        </v-btn>
      </v-card-actions>
    </dialog-form>
  </div>
</template>
<script>
import DialogForm from "../Dialog/DialogForm.vue";
import DialogFormHeader from "../Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../Dialog/DialogFormSectionOneCol.vue";

export default {
  components: { DialogForm, DialogFormSectionOneCol, DialogFormHeader },
  props: {
    value: Boolean,
    filters: Array,
    filterFields: Array,
    sortOnlyFields: Array,
    quickFilters: Array,
    hiddenFields: Array,
  },
  data() {
    return {
      loading: false,
      appliedFilters: this.filters,
      updatedFilters: [],
      selectedQuickFilters: [],
      selectedFilters: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    availableEntryFields() {
      return this.filterFields
        .filter((filter) => !this.hiddenFields.includes(filter))
        .map((filter) => {
          return {
            text: this.$t("components.accountingEntries.fields." + filter),
            value: filter,
          };
        });
    },
    sortingOptions() {
      return [
        {
          text: this.$t("common.sorting.ascending"),
          value: false,
        },
        {
          text: this.$t("common.sorting.descending"),
          value: true,
        },
        {
          text: this.$t("common.sorting.noSorting"),
          value: null,
        },
      ];
    },
    filterOptions() {
      return [
        {
          text: this.$t("common.filters.include"),
          value: "Include",
          icon: "mdi-alphabetical-variant",
        },
        {
          text: this.$t("common.filters.notInclude"),
          value: "NotInclude",
          icon: "mdi-alphabetical-variant-off",
        },
        {
          text: this.$t("common.filters.startsWith"),
          value: "Starts",
          icon: "mdi-format-letter-starts-with",
        },
        {
          text: this.$t("common.filters.endsWith"),
          value: "Ends",
          icon: "mdi-format-letter-ends-with",
        },
        {
          text: this.$t("common.filters.equalTo"),
          value: "Equal",
          icon: "mdi-equal",
        },
        {
          text: this.$t("common.filters.notEqualTo"),
          value: "NotEqual",
          icon: "mdi-not-equal-variant",
        },
        {
          text: "",
          value: "Range",
          icon: "",
        },
      ];
    },
  },
  methods: {
    filterName(filter) {
      return this.$t("components.accountingEntries.fields." + filter);
    },
    removeAppliedFilter(filterId) {
      const quickFilterId = this.appliedFilters.find(
        (filter) => filter.id === filterId
      )?.quickFilterId;

      if (quickFilterId) {
        this.selectedQuickFilters = this.selectedQuickFilters.filter(
          (filter) => filter !== quickFilterId
        );
      }

      this.appliedFilters = this.appliedFilters.filter(
        (filter) => filter.id !== filterId
      );
    },
    removeAllFilters() {
      // Remove all filters
      this.updatedFilters = [];
      this.appliedFilters = [];
      this.selectedQuickFilters = [];
    },
    addFilter() {
      this.updatedFilters.push({
        sortDesc: null,
      });
    },
    applyFilters() {
      const selectedQuickFilters = this.quickFilters.filter((quickFilter) => {
        if (
          this.appliedFilters.find(
            (filter) => filter.quickFilterId === quickFilter.quickFilterId
          )
        ) {
          return false;
        }
        return this.selectedQuickFilters.includes(quickFilter.quickFilterId);
      });

      this.$emit("apply-filters", [
        ...this.updatedFilters,
        ...this.appliedFilters,
        ...selectedQuickFilters,
      ]);
      this.updatedFilters = [];
      this.show = false;
    },
    toggleQuickFilter(quickFilterId) {
      if (this.hasFilterSelected(quickFilterId)) {
        this.selectedQuickFilters = this.selectedQuickFilters.filter(
          (x) => x !== quickFilterId
        );
        // Remove already applied quick filters that match
        this.appliedFilters = this.appliedFilters.filter(
          (f) => f.quickFilterId !== quickFilterId
        );
      } else {
        this.selectedQuickFilters.push(quickFilterId);
      }
    },
    hasFilterSelected(filterId) {
      return this.selectedQuickFilters.includes(filterId);
    },
  },
  watch: {
    filters(to) {
      this.appliedFilters = to;
    },
    show(to) {
      // reset applied filters to avoid confusion when dismissing
      // the modal and reopening after removing applied filters
      if (to) {
        this.appliedFilters = this.filters;
        this.selectedQuickFilters = this.quickFilters
          .filter((filter) =>
            this.filters.find((f) => f.quickFilterId === filter.quickFilterId)
          )
          .map((f) => f.quickFilterId);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-item {
  border-bottom: 1px solid #e8e8e8;
  display: flex;

  &.list-header {
    font-size: 12px;
    .filter-name,
    .filter-actions {
      color: #b4b4b4;
    }
  }

  .selected {
    color: #f25d3b;
  }

  .filter-name {
    flex: 1;
    font-weight: 500;
  }
}
.solo-item-list-area {
  height: 30vh;
  overflow-y: auto;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
