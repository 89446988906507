<template>
  <div class="d-flex flex-row align-center pl-4 invoice-list-item">
    <PdfIcon></PdfIcon>
    <v-btn
      class="invoice-btn flex-grow-1 mb-0 ml-0 px-2 justify-start text-none font-weight-regular"
      :href="
        route('api.files.content', {
          id: file.id,
          filename: file.originalName,
        })
      "
      target="_blank"
      left
      plain
      >{{ file.originalName }}</v-btn
    >
    <v-btn
      v-if="!readonly"
      @click="$emit('remove', file.id)"
      class="mr-1"
      icon
      plain
    >
      <v-icon size="20" color="#686868">mdi-close</v-icon>
    </v-btn>
  </div>
</template>
<script>
import PdfIcon from "../../assets/svg/pdf.svg";

export default {
  components: { PdfIcon },
  emits: ["remove"],
  props: {
    file: Object,
    readonly: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.invoice-list-item {
  background: #fef2ef;
  border: 1px solid #fef2ef;
  .invoice-btn {
    color: #686868;
    flex: 1;
    overflow: hidden;
    letter-spacing: 0;
  }
  &:hover {
    border-color: #f25d3b;
    .invoice-btn * {
      color: #f25d3b;
    }
  }
}
</style>