<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-card
      elevation="0"
      min-height="17.625rem"
      class="d-flex flex-column ma-4"
    >
      <v-card-text class="pa-0 flex-grow-1">
        <v-tabs
          v-model="tab"
          class="mb-4"
          style="border-bottom: 1px solid #e8e8e8"
          show-arrows
        >
          <v-tab
            v-for="contentTab in tabs"
            :key="contentTab.id"
            :href="`#${getTabHref(contentTab.id)}`"
            @change="setTabInUrl(contentTab.id)"
          >
            {{ contentTab.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="fill-height fill-width px-0 pb-4">
          <tab-rules v-bind="rulesBindings" @setTab="setTabInUrl" />
          <tab-factor-sources
            v-bind="factorSourcesBindings"
            @setTab="setTabInUrl"
          ></tab-factor-sources>
          <tab-custom-emission-factors
            v-bind="customEmissionFactorsBindings"
            ref="customEmissionFactorsTab"
            @setTab="setTabInUrl"
            :currencies="currencies"
            :attributes="attributes"
            :defaultRegionId="settings?.defaultRegionId"
          ></tab-custom-emission-factors>
          <tab-intensity-figures
            v-bind="intensityFiguresBindings"
            @setTab="setTabInUrl"
          />
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="!isCardActionsHidden">
        <v-spacer></v-spacer>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
          large
          color="#F25D3B"
          dark
          elevation="0"
          tile
          x-large
          :loading="form.processing"
          @click="submit"
        >
          {{ $t("common.actions.saveChanges") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import qs from "qs";
import { appLayout } from "@/util/layout";
import { serialize } from "object-to-formdata";
import TabRules from "./AssetSettingsTabs/TabRules.vue";
import TabFactorSources from "./AssetSettingsTabs/TabFactorSources.vue";
import TabCustomEmissionFactors from "./AssetSettingsTabs/TabCustomEmissionFactors.vue";
import TabIntensityFigures from "./AssetSettingsTabs/TabIntensityFigures.vue";
import Formatters from "../../mixins/Formatters.vue";

export default {
  layout: appLayout({
    title: "pages.assets.assetSettingsIndex.title",
    pushContent: false,
  }),
  mixins: [Formatters],
  components: {
    TabRules,
    TabFactorSources,
    TabCustomEmissionFactors,
    TabIntensityFigures,
  },
  props: {
    settings: Object,
    currencies: Array,
    organization: Object,
    fiscalYears: Array,
    assetRules: Object,
    assetRuleTriggerProperties: Array,
    assetRuleEntryTriggerProperties: Array,
    integrations: Object,
    integrationSyncPeriods: Array,
    installedIntegration: Object,
    integrationError: String,
    financialAccounts: Array,
    customEmissionFactors: Object,
    attributes: Array,
    companies: Array,
    showCompanies: Boolean,
    dynamicsInitializedIntegrationId: String,
    payment: Object,
    billingSubscription: Object,
    billingPaymentMethods: Array,
    billingCustomers: Array,
    billingTransactions: Array,
    billingPaymentIntents: Array,
    billingLimits: Object,
    publishableKey: String,
    paymentReturnUrl: String,
    paymentTierIds: Object,
    isFreePlan: Boolean,
    isOrganizationGroup: Boolean,
    discountPercentage: Number,
    economicResetTimeoutMinutes: Number,
    dynamicsResetTimeoutMinutes: Number,
    unicontaResetTimeoutMinutes: Number,
    bc365RedirectToOnboarding: Boolean,
    dateFormats: Array,
    ghgCategories: Array,
    factorSources: Array,
    intensityFigures: Array,
    emissionSectors: Array,
    emissionCategories: Array,
  },
  data() {
    return {
      form: this.$inertia.form(this.generateInitialForm()),
      tab: window.location.hash?.substring(1) ?? null,
      emissionRegions: [],
    };
  },
  mounted() {
    this.getEmissionRegions();
  },
  computed: {
    rulesBindings() {
      return {
        tabHref: this.getTabHref(6),
        tabId: 6,
        assetRules: this.assetRules,
        assetRuleTriggerProperties: this.assetRuleTriggerProperties,
        assetRuleEntryTriggerProperties: this.assetRuleEntryTriggerProperties,
        currencies: this.currencies,
        settings: this.settings,
        ghgCategories: this.ghgCategories,
        attributes: this.attributes,
      };
    },
    factorSourcesBindings() {
      return {
        tabHref: this.getTabHref(10),
        tabId: 10,
        factorSources: this.factorSources,
      };
    },
    customEmissionFactorsBindings() {
      var modifiedCustomEmissionFactors = {
        ...this.customEmissionFactors,
        data: this.customEmissionFactors.data.map((item) => {
          let kgCO2e = item.kgCo2eAr6;

          if (!kgCO2e) kgCO2e = item.kgCo2eAr5;
          if (!kgCO2e) kgCO2e = item.kgCo2eAr4;
          if (!kgCO2e) kgCO2e = 0;

          return {
            ...item,
            id: item.id, // change to verarca id
            sectorCategoryRegion:
              item.sector.name +
              "/" +
              item.category.name +
              "/" +
              item.region.name,
            emissionFactor:
              this.formatCurrency(kgCO2e) +
              "" +
              this.$t("common.units.kgCO2e") +
              "/" +
              item.activityUnit,
          };
        }),
      };

      return {
        tabHref: this.getTabHref(7),
        tabId: 7,
        customEmissionFactors: modifiedCustomEmissionFactors,
      };
    },
    intensityFiguresBindings() {
      return {
        tabHref: this.getTabHref(11),
        tabId: 11,
        intensityFigures: this.intensityFigures,
        attributes: this.attributes,
        ghgCategories: this.ghgCategories,
        emissionSectors: this.emissionSectors,
        emissionCategories: this.emissionCategories,
        integrations: this.integrations,
        financialAccounts: this.financialAccounts,
        settings: this.settings,
      };
    },
    isCardActionsHidden() {
      switch (this.tab) {
        case this.getTabHref(5):
        case this.getTabHref(6):
        case this.getTabHref(7):
        case this.getTabHref(8):
        case this.getTabHref(9):
        case this.getTabHref(10):
        case this.getTabHref(11):
          return true;
        default:
          return false;
      }
    },
    tabs() {
      let tabs = [
        {
          id: 6,
          name: this.$t("pages.settings.index.tabs.rules"),
        },
        {
          id: 7,
          name: this.$t("pages.settings.index.tabs.customEmissionFactors"),
        },
        {
          id: 10,
          name: this.$t("pages.settings.index.tabs.factorSources"),
        },
        {
          id: 11,
          name: this.$t("pages.settings.index.tabs.intensityFigures"),
        },
      ];

      if (this.moduleFixedAssetsEnabled) {
        tabs.push({
          id: 4,
          name: this.$t("pages.settings.index.tabs.fixedAssets"),
        });
      }

      if (this.isOrganizationGroup) {
        tabs = tabs.filter((x) => [6, 7].includes(x.id));
      }

      return tabs;
    },
    moduleFixedAssetsEnabled() {
      return this.$inertia.page.props.auth.settings.moduleFixedAssets;
    },
  },
  methods: {
    submit() {
      if (this.tab === "general") {
        this.$refs.generalTab.submit();
      } else if (this.tab === "integrations") {
        this.$refs.integrationsTab.submit();
      } else {
        this.form
          .transform((data) =>
            serialize(data, { noFilesWithArrayNotation: true })
          )
          .put(this.route("settings.update"), {
            onSuccess: () => {
              this.form = this.$inertia.form(this.generateInitialForm());

              if (this.tab) window.location.hash = `#${this.tab}`;
            },
          });
      }
    },
    reloadSettings(updatedSettings) {
      this.form.defaultCurrencyId = updatedSettings.defaultCurrencyId;
      this.form.defaultRegionId = updatedSettings.defaultRegionId;
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    generateInitialForm() {
      return {
        defaultCurrencyId: this.settings?.defaultCurrencyId,
        fiscalYear: this.settings?.fiscalYear,
        defaultRegionId: this.settings?.defaultRegionId,
        defaultDateFormat: this.settings?.defaultDateFormat,
      };
    },
    getTabHref(tabId) {
      switch (tabId) {
        case 6:
          return "rules";
        case 7:
          return "custom-emission-factors";
        case 10:
          return "factor-sources";
        case 11:
          return "intensity-figures";
      }
    },
    setTabInUrl(tabId) {
      const tabHref = this.getTabHref(tabId);

      if (!tabHref) return;

      const existingQuery = qs.parse(window.location.search.substring(1));

      const newUrl = this.route("assets.more", existingQuery) + `#${tabHref}`;

      window.history.replaceState(null, "", newUrl);
    },
    getEmissionRegions() {
      fetch(this.route("api.emission-regions.index"))
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data.map((x) => ({
            ...x,
            value: x.countryName ? x.countryName : x.name,
          }));
        });
    },
  },
  watch: {
    settings(value) {
      this.reloadSettings(value);
    },
  },
};
</script>
