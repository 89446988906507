<template>
  <div>
    <div v-if="logicOperator">
      <section-divider
        :text="logicOperator"
        :showOperatorDelete="showOperatorDelete"
        @delete-rule="$emit('deleteTrigger')"
      />
    </div>
    <v-row class="mx-0">
      <v-col class="px-0 py-0">
        <div class="d-flex flex-row align-center">
          <p class="text-caption grey--text text--lighten-1 my-2 flex-grow-1">
            {{ $t("pages.settings.assetRuleForm.stepOne.if") }}
          </p>
        </div>
        <v-autocomplete
          :value="propertyName"
          :items="assetRuleTriggerProperties"
          :menu-props="{ top: false, offsetY: true }"
          class="rounded-0"
          item-color="#F25D3B"
          color="#F25D3B"
          background-color="#F7F7F7"
          hide-details
          hide-selected
          solo
          flat
          filled
          light
          @input="$emit('propertyNameChanged', $event)"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col class="px-0 py-0">
        <div class="d-flex flex-row align-center">
          <p class="text-caption grey--text text--lighten-1 my-2 flex-grow-1">
            {{ $t("pages.settings.assetRuleForm.stepOne.isEqualTo") }}
          </p>
        </div>
        <v-text-field
          v-if="!dimensionAttribute"
          :value="value"
          class="rounded-0"
          item-color="#F25D3B"
          color="#F25D3B"
          background-color="#F7F7F7"
          hide-details
          solo
          flat
          filled
          light
          clearable
          @input="$emit('valueChanged', $event)"
        ></v-text-field>
        <v-autocomplete
          v-else
          :value="value"
          :items="dimensionAttribute.attributeSelectOptions"
          item-text="value"
          item-value="id"
          class="rounded-0"
          item-color="#F25D3B"
          color="#F25D3B"
          background-color="#F7F7F7"
          hide-details
          hide-selected
          solo
          flat
          filled
          light
          @input="$emit('valueChanged', $event)"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionDivider from "./SectionDivider.vue";

export default {
  components: { SectionDivider },
  props: {
    propertyName: String,
    value: String,
    logicOperator: String,
    assetRuleTriggerProperties: Array,
    attributes: Array,
    showOperatorDelete: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["propertyNameChanged", "valueChanged", "deleteTrigger"],
  computed: {
    dimensionAttribute() {
      return this.attributes.find((x) => x.id === this.propertyName) ?? null;
    },
  },
  watch: {
    dimensionAttribute() {
      this.$emit("valueChanged", "");
    },
  },
};
</script>
<style scoped>
.reverse-checkbox {
  width: 150px;
}

.reverse-checkbox:deep(.v-input__slot) {
  flex-direction: row-reverse;
}
</style>