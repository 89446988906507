<template>
  <dialog-form
    :show-dialog="show"
    @showStateChanged="show = $event"
    max-width="1000px"
    persistent
  >
    <dialog-form-header
      :title="$t('pages.settings.intensityFigures.form.title')"
      :showDismiss="true"
      @onDismiss="show = false"
      style="z-index: 10"
    ></dialog-form-header>
    <div
      ref="scrollable-area"
      style="height: calc(81vh - 175px); overflow-y: scroll"
    >
      <dialog-form-section-one-col>
        <dialog-form-text-input
          :title="
            $t('pages.settings.intensityFigures.form.intensityFigureName')
          "
          :value="formData?.name"
          @input="(val) => (formData.name = val)"
          :errorMessages="formErrors?.name"
          :hideDetails="false"
          :readonly="isStandardFigure"
        ></dialog-form-text-input>
      </dialog-form-section-one-col>
      <dialog-form-section-one-col>
        <v-row dense>
          <v-col></v-col>
          <v-col>
            <v-checkbox
              v-model="specificRange"
              :label="$t('pages.settings.intensityFigures.form.specificRange')"
              class="mt-0 ml-1"
              hide-details
              :readonly="isStandardFigure"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <dialog-form-text-input
              :title="
                $t(
                  'pages.settings.intensityFigures.form.numberMetricDenominator'
                )
              "
              :value="metricDenominatorToString(formData?.metricDenominator)"
              @input="(val) => (formData.metricDenominator = val)"
              :errorMessages="formErrors?.metricDenominator"
              :hideDetails="false"
              :readonly="isStandardFigure"
            ></dialog-form-text-input>
          </v-col>
          <v-col>
            <dialog-form-input-wrapper
              :title="$t('pages.settings.intensityFigures.form.dateRange')"
            >
              <date-range-picker
                v-if="specificRange"
                :fromDate="formData?.startDate"
                :toDate="formData?.endDate"
                @change="(date) => setDateRange(date)"
                class="px-0"
                buttonStyle="padding: 0;"
              >
                <dialog-form-date-selector
                  :value="formatRangeDateValue(formData?.startDate)"
                  class="pr-3"
                  readonly
                  :errorMessages="formErrors?.startDate"
                  :hideDetails="false"
                ></dialog-form-date-selector>
                <ArrowRightIcon
                  width="14px"
                  height="14px"
                  style="margin-bottom: 30px"
                ></ArrowRightIcon>
                <dialog-form-date-selector
                  :value="formatRangeDateValue(formData?.endDate)"
                  class="pl-3"
                  readonly
                  :errorMessages="formErrors?.endDate"
                  :hideDetails="false"
                ></dialog-form-date-selector>
              </date-range-picker>
              <h5 v-else class="text-h5 py-3">Always</h5>
            </dialog-form-input-wrapper>
          </v-col>
        </v-row>
        <h3 class="mb-4">
          {{ $t("pages.settings.intensityFigures.form.chooseEmissons") }}
        </h3>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel v-for="(item, i) in items" :key="i" :value="i">
            <v-expansion-panel-header>
              <div class="d-flex flex-row align-center">
                <v-checkbox
                  hide-details
                  class="mt-0"
                  v-model="item.checked"
                  :indeterminate="item.indeterminate"
                  @click.stop="toggleCheckbox(item)"
                  :label="item.label"
                  :readonly="isStandardFigure"
                ></v-checkbox>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="ml-8" v-for="(child, j) in item.children" :key="j">
                <v-checkbox
                  hide-details
                  v-model="child.checked"
                  :indeterminate="child.indeterminate"
                  @click.stop="toggleCheckbox(child)"
                  :label="child.label"
                  :readonly="isStandardFigure"
                ></v-checkbox>
                <div v-if="child.virtualList && child.children">
                  <v-card color="#f7f7f7" elevation="0">
                    <v-virtual-scroll
                      :bench="50"
                      :items="child.children"
                      height="290"
                      item-height="64"
                      class="mt-4"
                    >
                      <template v-slot:default="{ item: grandchild }">
                        <v-checkbox
                          class="ml-8"
                          hide-details
                          v-model="grandchild.checked"
                          :indeterminate="grandchild.indeterminate"
                          @click.stop="toggleCheckbox(grandchild, true)"
                          :label="grandchild.label"
                          :readonly="isStandardFigure"
                        ></v-checkbox>
                      </template>
                    </v-virtual-scroll>
                  </v-card>
                </div>
                <div v-else-if="child.children" class="ml-8">
                  <v-checkbox
                    v-for="(grandchild, k) in child.children"
                    :key="k"
                    hide-details
                    v-model="grandchild.checked"
                    :indeterminate="grandchild.indeterminate"
                    @click.stop="toggleCheckbox(grandchild, true)"
                    :label="grandchild.label"
                    :readonly="isStandardFigure"
                  ></v-checkbox>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </dialog-form-section-one-col>
      <dialog-form-section-one-col>
        <v-checkbox
          :label="$t('common.actions.selectAll')"
          @change="(val) => selectAllItems(val)"
          :indeterminate="itemsIndeterminate"
          :value="allItemsSelected"
          :readonly="isStandardFigure"
        ></v-checkbox>
      </dialog-form-section-one-col>
    </div>
    <dialog-form-footer class="z-1">
      <template #left>
        <v-btn
          v-if="intensityFigure?.id && !isStandardFigure"
          @click="deleteIntensityFigure()"
          class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          text
          tile
          x-large
          :disabled="loading"
        >
          <v-icon class="mr-3" color="#686868">mdi-delete-outline</v-icon>
          {{ $t("common.actions.delete") }}
        </v-btn>
      </template>
      <template #right>
        <v-btn
          @click="show = false"
          class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          text
          tile
          x-large
          :disabled="loading"
        >
          <v-icon class="mr-3" color="#686868">mdi-close</v-icon>
          {{ $t("common.actions.cancel") }}
        </v-btn>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
          color="#F25D3B"
          elevation="0"
          tile
          x-large
          @click="saveIntensityFigure()"
          :loading="loading"
          :disabled="loading"
        >
          {{ $t("common.actions.save") }}
        </v-btn></template
      >
    </dialog-form-footer>
  </dialog-form>
</template>

<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormFooter from "../../Components/Dialog/DialogFormFooter.vue";
import DialogFormHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import DialogFormDateSelector from "../../Components/Dialog/inputs/DialogFormDateSelector.vue";
import DialogFormInputWrapper from "../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DateRangePicker from "../../Components/Inputs/DateRangePicker.vue";
import ArrowRightIcon from "../../assets/svg/arrow-right-dark-20px.svg";
import { serialize } from "object-to-formdata";
import { DateTime } from "luxon";
import { normalizeISOString } from "../../util/dateTime";

export default {
  components: {
    DialogForm,
    DialogFormHeader,
    DialogFormSectionOneCol,
    DialogFormTextInput,
    DateRangePicker,
    DialogFormDateSelector,
    DialogFormInputWrapper,
    DialogFormFooter,
    ArrowRightIcon,
  },
  props: {
    value: Boolean,
    intensityFigure: Object,
    scopes: Array,
    ghgCategories: Array,
    emissionSectors: Array,
    emissionCategories: Array,
    integrations: Array,
    financialAccounts: Array,
    dimensions: Array,
  },
  data() {
    return {
      items: [
        {
          label: "Scopes",
          key: "scopes",
          checked: false,
          indeterminate: false,
          children: [],
        },
        {
          label: "GHG categories",
          key: "ghgCategories",
          checked: false,
          indeterminate: false,
          children: [],
        },
        {
          label: "Emission factor sectors and categories",
          key: "emissionSectors",
          checked: false,
          indeterminate: false,
          children: [],
        },
        // temporary
        {
          label: "Dimensions",
          key: "dimensions",
          checked: false,
          indeterminate: false,
          children: [],
        },
      ],
      formData: this.emptyForm(),
      specificRange: false,
      loading: false,
      formErrors: null,
      panel: null,
    };
  },
  mounted() {
    // Populate items

    // Scopes
    const scopesIndex = this.items.findIndex((item) => item.key === "scopes");

    const scopes = this.scopes.map((scope) => {
      return {
        label: scope.value,
        id: scope.id,
        checked: false,
        indeterminate: false,
      };
    });

    this.items[scopesIndex].children = scopes;

    // Ghg categories
    const ghgCategoriesIndex = this.items.findIndex(
      (item) => item.key === "ghgCategories"
    );

    const ghgCategories = this.ghgCategories.map((ghgCategory) => {
      return {
        label: ghgCategory.name,
        id: ghgCategory.id,
        checked: false,
        indeterminate: false,
      };
    });

    this.items[ghgCategoriesIndex].children = ghgCategories;

    // Emission sectors and categories
    const emissionSectorsIndex = this.items.findIndex(
      (item) => item.key === "emissionSectors"
    );

    const emissionSectors = this.emissionSectors.map((emissionSector) => {
      const childEmissionCategories = this.emissionCategories
        .filter(
          (emissionCategory) => emissionCategory.sectorId === emissionSector.id
        )
        .map((emissionCategory) => {
          return {
            label: emissionCategory.name,
            id: emissionCategory.id,
            checked: false,
            indeterminate: false,
          };
        });
      return {
        label: emissionSector.name,
        id: emissionSector.id,
        checked: false,
        indeterminate: false,
        children: childEmissionCategories,
      };
    });

    this.items[emissionSectorsIndex].children = emissionSectors;

    // Integratons
    const integrations = this.integrations.map((integration) => {
      // Account numbers
      const accountnumbers = this.financialAccounts
        .filter(
          (financialAccount) =>
            financialAccount.integrationId === integration.id
        )
        .map((accountNumber) => {
          return {
            label: accountNumber.accountNumber + " " + accountNumber.name,
            id: accountNumber.id,
            checked: false,
            indeterminate: false,
          };
        });

      // Dimensions
      /*const dimensions = this.dimensions.map((dimension) => {
        return {
          label: dimension.name,
          id: dimension.id,
          checked: false,
          indeterminate: false,
        };
      });*/

      return {
        label: integration.name,
        key: "integration_" + integration.id,
        id: integration.id,
        checked: false,
        indeterminate: false,
        children: [
          {
            label: "Account numbers",
            key: "accountNumbers",
            checked: false,
            indeterminate: false,
            children: accountnumbers,
            virtualList: true,
          },
        ],
      };
    });

    this.items = this.items.concat(integrations);

    // Temp: Dimensions (when integration id is added to dimensions add them as children for each integration next to accountnumbers)
    const dimensionsIndex = this.items.findIndex(
      (item) => item.key === "dimensions"
    );

    const dimensions = this.dimensions.map((dimension) => {
      const dimensionValues = dimension.attributeSelectOptions.map(
        (dimensionValue) => {
          return {
            label: dimensionValue.value,
            id: dimensionValue.id,
            checked: false,
            indeterminate: false,
          };
        }
      );

      return {
        label: dimension.name,
        id: dimension.id,
        checked: false,
        indeterminate: false,
        children: dimensionValues,
      };
    });

    this.items[dimensionsIndex].children = dimensions;
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    allItemsSelected() {
      return (
        this.items.filter((item) => item.checked).length === this.items.length
      );
    },
    itemsIndeterminate() {
      const selectedParents = this.items.filter((item) => item.checked).length;
      if (selectedParents > 0 && selectedParents < this.items.length) {
        return true;
      }
      return this.items.filter((item) => item.indeterminate).length > 0;
    },
    isStandardFigure() {
      return !!this.intensityFigure?.standardFigureId;
    },
  },
  methods: {
    emptyForm() {
      const defaultDateRange = this.defaultDateRange();

      return {
        id: null,
        name: "",
        metricDenominator: "",
        startDate: defaultDateRange.from,
        endDate: defaultDateRange.to,
        noDataRestrictions: false,
        dimensionValueIds: [],
        emissionCategoryIds: [],
        emissionSectorIds: [],
        financialAccountIds: [],
        ghgCategoryIds: [],
        integrationIds: [],
        scopeIds: [],
      };
    },
    defaultDateRange() {
      const today = DateTime.utc().startOf("day");
      const startOfYear = today.startOf("year").toJSDate();
      const endOfYear = today.endOf("Year").startOf("day").toJSDate();
      const fromDate = new Date(startOfYear).toISOString().split("T")[0];
      const toDate = new Date(endOfYear).toISOString().split("T")[0];

      return {
        from: fromDate,
        to: toDate,
      };
    },
    setDateRange(dateRange) {
      this.formData.startDate = dateRange.from;
      this.formData.endDate = dateRange.to;
    },
    metricDenominatorToString(metricDenominator) {
      if (metricDenominator) {
        return metricDenominator + "";
      }
      return "";
    },
    updateChildCheckboxes(item, value) {
      item.checked = value;
      item.indeterminate = false;
      if (item.children) {
        item.children.forEach((child) => {
          this.updateChildCheckboxes(child, value);
        });
      }
    },
    updateParentCheckboxes() {
      const checkIndeterminate = (parent) => {
        let total = parent.children.length;

        let checkedCount = parent.children.filter(
          (child) => child.checked
        ).length;

        let indeterminateCount = parent.children.filter(
          (child) => child.indeterminate
        ).length;

        // Parent has no checked or indeterminate
        if (checkedCount === 0 && indeterminateCount === 0) {
          parent.checked = false;
          parent.indeterminate = false;
          // Parent has all checked
        } else if (checkedCount === total) {
          parent.checked = true;
          parent.indeterminate = false;
          // All other cases
        } else {
          parent.checked = false;
          parent.indeterminate = true;
        }
      };

      const updateParent = (parent) => {
        if (parent) {
          checkIndeterminate(parent);
          updateParent(parent.parent);
        }
      };

      // Parents
      this.items.forEach((parent) => {
        // Children
        parent.children.forEach((child) => {
          child.parent = parent;
          updateParent(parent);
          //Grandchildren
          child.children?.forEach((grandChild) => {
            grandChild.parent = child;
            updateParent(child);
          });
        });
      });
    },
    toggleCheckbox(item) {
      this.updateChildCheckboxes(item, item.checked);
      // Parents
      this.updateParentCheckboxes();
    },
    selectAllItems(value) {
      this.items.forEach((item) => {
        this.updateChildCheckboxes(item, value);
      });
    },
    deleteIntensityFigure() {
      this.loading = true;
      this.$inertia.delete(
        this.route("intensity-figures.destroy", this.formData.id),
        {
          preserveState: true,
          preserveScroll: true,
          onFinish: () => {
            this.show = false;
            this.loading = false;
          },
        }
      );
    },
    saveIntensityFigure() {
      const formData = { ...this.formData };

      if (!this.allItemsSelected) {
        formData.scopeIds = this.getSelectedScopeIds();
        formData.ghgCategoryIds = this.getSelectedGhgCategories();
        formData.emissionSectorIds = this.getSelectedEmissionSectors();
        formData.emissionCategoryIds = this.getSelectedEmissionCategories();
        formData.dimensionValueIds = this.getSelectedDimensionValues();
        // financialAccountIds grows to large if all are included for multiple integrations
        formData.financialAccountIds = this.getSelectedAccountNumbers();
        // To fix this we only include integration ids for integrations where all accounts are selected
        formData.integrationIds = this.getSelectedIntegrations();
      }

      formData.noDataRestrictions = this.allItemsSelected;

      if (!this.specificRange) {
        formData.startDate = null;
        formData.endDate = null;
      }

      // Validate formData before submitting

      const errors = this.validateFormData(formData);

      if (errors !== null) {
        this.formErrors = errors;
        return;
      }

      if (formData.id) {
        this.loading = true;
        this.$inertia.put(
          this.route("intensity-figures.update", formData.id),
          serialize(formData, {
            noFilesWithArrayNotation: true,
            indices: true,
          }),
          {
            preserveState: true,
            preserveScroll: true,
            onFinish: () => {
              this.show = false;
              this.loading = false;
            },
          }
        );
      } else {
        this.loading = true;
        this.$inertia.post(
          this.route("intensity-figures.store"),
          serialize(formData, {
            noFilesWithArrayNotation: true,
            indices: true,
          }),
          {
            preserveState: true,
            preserveScroll: true,
            onFinish: () => {
              this.show = false;
              this.loading = false;
            },
          }
        );
      }
    },
    // Scopes
    getSelectedScopeIds() {
      const scopesIndex = this.items.findIndex((item) => item.key === "scopes");
      const selectedScopeIds = this.items[scopesIndex].children
        .filter((scope) => scope.checked)
        .map((scope) => scope.id);
      return selectedScopeIds;
    },
    setSelectedScopes() {
      const scopeIds =
        this.intensityFigure?.scopes.map((scope) => scope.id) ?? [];
      this.formData.scopeIds = scopeIds;
      const scopesIndex = this.items.findIndex((item) => item.key === "scopes");
      scopeIds.forEach((scopeId) => {
        const scopeIndex = this.items[scopesIndex].children.findIndex(
          (scope) => scope.id === scopeId
        );
        this.items[scopesIndex].children[scopeIndex].checked = true;
      });
    },
    // Ghg categories
    getSelectedGhgCategories() {
      const ghgCategoriesIndex = this.items.findIndex(
        (item) => item.key === "ghgCategories"
      );
      const selectedGhgCategoryIds = this.items[ghgCategoriesIndex].children
        .filter((ghgCategory) => ghgCategory.checked)
        .map((ghgCategory) => ghgCategory.id);
      return selectedGhgCategoryIds;
    },
    setSelectedGhgCategories() {
      const ghgCategoryIds =
        this.intensityFigure?.ghgCategories.map(
          (ghgCategory) => ghgCategory.id
        ) ?? [];
      this.formData.ghgCategoryIds = ghgCategoryIds;

      const ghgCategoriesIndex = this.items.findIndex(
        (item) => item.key === "ghgCategories"
      );
      ghgCategoryIds.forEach((ghgCategoryId) => {
        const ghgCategoryIndex = this.items[
          ghgCategoriesIndex
        ].children.findIndex((ghgCategory) => ghgCategory.id === ghgCategoryId);
        this.items[ghgCategoriesIndex].children[
          ghgCategoryIndex
        ].checked = true;
      });
    },
    // Emission sectors
    getSelectedEmissionSectors() {
      const emissionSectorsIndex = this.items.findIndex(
        (item) => item.key === "emissionSectors"
      );
      const emissionSectorIds = this.items[emissionSectorsIndex].children
        .filter((emissionSector) => emissionSector.checked)
        .map((emissionSector) => emissionSector.id);
      return emissionSectorIds;
    },
    setSelectedEmissionSectors() {
      const emissionSectorsIds =
        this.intensityFigure?.emissionSectors.map(
          (emissionSector) => emissionSector.id
        ) ?? [];
      this.formData.emissionSectorsIds = emissionSectorsIds;

      const emissionSectorsIndex = this.items.findIndex(
        (item) => item.key === "emissionSectors"
      );

      emissionSectorsIds.forEach((emissionSectorId) => {
        const emissionSectorIndex = this.items[
          emissionSectorsIndex
        ].children.findIndex(
          (emissionSector) => emissionSector.id === emissionSectorId
        );
        this.items[emissionSectorsIndex].children[
          emissionSectorIndex
        ].checked = true;
      });
    },
    // Emission categories
    getSelectedEmissionCategories() {
      const emissionSectorsIndex = this.items.findIndex(
        (item) => item.key === "emissionSectors"
      );

      let emissionCategoryIds = [];

      this.items[emissionSectorsIndex].children.forEach((emissionSector) => {
        const categories = emissionSector.children
          .filter((emissionCategory) => emissionCategory.checked)
          .map((emissionCategory) => emissionCategory.id);

        emissionCategoryIds.push(...categories);
      });
      return emissionCategoryIds;
    },
    setSelectedEmissionCategories() {
      const emissionSectorsIndex = this.items.findIndex(
        (item) => item.key === "emissionSectors"
      );

      const emissionCategoryIds =
        this.intensityFigure?.emissionCategories.map(
          (emissionCategory) => emissionCategory.id
        ) ?? [];

      this.formData.emissionCategoryIds = emissionCategoryIds;

      // all emission sector ids
      const emissionSectorsIds = this.emissionSectors.map(
        (emissionSector) => emissionSector.id
      );

      emissionSectorsIds.forEach((emissionSectorId) => {
        const emissionSectorIndex = this.items[
          emissionSectorsIndex
        ].children.findIndex((item) => item.id === emissionSectorId);

        this.items[emissionSectorsIndex].children[
          emissionSectorIndex
        ]?.children.forEach((emissionCategory, emissionCategoryindex) => {
          // check if this id is in selectedEmissionCategories and if it is set to checked
          if (emissionCategoryIds.includes(emissionCategory.id)) {
            this.items[emissionSectorsIndex].children[
              emissionSectorIndex
            ].children[emissionCategoryindex].checked = true;
          }
        });
      });
    },
    // Dimensions
    getSelectedDimensionValues() {
      const dimensionsIndex = this.items.findIndex(
        (item) => item.key === "dimensions"
      );

      let selectedDimensionValueIds = [];

      this.items[dimensionsIndex].children.forEach((dimension) => {
        const dimensionValues = dimension.children
          .filter((dimension) => dimension.checked)
          .map((dimension) => dimension.id);

        selectedDimensionValueIds.push(...dimensionValues);
      });

      return selectedDimensionValueIds;
    },
    setSelectedDimensionValues() {
      const dimensionsIndex = this.items.findIndex(
        (item) => item.key === "dimensions"
      );

      const dimensionValueIds =
        this.intensityFigure?.dimensionValues.map(
          (dimensionValue) => dimensionValue.id
        ) ?? [];

      this.items[dimensionsIndex].children.forEach(
        (dimension, dimensionIndex) => {
          dimension.children.forEach((dimensionValue, dimensionValueIndex) => {
            if (dimensionValueIds.includes(dimensionValue.id)) {
              this.items[dimensionsIndex].children[dimensionIndex].children[
                dimensionValueIndex
              ].checked = true;
            }
          });
        }
      );
    },
    // Integrations & account numbers (Remember to add dimensions when integration ref is added to them)
    getSelectedAccountNumbers() {
      //loop though each integration
      const integrations = this.items.filter(
        (item) => item.key?.split("_")[0] === "integration"
      );

      let selectedAccountNumberIds = [];

      // Wee need to ignore account numbers for integrations where all account numbers are selected
      const selectedIntegrations = this.getSelectedIntegrations();

      integrations.forEach((integration) => {
        // Remember to check for dimensions aswell here
        if (selectedIntegrations.includes(integration.id)) {
          return;
        }
        integration.children
          .filter((children) => children.key === "accountNumbers")[0]
          .children.filter((accountNumber) => accountNumber.checked)
          .forEach((accountNumber) => {
            selectedAccountNumberIds.push(accountNumber.id);
          });
      });

      return selectedAccountNumberIds;
    },
    setSelectedAccountNumbers() {
      const selectedFinancialAccountIds =
        this.intensityFigure?.financialAccounts.map(
          (financialAccount) => financialAccount.id
        ) ?? [];

      this.integrations.forEach((integration) => {
        const key = "integration_" + integration.id;

        const integrationIndex = this.items.findIndex(
          (item) => item.key === key
        );

        const accountNumbersIndex = this.items[
          integrationIndex
        ].children.findIndex((child) => child.key === "accountNumbers");

        this.items[integrationIndex].children[
          accountNumbersIndex
        ].children.forEach((accountNumber, accountNumberIndex) => {
          if (selectedFinancialAccountIds.includes(accountNumber.id)) {
            this.items[integrationIndex].children[accountNumbersIndex].children[
              accountNumberIndex
            ].checked = true;
          }
        });
      });
    },
    getSelectedIntegrations() {
      const integrations = this.items.filter(
        (item) => item.key?.split("_")[0] === "integration"
      );

      let selectedIntegrationIds = [];

      integrations.forEach((integration) => {
        if (integration.checked) {
          selectedIntegrationIds.push(integration.id);
        }
      });

      return selectedIntegrationIds;
    },
    setSelectedIntegrations() {
      const selectedIntegrationIds =
        this.intensityFigure?.integrations.map(
          (integration) => integration.id
        ) ?? [];
      selectedIntegrationIds.forEach((integrationId) => {
        const itemIndex = this.items.findIndex(
          (item) => item.key === "integration_" + integrationId
        );

        this.updateChildCheckboxes(this.items[itemIndex], true);
      });
    },
    // form validation
    validateFormData(formData) {
      const errors = {};

      // Must be set (name)
      if (!formData.name || formData.name.trim() === "") {
        errors.name = this.$t(
          "pages.settings.intensityFigures.form.validation.nameRequired"
        );
      }

      // Must be a number (metricDenominator)
      if (
        formData.metricDenominator === null ||
        formData.metricDenominator === undefined ||
        isNaN(Number(formData.metricDenominator))
      ) {
        errors.metricDenominator = this.$t(
          "pages.settings.intensityFigures.form.validation.metricDenominatorRequired"
        );
      } else if (Number(formData.metricDenominator) < 0) {
        errors.metricDenominator = this.$t(
          "pages.settings.intensityFigures.form.validation.metricDenominatorPositive"
        );
      }

      // Must be set (startDate)
      if (!formData.startDate) {
        errors.startDate = this.$t(
          "pages.settings.intensityFigures.form.validation.startDateRequired"
        );
      }

      // Must be set (endDate)
      if (!formData.endDate) {
        errors.endDate = this.$t(
          "pages.settings.intensityFigures.form.validation.endDateRequired"
        );
      }

      // If there are no errors, return null or true to indicate valid data
      if (Object.keys(errors).length === 0) {
        return null; // No errors, valid form
      }

      // Return the errors object if there are validation issues
      return errors;
    },
    formatRangeDateValue(date) {
      if (!this.specificRange || !date) return null;

      let normalizedDate = normalizeISOString(date);

      return new Date(normalizedDate);
    },
  },
  watch: {
    intensityFigure(to) {
      if (to !== null) {
        this.formData = {
          id: to.id,
          dimensionValueIds: to.dimensionValueIds,
          emissionCategoryIds: to.emissionCategoryIds,
          emissionSectorIds: to.emissionSectorIds,
          endDate: to.endDate,
          financialAccountIds: to.financialAccountIds,
          ghgCategoryIds: to.ghgCategoryIds,
          integrationIds: to.integrationIds,
          metricDenominator: to.metricDenominator,
          name: to.name,
          noDataRestrictions: to.noDataRestrictions,
          scopeIds: to.scopeIds,
          startDate: to.startDate,
        };

        this.specificRange =
          !!this.formData.endDate || !!this.formData.startDate;
      } else {
        this.formData = this.emptyForm();

        this.specificRange = false;
      }

      this.formErrors = null;

      // reset selections
      this.selectAllItems(false);

      // Get selected scopeIds
      this.setSelectedScopes();

      // Get selected ghg's
      this.setSelectedGhgCategories();

      // Emission sectors and categories
      this.setSelectedEmissionSectors();
      this.setSelectedEmissionCategories();

      // Dimensions
      this.setSelectedDimensionValues();

      // Accounts
      this.setSelectedAccountNumbers();

      // Integrations
      this.setSelectedIntegrations();

      // Update checkboxes
      this.updateParentCheckboxes();
    },
    show(to) {
      if (to === false) {
        if (this.$refs["scrollable-area"] !== undefined) {
          this.$refs["scrollable-area"].scrollTop = 0;
          this.panel = null;
        }
      }
    },
    specificRange(to) {
      if (!to) return;

      const dateRange = this.defaultDateRange();

      if (!this.formData.startDate) {
        this.formData.startDate = dateRange.from;
      }

      if (!this.formData.endDate) {
        this.formData.endDate = dateRange.to;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
