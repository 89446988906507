import { render, staticRenderFns } from "./TotalGhgAndScopes.vue?vue&type=template&id=25f2e432&scoped=true"
import script from "./TotalGhgAndScopes.vue?vue&type=script&lang=js"
export * from "./TotalGhgAndScopes.vue?vue&type=script&lang=js"
import style0 from "./TotalGhgAndScopes.vue?vue&type=style&index=0&id=25f2e432&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25f2e432",
  null
  
)

export default component.exports