<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-container fluid class="py-0" style="margin-top: -66px">
      <AssetCardRowDefault :assetCardData="assetCardData"></AssetCardRowDefault>
      <TableConfiguration
        @update-filters="(filters) => updateFilters(filters)"
        @update-fields="(fields) => updateFields(fields)"
        @search-text="(text) => searchForText(text)"
        :layoutOptions="{ ...this.layout }"
        :filterFields="filterFields"
        :accountingEntryFields="accountingEntryFields"
        :sortOnlyFields="sortOnlyFields"
        :hiddenFields="hiddenFields"
      ></TableConfiguration>
      <v-row class="px-2">
        <v-col cols="12" class="px-2 pt-0">
          <AccountingEntryTable
            ref="entry-table"
            :entries="formattedEntries"
            :headers="accountingEntriesHeaders"
            @update-header="(val) => updateHeaders(val)"
            @update-card-data="() => fetchAssetsCardData(dateRange)"
            @edit-asset="(val) => (selectedAssetId = val)"
            @bulk-edit-assets="
              (assetIds, entryIds, extraAssetCount) =>
                applyBulkEditData(assetIds, entryIds, extraAssetCount)
            "
            @updated-items-per-page="(val) => (layoutOptions.pageSize = val)"
            :entryItemsTotalCount="accountingEntries?.totalCount"
            :entryItemsPerPage="accountingEntries?.pageSize"
            :defaultDateFormat="settings.defaultDateFormat"
            :filters="[...layout.filters]"
            :currencies="currencies"
            @add-sort-filters="(filters) => addSortFilters(filters)"
          ></AccountingEntryTable>
        </v-col>
      </v-row>
      <AssetFormWrapper
        :assetId="selectedAssetId"
        @clear-asset-id="selectedAssetId = ''"
        @reloadAsset="reloadAsset"
        @deleteAsset="deleteAsset"
        :attributes="attributes"
        :currencies="currencies"
        :ghgCategories="ghgCategories"
        :attributeClasses="attributeClasses"
      ></AssetFormWrapper>
      <BulkEditAssetsForm
        :value="selectedBulkAssetIds.length + selectedBulkEntryIds.length > 0"
        :attribute-classes="attributeClasses"
        :attributes="attributes"
        :ghg-categories="ghgCategories"
        :currencies="currencies"
        :asset-ids="selectedBulkAssetIds"
        :entry-ids="selectedBulkEntryIds"
        :indirect-asset-count="bulkEditExtraAssetCount"
        @input="() => clearBulkEditData()"
      />
    </v-container>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import AssetCardRowDefault from "../../Components/StatCardRows/AssetCardRowDefault.vue";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";
import TableConfiguration from "../../Components/Table/TableConfiguration.vue";
import AccountingEntryTable from "../../Components/AccountingEntry/AccountingEntryTable.vue";
import AssetFormWrapper from "./AssetForm.vue";
import BulkEditAssetsForm from "../../Components/Asset/BulkEditAssetsFormNew.vue";
import { serialize } from "object-to-formdata";
import Vue from "vue";

export default {
  components: {
    AssetCardRowDefault,
    TableConfiguration,
    AccountingEntryTable,
    AssetFormWrapper,
    BulkEditAssetsForm,
  },
  mixins: [GlobalDateRange],
  layout: appLayout({
    title: "pages.assets.entryView.title",
    pushContent: true,
  }),
  props: {
    accountingEntries: Object,
    attributes: Array,
    currencies: Array,
    ghgCategories: Array,
    attributeClasses: Array,
    layout: Object,
    filterFields: Array,
    sortOnlyFields: Array,
    accountingEntryFields: Array,
    auth: Object,
    hiddenFields: Array,
  },
  data() {
    return {
      assetCardData: null,
      accountingEntriesHeaders: this.generateAccountingEntryHeaders(),
      selectedAssetId: "",
      selectedBulkAssetIds: [],
      selectedBulkEntryIds: [],
      bulkEditExtraAssetCount: 0,
      layoutOptions: { ...this.layout },
      firstLoad: true,
      dateRange: null,
    };
  },
  mounted() {
    this.syncDateRange((dateRange) => {
      this.dateRange = dateRange;

      this.fetchAssetsCardData(dateRange);
      if (!this.firstLoad) {
        this.updateDateRange(dateRange);
      } else {
        this.firstLoad = false;
      }
    });
  },
  methods: {
    updateLayout() {
      const form = serialize(this.layoutOptions, {
        indices: true,
        dotsForObjectNotation: true,
      });

      this.$inertia.post(
        this.route("accounting-entries.layouts.update"),
        form,
        {
          preserveScroll: true,
          onSuccess: () => {
            // Fetch page data
          },
          onFinish: () => {
            // Have to set headers here else they wont be update until page reload
            this.accountingEntriesHeaders =
              this.generateAccountingEntryHeaders();
          },
        }
      );
    },
    generateAccountingEntryHeaders() {
      return this.layout?.fields
        .map((field) => {
          const propertyName = field.propertyName;

          const text = this.$t(
            "components.accountingEntries.fields." + propertyName
          );

          return {
            text: text,
            value: propertyName,
            width: propertyName === "Account" ? null : 150,
            order: field.sortOrder,
          };
        })
        .sort((a, b) => a.order - b.order);
    },
    async fetchAssetsCardData(dateRange) {
      await fetch(
        this.route("api.dashboard.infographics.assets.card-data", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.assetCardData = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
    updateHeaders(value) {
      const sortedFields = value.map((f) => f.value);

      Vue.set(this.layoutOptions, "fields", sortedFields);

      this.accountingEntriesHeaders = value;
    },

    reloadAsset() {
      this.$refs["entry-table"].reloadAssets();
      // Reload all assets in entry?
    },
    deleteAsset(id) {
      this.$inertia.delete(this.route("assets.destroy", id), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
          this.$refs["entry-table"].reloadAssets();
        },
      });
    },
    updateFilters(filters) {
      Vue.set(this.layoutOptions, "filters", filters);
    },
    addSortFilters(filters) {
      const appliedTypeFilters = this.layoutOptions.filters.filter(
        (filter) => filter.filterType !== null
      );

      this.layoutOptions.filters = [...filters, ...appliedTypeFilters];

      //Vue.set(this.layoutOptions, "filters", filters);
    },
    updateFields(fields) {
      Vue.set(this.layoutOptions, "fields", fields);
    },
    searchForText(text) {
      Vue.set(this.layoutOptions, "searchText", text);
    },
    updateDateRange(dateRange) {
      this.$inertia.get(
        this.route("accounting-entries.index", {
          page: 1,
          fromDate: dateRange.from,
          toDate: dateRange.to,
        }),
        { preserveState: true }
      );
    },
    applyBulkEditData(assetIds, entryIds, extraAssetCount) {
      this.selectedBulkAssetIds = assetIds;
      this.selectedBulkEntryIds = entryIds;
      this.bulkEditExtraAssetCount = extraAssetCount;
    },
    clearBulkEditData() {
      this.selectedBulkAssetIds = [];
      this.selectedBulkEntryIds = [];
      this.bulkEditExtraAssetCount = 0;
    },
  },
  computed: {
    formattedEntries() {
      return this.accountingEntries.data.map((entry) => {
        return {
          id: entry.id,
          Account:
            entry.financialAccount.accountNumber +
            " - " +
            entry.financialAccount.name,
          EntryNumber: entry.entryNumber,
          EntryText: entry.entryText,
          EntryDate: entry.entryDate,
          EntryAmount: entry.entryAmount,
          EntryCurrency: entry.entryCurrency,
          NumberOfAssets: entry.numberOfAssets,
          Emissions: entry.totalCo2Emissions,
          VoucherNumber: entry.voucherNumber,
          FileId: entry.fileId,
          File: entry.file,
          EmissionFactorStatus: entry.emissionFactorStatus,
        };
      });
    },
    settings() {
      return this.auth.settings;
    },
  },
  watch: {
    layoutOptions: {
      handler: function () {
        this.updateLayout();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
