<template>
  <dialog-form
    :show-dialog="show"
    :max-width="dialogMaxWidth"
    :persistent="true"
  >
    <div class="d-flex flex-column px-0 pt-8">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>
      <step-visualizer
        class="pb-8"
        :steps="5"
        :selected-step="selectedOnboardingStep"
        ref="stepVisualizer"
        :hiddenStep="2"
      />
      <div style="max-width: 716px; align-self: center">
        <h1 class="text-h4 black--text mb-2 text-center">
          {{ currentStep.title }}
        </h1>
        <h2
          class="text-subtitle-1 grey--text mb-2 font-weight-light text-center"
        >
          {{ currentStep.subTitle }}<br />
          {{ currentStep.subTitleLine2 }}
          <a :href="currentStep.subTitleLink">{{
            currentStep.subTitleLinkText
          }}</a>
        </h2>
      </div>

      <v-alert
        type="error"
        v-if="
          onboardingIntegrationError &&
          $te(`common.integrations.errors.${onboardingIntegrationError}`)
        "
        class="mb-6 mx-8"
      >
        {{ $t(`common.integrations.errors.${onboardingIntegrationError}`) }}
      </v-alert>

      <div
        class="d-flex align-center relative"
        style="overflow-y: auto; min-height: 350px"
        :style="
          selectedOnboardingStep === 5
            ? 'height: calc(100vh - 527px);max-height: 527px;'
            : null
        "
      >
        <Transition name="fade">
          <onboarding-step-one
            v-if="selectedOnboardingStep === 1"
            class="transition-item"
            @updateForm="updateForm"
            :companyRegNo="form.companyRegNo"
            :companyName="form.companyName"
            :companyEmail="form.companyEmail"
          />
          <onboarding-step-two
            v-if="selectedOnboardingStep === 2"
            class="transition-item"
            :fiscalYear.sync="fiscalYear"
            :fiscalYears="fiscalYears"
            :depreciation-starts="depreciationStarts"
            :depreciation-methods="depreciationMethods"
            :currencies="currencies"
            :countries="countries"
            :default-currency="getDefaultCurrency()"
            :default-region="getDefaultRegion()"
            @updateForm="updateForm"
          />
          <onboarding-step-three
            v-if="selectedOnboardingStep === 3"
            class="transition-item"
            :selected-integration.sync="selectedIntegration"
            :show-continue="true"
            @otherIntegration="handleOtherIntegration"
            @updateForm="updateForm"
          />
          <onboarding-step-four
            v-if="selectedOnboardingStep === 4"
            class="transition-item"
            :selected-integration="selectedIntegration"
          />
          <onboarding-step-five
            v-if="selectedOnboardingStep === 5"
            :api-token="apiToken"
            :selected-integration="selectedIntegration"
            class="transition-item"
          />
          <onboarding-step-six
            v-if="selectedOnboardingStep === 6"
            class="transition-item"
            :integration-id="integrationId"
            :integration-installed="integrationInstalled"
            ref="account-mapper"
            style="overflow-y: scroll; overflow-x: hidden; height: 100%"
            :mappingIsReadOnly="mappingIsReadOnly"
          />
        </Transition>
        <integration-dynamics-365-environment-modal
          v-model="showDynamicsIntegration"
          :redirectToOnboarding="true"
        ></integration-dynamics-365-environment-modal>
        <integration-uniconta-credentials-modal
          v-if="showUnicontaIntegration && unicontaCompanies === null"
          v-model="showUnicontaIntegration"
          @update:unicontaCompanies="unicontaCompanies = $event"
          @update:unicontaUsername="unicontaUsername = $event"
          @update:unicontaPassword="unicontaPassword = $event"
        ></integration-uniconta-credentials-modal>
        <integration-uniconta-company-modal
          v-else-if="showUnicontaIntegration && unicontaCompanies !== null"
          v-model="showUnicontaIntegration"
          :uniconta-companies="unicontaCompanies"
          :uniconta-username="unicontaUsername"
          :uniconta-password="unicontaPassword"
          :redirect-to-onboarding="true"
        ></integration-uniconta-company-modal>
      </div>
      <v-card-actions
        v-if="selectedOnboardingStep == 6"
        class="d-flex flex-row footer-section pa-8"
        style="border-top: 1px solid #e8e8e8"
      >
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            selectedOnboardingStep !== 4 &&
            selectedIntegration !== integrations.businessCentralOnPremise &&
            selectedIntegration !== integrations.ekomplet
          "
          tile
          large
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-none black--text black--border white pa-0 ml-0"
          style="flex: 1"
          @click="skipMapping()"
        >
          {{ $t("components.onboarding.skip") }}
        </v-btn>
        <v-btn
          v-if="mappingIsReadOnly"
          tile
          large
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-none black--text black--border white pa-0"
          style="flex: 1"
          @click="editMapping()"
        >
          {{ $t("components.onboarding.edit") }}
        </v-btn>
        <v-btn
          v-else
          tile
          large
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-none black--text black--border white pa-0"
          style="flex: 1"
          @click="cancelMapping()"
        >
          {{ $t("components.onboarding.cancel") }}
        </v-btn>
        <v-btn
          tile
          large
          elevation="0"
          color="orange"
          class="font-weight-regular text-none white--text"
          type="submit"
          :disabled="isContinueDisabled"
          @click="moveToNextStep()"
          style="flex: 1"
        >
          {{ $t("components.onboarding.continue") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else class="d-flex flex-column footer-section pa-8">
        <v-btn
          tile
          block
          elevation="0"
          color="orange"
          class="font-weight-regular text-none white--text pa-0 mb-4"
          type="submit"
          :disabled="isContinueDisabled"
          @click="moveToNextStep()"
        >
          {{ connectSystemButtonText }}
        </v-btn>
        <v-btn
          v-if="selectedOnboardingStep !== 4"
          tile
          outlined
          block
          elevation="0"
          bg-color="white"
          class="border-sm text-none black--text black--border white pa-0 ml-0"
          @click="hideModal()"
        >
          {{
            selectedOnboardingStep === 4
              ? $t("components.onboarding.stepFour.apiFields")
              : $t("components.onboarding.skip")
          }}
        </v-btn>
        <!-- Hiding the api fields popup currently because they are not used -->
      </v-card-actions>
    </div>
  </dialog-form>
</template>
<script>
import OnboardingStepOne from "./OnboardingSteps/OnboardingStepOne.vue";
import OnboardingStepTwo from "./OnboardingSteps/OnboardingStepTwo.vue";
import OnboardingStepThree from "./OnboardingSteps/OnboardingStepThree.vue";
import OnboardingStepFour from "./OnboardingSteps/OnboardingStepFour.vue";
import OnboardingStepFive from "./OnboardingSteps/OnboardingStepFive.vue";
import OnboardingStepSix from "./OnboardingSteps/OnboardingStepSix.vue";
import DialogForm from "../Dialog/DialogForm.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
import StepVisualizer from "../StepVisualizer.vue";
import { serialize } from "object-to-formdata";
import IntegrationDynamics365EnvironmentModal from "../../Pages/Setting/IntegrationDynamics365EnvironmentModal.vue";
import IntegrationUnicontaCredentialsModal from "../../Pages/Setting/IntegrationUnicontaCredentialsModal.vue";
import IntegrationUnicontaCompanyModal from "../../Pages/Setting/IntegrationUnicontaCompanyModal.vue";

import {
  businessCentral,
  businessCentralOnPremise,
  economic,
  ekomplet,
  uniconta,
  other,
  manualFileUpload,
} from "@/util/integrations";

export default {
  components: {
    OnboardingStepOne,
    OnboardingStepTwo,
    OnboardingStepThree,
    OnboardingStepFour,
    OnboardingStepFive,
    OnboardingStepSix,
    DialogForm,
    LogoBlack,
    StepVisualizer,
    IntegrationDynamics365EnvironmentModal,
    IntegrationUnicontaCredentialsModal,
    IntegrationUnicontaCompanyModal,
  },
  props: {
    selectedStep: {
      type: Number,
      default: 1,
    },
    value: Boolean,
    settings: Object,
    fiscalYears: Array,
    depreciationMethods: Array,
    depreciationStarts: Array,
    currencies: Array,
    countries: Array,
    integrationId: String,
    serverIntegrationError: String,
    companyRegNo: String,
    companyName: String,
    companyEmail: String,
    integrationInstalled: Boolean,
  },
  data() {
    return {
      form: this.$inertia.form(this.generateInitialForm()),
      financialSystemForm: this.$inertia.form(
        this.generateInitialFinancialSystemForm()
      ),

      fiscalYear: "",

      showDynamicsIntegration: false,

      showUnicontaIntegration: false,
      unicontaUsername: null,
      unicontaPassword: null,
      unicontaCompanies: null,

      selectedOnboardingStep: this.selectedStep,
      selectedIntegration: "",
      integrations: {
        economic: economic,
        uniconta: uniconta,
        businessCentral: businessCentral,
        businessCentralOnPremise: businessCentralOnPremise,
        ekomplet: ekomplet,
      },
      mappingIsReadOnly: true,
      apiToken: null,
      integrationError: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    onboardingIntegrationError() {
      return this.$props.serverIntegrationError || this.$data.integrationError;
    },
    currentStep() {
      const onboardingSteps = [
        {
          id: 1,
          title: this.$t("components.onboarding.titles.basicInfo"),
          subTitle: this.$t("components.onboarding.subtitles.basicInfo"),
        },
        {
          id: 2,
          title: this.$t("components.onboarding.titles.basicInfo"),
          subTitle: this.$t("components.onboarding.subtitles.dataBasedOn"),
        },
        {
          id: 3,
          title: this.$t("components.onboarding.titles.chooseIntegration"),
          subTitle: this.$t(
            "components.onboarding.subtitles.integrateWithVerarca"
          ),
        },
        {
          id: 4,
          title: this.$t("components.onboarding.titles.startIntegration"),
          subTitle: this.$t(
            "components.onboarding.subtitles.howToIntegrateWithVerarca"
          ),
        },
        {
          id: 5,
          title: this.$t("components.onboarding.titles.apiToken"),
          subTitle: this.$t("components.onboarding.subtitles.apiTokenAddToApp"),
        },
        {
          id: 6,
          title: this.$t("components.onboarding.titles.assignAccountsToSync"),
          subTitle: this.$t(
            "components.onboarding.subtitles.chooseAccountCategory"
          ),
          subTitleLine2: this.$t(
            "components.onboarding.subtitles.accountCategoryHelp"
          ),
          subTitleLink: this.$t(
            "components.onboarding.subtitles.accountCategoryHashTag"
          ),
          subTitleLinkText: this.$t(
            "components.onboarding.subtitles.accountCategoryGuidance"
          ),
        },
      ];

      if (this.selectedOnboardingStep === 6 && this.mappingIsReadOnly) {
        return {
          title: this.$t("components.onboarding.titles.assignAIAccountsToSync"),
          subTitle: this.$t(
            "components.onboarding.subtitles.suggestedGHGCategoryMapping"
          ),
          subTitleLine2: this.$t(
            "components.onboarding.subtitles.continueOrEdit"
          ),
        };
      }

      const step = onboardingSteps.find(
        (x) => x.id === this.selectedOnboardingStep
      );

      if (step.id === 5 && this.selectedIntegration === ekomplet) {
        step.subTitle = this.$t(
          "components.onboarding.subtitles.apiTokenEkompletApp"
        );
      }

      return step;
    },
    isContinueDisabled() {
      if (this.selectedOnboardingStep === 1) {
        return !(
          this.form.companyName &&
          this.form.companyRegNo &&
          this.form.companyEmail
        );
      }

      if (this.selectedOnboardingStep === 3) {
        return this.selectedIntegration === "";
      }

      return false;
    },
    dialogMaxWidth() {
      if (this.selectedOnboardingStep === 6) {
        return "960px";
      }
      return "538px";
    },
    connectSystemButtonText() {
      if (this.selectedIntegration == other) {
        return this.$t(
          "components.onboarding.stepFour.continueWithoutIntegration"
        );
      }

      if (this.selectedIntegration == manualFileUpload) {
        return this.$t("components.onboarding.stepFour.setupManualFileUpload");
      }

      let text = null;

      switch (this.selectedOnboardingStep) {
        case 3:
        case 4:
          text = `${this.$t(
            "components.onboarding.stepFour.clickHereToConnect"
          )} ${this.selectedIntegration}`;
          break;
        case 5:
          if (
            this.selectedIntegration === businessCentralOnPremise ||
            this.selectedIntegration === ekomplet
          ) {
            text = this.$t("components.onboarding.close");
          }
          break;
        default:
          text = this.$t("components.onboarding.next");
          break;
      }

      return text;
    },
  },
  methods: {
    editMapping() {
      this.mappingIsReadOnly = false;
    },
    cancelMapping() {
      this.mappingIsReadOnly = true;
    },
    moveToNextStep() {
      if (
        (this.selectedOnboardingStep === 3 &&
          this.selectedIntegration == other) ||
        (this.selectedOnboardingStep === 5 &&
          (this.selectedIntegration === businessCentralOnPremise ||
            this.selectedIntegration === ekomplet))
      ) {
        this.finishOnboarding();
      }

      if (this.selectedOnboardingStep === 3) {
        switch (this.selectedIntegration) {
          case economic:
            this.redirectToEconomic();
            break;
          case uniconta:
            this.showUnicontaIntegration = true;
            break;
          case businessCentral:
            this.showDynamicsIntegration = true;
            break;
          case businessCentralOnPremise:
            this.createDataCollectIntegration();
            break;
          case ekomplet:
            this.createEkompletIntegration();
            break;
          case manualFileUpload:
            this.$emit("open:manual-file-upload-modal");
            break;
        }

        return;
      }

      if (this.selectedOnboardingStep === 2) {
        this.submitForm();
      }

      if (
        this.selectedOnboardingStep === 5 &&
        (this.selectedIntegration === businessCentralOnPremise ||
          this.selectedIntegration === ekomplet)
      ) {
        this.close();
        this.selectedIntegration = null;
        this.selectedOnboardingStep = 1;
        return;
      }

      if (this.selectedOnboardingStep === 6) {
        this.$refs["account-mapper"].setLinkedAccounts(() =>
          this.$emit("onAccountMappingSuccess")
        );
        return;
      }
      this.selectedOnboardingStep = (this.currentStep?.id ?? 0) + 1;

      this.$emit("update:selected-step", this.selectedOnboardingStep);
    },
    hideModal() {
      if (this.selectedOnboardingStep === 4) {
        this.$emit("show-api-fields");
      } else {
        this.$emit("show-warning");
      }
    },
    skipMapping() {
      this.$emit("skip-mapping");
    },
    async close() {
      this.$emit("close-all");
      this.show = false;
    },
    finishOnboarding() {
      if (
        this.selectedIntegration !== businessCentralOnPremise &&
        this.selectedIntegration !== ekomplet
      ) {
        this.submitForm();
      } else {
        this.form = this.$inertia.form(this.generateInitialForm());
      }

      this.selectedOnboardingStep = 2;
      this.close();
    },
    redirectToEconomic() {
      const redirectToOnboarding = true;
      const queryParams = new URLSearchParams({ redirectToOnboarding });
      const url =
        this.route("api.settings.economic.install") +
        "?" +
        queryParams.toString();
      window.location.href = url;
    },
    async createDataCollectIntegration() {
      const response = await fetch(
        this.route("api.settings.datacollect.create"),
        {
          method: "POST",
        }
      );

      if (response.ok) {
        this.apiToken = await response.text();
        this.selectedOnboardingStep = 5;
      } else {
        let data = await response.json();
        this.integrationError = data.integrationErrorTranslationKey;
        console.error("datacollect integration failed to configure.");
      }
    },
    async createEkompletIntegration() {
      const response = await fetch(
        this.route("api.settings.datacollect.create"),
        {
          method: "POST",
          body: serialize({ system: "ekomplet" }),
        }
      );

      if (response.ok) {
        this.apiToken = await response.text();
        this.selectedOnboardingStep = 5;
      } else {
        let data = await response.json();
        this.integrationError = data.integrationErrorTranslationKey;
        console.error("Ekomplet integration failed to configure.");
      }
    },
    updateForm(key, value) {
      this.$set(this.form, key, value);
    },
    handleOtherIntegration(value) {
      this.financialSystemForm["financialSystem"] = value;
    },
    submitForm() {
      this.form
        .transform((data) =>
          serialize(data, { noFilesWithArrayNotation: true })
        )
        .put(this.route("onboarding.settings"), {
          onSuccess: () => {
            this.form = this.$inertia.form(this.generateInitialForm());
          },
        });
    },
    submitFinancialSystemForm() {
      this.financialSystemForm
        .transform((data) =>
          serialize(data, { noFilesWithArrayNotation: true })
        )
        .post(this.route("onboarding.integration"), {
          onSuccess: () => {
            this.financialSystemForm = this.$inertia.form(
              this.generateInitialFinancialSystemForm()
            );
          },
        });
    },
    generateInitialForm() {
      const defaultCurrency = this.getDefaultCurrency();

      return {
        enableAssetRegister: false,
        enableEmissionReporting: true,
        financialSystem: null,
        companyRegNo: this.companyRegNo,
        companyName: this.companyName,
        companyEmail: this.companyEmail,
        defaultCurrencyId: defaultCurrency?.id ?? null,
        defaultRegion: this.getDefaultRegion() ?? null,
      };
    },
    generateInitialFinancialSystemForm() {
      return {
        financialSystem: null,
      };
    },

    getDefaultCurrency() {
      return this.currencies[
        this.currencies.findIndex((currency) => currency.currencyCode === "DKK")
      ];
    },
    getDefaultRegion() {
      return "denmark";
    },
  },
  mounted() {
    if (this.settings.skippedOnboarding && !this.integrationInstalled) {
      this.selectedOnboardingStep = 3;
    }
  },
  watch: {
    showUnicontaIntegration(value) {
      if (value) return;

      this.unicontaUsername = null;
      this.unicontaPassword = null;
      this.unicontaCompanies = null;
    },
  },
};
</script>
<style>
.fade-enter-active {
  transition: all 0.4s ease;
}
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter {
  transform: translateX(20px);
  opacity: 1;
}

.fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.transition-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.footer-section {
  position: sticky;
  bottom: 0;
  background: #fff;
}
</style>
