<template>
  <div class="d-flex justify-center">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="d-flex align-center"
    >
      <v-btn
        v-if="hiddenStep !== step"
        :class="{
          active: selectedStep === index + 1,
          completed: selectedStep > index + 1,
          'cursor-default': disableNavigation,
        }"
        class="step-button"
        outlined
        fab
        x-small
        :disabled="selectedStep <= index + 1"
        @click="goToStep(step)"
      >
        <div class="button-border">
          <template v-if="selectedStep > index + 1">
            <v-icon>mdi-check</v-icon>
          </template>
          <template v-else>
            {{ getStepNumber(index) }}
          </template>
        </div>
      </v-btn>

      <div
        v-if="index !== steps - 1"
        class="line"
        :class="
          hiddenStep === step || hiddenStep === step + 1
            ? 'hidden-step-line'
            : ''
        "
        :style="lineStyle(index + 1)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      required: false,
    },
    selectedStep: {
      type: Number,
      default: 1,
    },
    hiddenStep: {
      type: Number,
      default: null,
      /* Fix to allow 1 half step, maybe change to array if more substeps are necessary */
    },
    disableNavigation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToStep(step) {
      if (this.disableNavigation) return;

      if (step >= 1 && step <= this.steps) {
        this.$emit("input", step);
      }
    },
    lineStyle(step) {
      return {
        backgroundColor: step <= this.selectedStep - 1 ? "#F25D3B" : "#F7F7F7",
        transition: "background-color 0.5s ease",
      };
    },
    getStepNumber(index) {
      const realStep = index + 1;
      if (this.hiddenStep !== null) {
        if (this.hiddenStep < realStep) {
          return realStep - 1;
        }
      }
      return realStep;
    },
  },
};
</script>

<style lang="scss" scoped>
.line {
  width: 70px;
  height: 2px;
}

.hidden-step-line {
  width: 35px;
}

.step-button {
  display: flex;
  border-width: 2px;
  border-color: #f7f7f7;
  position: relative;
  &.v-btn--disabled{
      color: #131313 !important;
  }
}

.step-button:deep() {
  .v-btn__content {
    position: absolute;
    inset: 0;
  }
}

.step-button.active {
  border-width: 2px;
  background-color: #f25d3b !important;
  color: white !important;
  border-color: #f25d3b;
}

.step-button.completed {
  border-width: 2px;
  background-color: #f25d3b !important;
  color: white !important;
  font-weight: bold;
  border-color: #f25d3b;

  .v-icon {
    color: white !important;
  }
}

.button-border {
  position: absolute;
  border-width: 2px;
  border-radius: 100%;
  border-color: white;
  border-style: solid;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
