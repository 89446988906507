<template>
  <div class="page-header d-flex justify-center">
    <div class="d-flex flex-row" style="width: 1313px">
      <div class="d-flex flex-row align-center flex-grow-1">
        <v-avatar
          color="#E4E4E7"
          class="mr-4"
          width="40px"
          height="40px"
          min-width="40px"
        >
          <div>
            <Calendar width="24px" height="24px"></Calendar>
          </div>
        </v-avatar>
        <div class="d-flex flex-row border-right align-center">
          {{ formattedDateRange.from }}
          <ArrowRightIcon
            class="mx-4"
            width="15px"
            height="15px"
          ></ArrowRightIcon>
          {{ formattedDateRange.to }}
        </div>
      </div>
      <div class="mr-8 organization-info">
        <div>{{ $t("pages.reports.climateReport.client") }}</div>
        <div>{{ organization.name }}</div>
      </div>
      <div
        v-if="partner"
        class="pl-8 organization-info"
        style="border-left: 1px solid #e4e4e7"
      >
        <div>{{ $t("pages.reports.climateReport.partner") }}</div>
        <div>{{ partner.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from "../../assets/svg/arrow-right-dark-20px.svg";
import Calendar from "../../assets/svg/calendar-black.svg";

export default {
  components: { ArrowRightIcon, Calendar },
  props: {
    formattedDateRange: Object,
    organization: Object,
    partner: Object,
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  position: absolute;
  border-bottom: 1px solid #e4e4e7;
  left: 0px;
  right: 0;

  .organization-info {
    margin-top: 35px;
    padding-bottom: 25px;
    div:nth-child(1) {
      color: #686868;
      font-size: 16px;
    }
    div:nth-child(2) {
      color: #131313;
      font-size: 16px;
    }
  }
}
</style>
