<template>
  <div>
    <div class="footer-logo">
      <PoweredByVerarca></PoweredByVerarca>
    </div>
    <div class="report-footer-title">
      {{ $t("components.reportTemplates.carbonFootprintOverview.title") }}
    </div>
    <div class="website-domain">
      <v-avatar
        color="#F25D3B1A"
        width="40px"
        height="40px"
        min-width="40px"
        class="mr-4"
      >
        <div><Globe width="24px" height="24px"></Globe></div>
      </v-avatar>
      verarca.com
    </div>
    <div class="page-number">{{ pageNumber }} / {{ totalNumberOfPages }}</div>
  </div>
</template>

<script>
import PoweredByVerarca from "../../assets/svg/powered-by-verarca.svg";
import Globe from "../../assets/svg/globe.svg";
export default {
  components: { PoweredByVerarca, Globe },
  props: {
    pageNumber: Number,
    totalNumberOfPages: Number,
  },
};
</script>

<style lang="scss" scoped>
.footer-logo {
  position: absolute;
  bottom: 50px;
  left: 50px;
}

.report-footer-title {
  font-size: 35px;
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  color: #686868;
}

.page-number {
  position: absolute;
  bottom: 50px;
  right: 80px;
  text-align: right;
  color: #6e6e6e;
}

.website-domain {
  position: absolute;
  bottom: 65px;
  right: 80px;
}
</style>
