var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-grow-1 d-flex justify-center align-center"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"px-0",staticStyle:{"width":"1313px"},attrs:{"fluid":""}},[_c('v-card',{staticClass:"flex-grow-1",attrs:{"light":"","color":"#fff","elevation":"0","rounded":"0","max-width":"100%"}},[_c('div',{staticClass:"px-10 pt-2"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"flex-grow-1"},[_c('p',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.$t("components.reportTemplates.emissionsByGhgs.title"))+" ")])]),_c('div',{staticClass:"d-flex flex-row"},_vm._l(([
                  { color: '#266663', name: 'Scope 1' },
                  { color: '#6493BE', name: 'Scope 2' },
                  { color: '#E35E3E', name: 'Scope 3' },
                ]),function(item,index){return _c('div',{key:index,staticClass:"d-flex ml-sm-4 mb-4 mb-sm-0 align-center"},[_c('div',{staticClass:"mr-2 rounded-4",style:('background-color:' +
                    item.color +
                    ';display: inline-block; min-width: 16px; height: 16px; border-radius: 4px;')}),_c('div',{staticStyle:{"color":"#b4b4b4"}},[_vm._v(_vm._s(item.name))])])}),0)]),_c('v-row',_vm._l((_vm.categories),function(category){return _c('v-col',{key:category.id,attrs:{"cols":"3"}},[_c('icon-with-percentage',{attrs:{"title":category.name,"icon-back-color":category.iconColor,"percent-value":category.emissions,"percentage-prefix":category.emissions === 0.1 ? '<' : undefined},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(category.iconComponent,{tag:"component",style:({
                      width: category.iconWidth,
                      height: category.iconHeight,
                      borderRadius: 0,
                    }),attrs:{"width":category.iconWidth,"height":category.iconHeight}})]},proxy:true}],null,true)})],1)}),1)],1)]),_c('div',{staticClass:"bottom-note"},[_vm._v(" "+_vm._s("* " + _vm.$t("components.reportTemplates.emissionsByGhgs.dueToRounding"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }